import React, { useEffect, useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import FolderApi from "../../../utils/api/FolderApi";

import cn from "classnames";
import { toast } from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import StepIndicator from "../../StepIndicator/StepIndicator";
import SelectUsersAndTeamsModal from "../SelectUsersAndTeamsModal/SelectUsersAndTeamsModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import UserApi from "../../../utils/api/UserApi";
import CourseApi from "../../../utils/api/CourseApi";
import useGetActiveUsersOfCompany from "../../../hooks/api/useGetActiveUsersOfCompany";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useUserRole from "../../../hooks/useUserRole";
import useGetTeams from "../../../hooks/api/useGetTeamsOfCompany";
import { useParams } from "react-router-dom";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";

import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const GiveCourseAccessModal = ({
   onClose,
   isOpen,
   onRemove,
   modal,
   clickedCourse,
   isCancelRequestsRef
}) => {
   const [title, setTitle] = useState("");
   const [isDisabled, setIsDisabled] = useState(true);
   const [isChangeLoading, setIsChangeLoading] = useState(false);
   const [progress, setProgress] = useState();
   const [selectedDepartment, setSelectedDepartment] = useState(null);
   const [selectedPosition, setSelectedPosition] = useState(null);

   const params = useParams();

   const onCancelRequests = () => {
      isCancelRequestsRef.current = true;
      modal.close();
    };

   const t = useFindTranslationsByString();

   const [selectedUserList, setSelectedUserList] = useState([]);
   const [selectedTeamList, setSelectedTeamList] = useState([]);

   const currentCompanyBranchId = useSelector(
      (state) => state.sidebar.currentCompanyBranchId
   );

   const companyId = useSelector((state) => state.sidebar.companyId);
   const userInfo = useSelector((state) => state?.user?.info);

   const userId = useSelector((state) => state?.user?.info?.id);
   const { userRoleName } = useUserRole(companyId);

   const {
      setCurrentPage,
      userList,
      setUserList,
      searchedValue,
      isUsersLoading,
      totalUsers,
      setSearchedValue,
      selectAll: selectAllUsers,
      setSelectAll: setSelectAllUsers,
      emails,
      setEmails,
      fetchUsers: refetchUsers 
   } = useGetActiveUsersOfCompany(
      companyId,
      false,
      false,
      selectedDepartment?.id,
      selectedPosition?.id
   );   

   const lastUserRef = useIntersectionObserver(() => {
      if (!isUsersLoading && userList?.length < totalUsers) {
         if (userList?.length === 0) {
            setCurrentPage(1);
         } else {
            setCurrentPage((prev) => prev + 1);
         }
      }
   }, [isUsersLoading, totalUsers]);

   const teamManager = useGetTeams({ companyId, userRoleName });

   const lastTeamRef = useIntersectionObserver(() => {
      if (
         !teamManager.isTeamsLoading &&
         teamManager.teamsList?.length < teamManager.totalTeams
      ) {
         if (teamManager.teamsList?.length === 0) {
            teamManager.setCurrentPage(1);
         } else {
            teamManager.setCurrentPage((prev) => prev + 1);
         }
      }
   }, [teamManager.isTeamsLoading, teamManager.totalTeams]);

   const changeAdminAsStudentStatus = (clickedId, asWho) => {
      setUserList((prevUsers) =>
            prevUsers.map((user) =>
            user.id === clickedId
               ? {
                  ...user,
                  isAsStudent: asWho === 'student' ? true : false,
                  }
               : user
            )
         );
    }

   useEffect(() => {
      return () => {
         setUserList([]);
      };
   }, []);

   const chunkArray = (array, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    };
        
    const addUserToCourseInChunks = async (users, status = 1, isMass = false) => {
      const userChunks = chunkArray(users, 25);
      const allAddUserPromises = [];

      let i = -1;
      for (const chunk of userChunks) {
         i++;

        if (isCancelRequestsRef.current) {
            break; 
        }

        const data = {
          user_ids: chunk.map(user => user?.id),
          status: status,
          ...(userChunks?.length > 1 ? { send_email: false } : {}),
         //  overwrite_user_course_active_or_paused_statuses: false
        };
    
        try {
          const res = await new CourseApi().addUsersToCourse(clickedCourse?.id, data);

          if (res?.success?.message) { 
            toast(<ToastMsg text={res?.success?.message} />);
          }

          if (res?.error?.message) {
            return toast(<ToastMsg text={res?.error?.message} isError/>)
         } 

         const newProgress = ((i + 1) / userChunks?.length) * 100;
         setProgress(newProgress?.toFixed());
          
          allAddUserPromises.push(res);
        } catch (error) {
          console.error(`Error adding users in chunk: ${error}`);
        }
      }
    
      return allAddUserPromises;
    };

      const onAssignUsersAsStudent = async () => {
         const markedUsers = userList?.filter(user => user?.isAsStudent);
         if(!markedUsers?.length) return;
   
         const transformedData = markedUsers?.map(user => ({
            user_id: user?.id,
            is_admin_as_student: user?.isAsStudent
         }))
   
         const dataToSend = {
            users: transformedData
            }
         const res = await new UserApi().changeAdminStatusInCourse(clickedCourse?.id, dataToSend);
      }

   const onGiveAccess = async () => {
      setIsChangeLoading(true);

      if(selectedUserList.length) {
         const res = await addUserToCourseInChunks(selectedUserList);
            onAssignUsersAsStudent();
            if (res?.error?.message) {
               return toast(<ToastMsg text={res?.error?.message} isError/>)
            } 
         }

         if(selectedTeamList.length) {
            const resTeams = await new CourseApi()
               .addTeamsToCourse(clickedCourse?.id, {
                     team_ids: selectedTeamList?.map(item => item?.id),
                     status: 1,
                     send_email: false
               });

            if (resTeams?.success?.message) toast(<ToastMsg text={resTeams?.success?.message} />)
            if (resTeams?.error?.message) return toast(<ToastMsg text={res?.error?.message} isError/>)
         }

         setProgress(0);
         setIsChangeLoading(false);
    }

   return (
      <SelectUsersAndTeamsModal
         title={t("Add access")}
         changeAdminAsStudentStatus={changeAdminAsStudentStatus}
         isCourseAccessPage
         emails={emails}
         setEmails={setEmails}
         lastUserRef={lastUserRef}
         isUsersLoading={isUsersLoading || isChangeLoading}
         searchedValue={searchedValue}
         setSearchedValue={setSearchedValue}
         onClose={modal.close}
         isOpen={modal.isActive}
         selectedUsers={selectedUserList}
         setSelectedUsers={setSelectedUserList}
         usersList={userList}
         onConfirm={onGiveAccess}
         teamList={teamManager.teamsList}
         selectedTeamList={selectedTeamList}
         setSelectedTeamList={setSelectedTeamList}
         setSelectAllUsers={setSelectAllUsers}
         selectAllUsers={selectAllUsers}
         selectAllTeams={teamManager.selectAll}
         setSelectAllTeams={teamManager.setSelectAll}
         lastTeamRef={lastTeamRef}
         teamsLoading={teamManager.isTeamsLoading}
         teamSearchedValue={teamManager.searchedValue}
         setTeamSearchedValue={teamManager.setSearchedValue}
         selectedDepartment={selectedDepartment}
         setSelectedDepartment={setSelectedDepartment}
         selectedPosition={selectedPosition}
         setSelectedPosition={setSelectedPosition}
         progress={progress}
         onCancelRequests={onCancelRequests}
      />
   );
};

export default GiveCourseAccessModal;

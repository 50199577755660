import React, { useState } from "react";
import styles from "./styles.module.scss";
import { ReactComponent  as Cross } from "../../../assets/images/symbols/plus-16-16.svg";
import deleteIcon from "../../../assets/images/symbols/delete.svg";
import DropdownWithCheckboxes from "../../../components/DropdownWithCheckboxes/DropdownWithCheckboxes";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import cn from "classnames";
import {
   ALL_LANGUAGES_LIST,
   DEFAULT_LANG_LOCALE,
   TEXT_DOMAINS,
} from "../../../constants/languages";
import Input from "../../../components/Input/Input";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import TranslationApi from "../../../utils/api/TranslationApi";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import searchIcon from "../../../assets/images/symbols/Search.svg";

const LanguagesSidebar = ({
   domainTab,
   setDomainTab,
   onSelectedLanguagesChange,
   selectedLanguages,
   isAddLangLoading,
   companyId,
   removeAvailableLanguage,
   t
}) => {
   const tNew = useFindTranslationsByString();

   const [availableLangsVisible, setAvailableLangsVisible] = useState(false);
   const [langSearchValue, setLangSearchValue] = useState("");
   const [langToDelete, setLangToDelete] = useState(null);
   const [isDeleteLoading, setIsDeleteLoading] = useState(false);

   const confirmDeleteModal = useHandleModal();

   const confirmDeleteModalOpen = (lang) => {
      setLangToDelete(lang);
      confirmDeleteModal.open();
   };

   const toggleAbailableLangsVisibility = () => {
      setAvailableLangsVisible(!availableLangsVisible);
   };

   const allLangsApartFromDefault = ALL_LANGUAGES_LIST.filter(
      (lang) => lang.code !== DEFAULT_LANG_LOCALE?.code
   );

   const selectedWithoutDefault = selectedLanguages?.filter(
      (lang) => lang?.code !== DEFAULT_LANG_LOCALE?.code
   );

   const searchedLanguages = selectedWithoutDefault?.filter((lang) =>
      lang?.name?.toLowerCase().includes(langSearchValue.toLowerCase())
   );

   const onDeleteLangContent = async () => {
      setIsDeleteLoading(true);

      const data = [
         {
            company_id: companyId,
            locale: langToDelete?.code,
         },
      ];

      const res = await new TranslationApi().deleteCompanyTranslations(data);

      if (res?.success?.message) {
         removeAvailableLanguage(langToDelete);
         toast(<ToastMsg text={res?.success?.message} />);
      }
      if (res?.error?.message) {
         toast(<ToastMsg isError text={res?.error?.message} />);
      }

      confirmDeleteModal.close();
      setIsDeleteLoading(false);
   };

   return (
      <aside className={styles.sidebar}>
         <div className={styles.top}>
            <div className={styles.top_top}>
               <span className={styles.title}>{t?.languages}</span>
               <div
                  onClick={toggleAbailableLangsVisibility}
                  className={cn(
                     styles.img_wrapper,
                     "noselect",
                     availableLangsVisible ? styles?.close : ""
                  )}
               >
                <Cross/>
               </div>
            </div>

            {availableLangsVisible && (
               <div className={styles.languages}>
                  <DropdownWithCheckboxes
                     isDark
                     isAddLoading={isAddLangLoading}
                     field={"name"}
                     maxHeight={"215px"}
                     data={allLangsApartFromDefault}
                     placeholder={
                        selectedLanguages?.length
                           ? selectedLanguages
                                ?.map((item) => item.name)
                                .join(", ")
                           : t?.selectLanguage
                     }
                     dontCloseOnClick
                     onChange={onSelectedLanguagesChange}
                     selectedItems={selectedLanguages}
                  />
               </div>
            )}

            <div className={styles.search}>
               <Input
                  maxHeightFrontEl={"44px"}
                  customClass={styles.input_wrapper}
                  value={langSearchValue}
                  onChangeValue={(e) => setLangSearchValue(e.target.value)}
                  placeholder={t?.search}
                  crossNeed
                  icon={searchIcon}
               />
            </div>

            <div className={styles.default_lang}>
               <span>{DEFAULT_LANG_LOCALE?.name}</span>
               <span>{` (${t?.default})`}</span>
            </div>

            <div className={styles.langs_list}>
               {searchedLanguages?.map((lang) => (
                  <div key={lang?.id} className={styles.langs_item}>
                     <span>{lang?.name}</span>
                     <img
                        onClick={() => confirmDeleteModalOpen(lang)}
                        className={styles.delete_icon}
                        src={deleteIcon}
                        alt=""
                     />
                  </div>
               ))}
            </div>
         </div>
         <div className={styles.bottom}>
            <span className={styles.title}>{t?.sections}</span>
            <div className={cn(styles.domains, availableLangsVisible && styles.small)}>
               {TEXT_DOMAINS?.map((domain) => (
                  <div
                     key={domain?.id}
                     onClick={() => setDomainTab(domain)}
                     className={cn(
                        styles.domain,
                        domainTab?.label === domain?.label
                           ? styles.active_domain
                           : ""
                     )}
                  >
                     {t?.returnTranslation(domain?.name)}
                  </div>
               ))}
            </div>

            <ConfirmModal
               isRemove
               maxWidth={"416px"}
               confirmButtonText={tNew('Delete')}
               onConfirm={onDeleteLangContent}
               onClose={confirmDeleteModal.close}
               isOpen={confirmDeleteModal.isActive}
               isLoading={isDeleteLoading}
               title={tNew('Are you sure you want to delete language?')}
               subtitle={tNew('All data in this language will be lost')}
            />
         </div>
      </aside>
   );
};

export default LanguagesSidebar;

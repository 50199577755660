import folderPurple from '../assets/images/Folder_purple.svg'
import folderYellow from '../assets/images/Folder_yellow.svg'
import folderBlue from '../assets/images/Folder_blue.svg'
import folderGreen from '../assets/images/Folder_green.svg'
import folderRed from '../assets/images/Folder_red.svg'
import courseImg from '../assets/images/course_thumbnail.png'
import settingsImg from '../assets/images/symbols/setting.svg'
import settingsImgWhite from '../assets/images/symbols/setting_white.svg'
import dots from '../assets/images/symbols/more_circle.svg'
import dotsWhite from '../assets/images/symbols/more_circle_white.svg'
import templateImg from '../assets/images/certificates/template.svg'
import templateWhite from '../assets/images/certificates/template_white.svg'
import stylesImg from '../assets/images/certificates/styles.svg'
import stylesWhite from '../assets/images/certificates/styles_white.svg'
import certificateWhite from '../assets/images/certificate_icon_white.svg'
import certificate from '../assets/images/certificate_icon.svg'
import password from '../assets/images/symbols/password.svg'
import passwordWhite from '../assets/images/symbols/password_white.svg'
import teamImg from '../assets/images/Icon_teams.svg'
import teamImgWhite from '../assets/images/symbols/Work.svg'
import studentsImg from '../assets/images/select_users.svg'
import studentsImgWhite from '../assets/images/select_img_white.svg'
import multiChoice from '../assets/images/question_types/multi_choice.svg'
import multiChoiceStudent from '../assets/images/question_types/multi_choice_student.svg'
import oneChoice from '../assets/images/question_types/one_choice.svg'
import oneChoiceStudent from '../assets/images/question_types/one_choice_student.svg'
import true_false from '../assets/images/question_types/true_false.svg'
import trueFalseStudent from '../assets/images/question_types/true_false_student.svg'
import sequence from '../assets/images/question_types/sequence.svg'
import sequenceStudent from '../assets/images/question_types/sequence_student.svg'
import written_answer from '../assets/images/question_types/written_answer.svg'
import writtenAnswerStudent from '../assets/images/question_types/written_answer_student.svg'
import file_upload from '../assets/images/question_types/file_upload.svg'
import videoAnswerStudent from '../assets/images/question_types/video_student.svg'
import fileUploadStudent from '../assets/images/question_types/upload_student.svg'
import video_answer from "../assets/images/icon_video_filled.svg";
import compare from '../assets/images/question_types/compare.svg';
import compareStudent from '../assets/images/question_types/compare_icon_student.svg';

import planet_1 from "../assets/images/progress/level-3.svg";
import planet_2 from "../assets/images/progress/level-4.svg";
import planet_3 from "../assets/images/progress/level-5.svg";
import planet_4 from "../assets/images/progress/level-6.svg";
import planet_5 from "../assets/images/progress/level-7.svg";
import planet_6 from "../assets/images/progress/level-8.svg";

import colorPalette1 from "../assets/images/certificates/color_palette_1.svg";
import colorPalette2 from "../assets/images/certificates/color_palette_2.svg";
import colorPalette3 from "../assets/images/certificates/color_palette_3.svg";
import colorPalette4 from "../assets/images/certificates/color_palette_4.svg";
import colorPalette5 from "../assets/images/certificates/color_palette_5.svg";
import colorPalette6 from "../assets/images/certificates/color_palette_6.svg";

import type1paletteImg1 from "../assets/images/certificates/type_first_img_1.png";
import type1paletteImg2 from "../assets/images/certificates/type_first_img_2.png";
import type1paletteImg3 from "../assets/images/certificates/type_first_img_3.png";
import type1paletteImg4 from "../assets/images/certificates/type_first_img_4.png";
import type1paletteImg5 from "../assets/images/certificates/type_first_img_5.png";
import type1paletteImg6 from "../assets/images/certificates/type_first_img_6.png";

import paletteImg1 from "../assets/images/certificates/type_second_img_1.png";
import paletteImg2 from "../assets/images/certificates/type_second_img_2.png";
import paletteImg3 from "../assets/images/certificates/type_second_img_3.png";
import paletteImg4 from "../assets/images/certificates/type_second_img_4.png";
import paletteImg5 from "../assets/images/certificates/type_second_img_5.png";
import paletteImg6 from "../assets/images/certificates/type_second_img_6.png";

export const PALETTE_IMAGES_TYPE_FIRST = [ type1paletteImg1, type1paletteImg2, type1paletteImg3, type1paletteImg4, type1paletteImg5, type1paletteImg6 ]
export const PALETTE_IMAGES_TYPE_SECOND = [ paletteImg1, paletteImg2, paletteImg3, paletteImg4, paletteImg5, paletteImg6 ]

export const INITIAL_EDITOR_TEXT = 'Initial content';
export const CLIENT_CLOSED_REQUEST_CODE = 499;
export const DELAY_BEFORE_UPLOADING_FILE_GONE = 2000;
export const DELAY_EDITOR_RENDERING = 2000;
export const MAX_UPLOADING_FILES_AT_ONCE = 50;


export const colorPalettes = [
   {img: colorPalette1, label: '1'},
   {img: colorPalette3, label: '3'},
   {img: colorPalette5, label: '5'},
   {img: colorPalette2, label: '2'},
   {img: colorPalette4, label: '4'},
   {img: colorPalette6, label: '6'},
];

export const LOADING_TEXT = 'Loading...';

export const NO_IMG_FOUND_RES_TEXT = 'No course image found.';

export const CERTIFICATE_FONTS = ['Roboto', 'Montserrat', 'OpenSans', 'Lato', 'Raleway'];

export const DEFAULT_CONGRATS_MSG = 'Congratulations on successfully completing the course! This achievement is a testament to your intellect and ability to absorb new knowledge.'

export const CERTIFICATE_GLOBAL_TYPE_FIRST = 'first';
export const CERTIFICATE_GLOBAL_TYPE_SECOND = 'second';

export const CERTIFICATE_GLOBAL_TYPES = [
   {id: 1, company_logo: '', language: '', name: CERTIFICATE_GLOBAL_TYPE_FIRST, color_palette: '1', font_family: '', congrats_msg: '', type: 'first_type_1'},
   {id: 2, company_logo: '', language: '', name: CERTIFICATE_GLOBAL_TYPE_SECOND, color_palette: '1', font_family: '', congrats_msg: DEFAULT_CONGRATS_MSG, image_position: 'left bottom',  background_size: "43% 33%", type: 'second_type_1'},
]

export const TEMPLATE_FIRST_STATIC_TEXT = {
   en: {
      certificateLabel: 'Certificate',
     date: 'February 06, 2023',
     studentLabel: 'To certificate that ',
     courseLabel: 'Has successfully completed',
     resultLabel: 'Result',
     result: 'Excellent'
   },
   uk: {
     certificateLabel: 'Сертифікат',
     date: '6 лютого 2023 року',
     studentLabel: 'Студент',
     courseLabel: 'Успішно завершив',
     resultLabel: 'Результат',
     result: 'Відмінно'
   },
   ru: {
     certificateLabel: 'Сертификат',
     date: '6 февраля 2023 года',
     studentLabel: 'Студент',
     courseLabel: 'Успешно завершил',
     resultLabel: 'Результат',
     result: 'Отлично'
   }
 };

 export const TEMPLATE_SECOND_STATIC_TEXT = {
   en: {
     certificateLabel: 'Certificate',
     ofAchivements: 'Of achievement',
     date: 'February 06, 2023',
     studentLabel: 'PROUDLY PRESENTED TO',
     courseLabel: 'Has successfully completed',
     congratsMsg: 'Congratulations on successfully completing the course! This achievement is a testament to your intellect and ability to absorb new knowledge.',
     resultLabel: 'Result',
     result: 'Excellent'
   },
   uk: {
     certificateLabel: 'Сертифікат',
     ofAchivements: 'Про досягнення',
     date: '6 лютого 2023 року',
     studentLabel: 'ГОРДО ПРЕЗЕНТУЄТЬСЯ',
     courseLabel: 'Успішно завершив',
     congratsMsg: 'Вітаємо з успішним завершенням курсу! Цей досягнений результат свідчить про вашу інтелектуальність та здатність вивчати нові знання.',
     resultLabel: 'Результат',
     result: 'Відмінно'
   },
   ru: {
     certificateLabel: 'Сертификат',
     ofAchivements: 'О достижениях',
     date: '6 февраля 2023 года',
     studentLabel: 'ГОРДО ПРЕДСТАВЛЕН',
     courseLabel: 'Успешно завершил',
     congratsMsg: 'Поздравляем с успешным завершением курса! Этот результат свидетельствует о вашем интеллекте и способности усваивать новые знания.',
     resultLabel: 'Результат',
     result: 'Отлично'
   }
 };

//  there is two global types of templates (different structure and image)
export const CERTIFICATE_TEMPLATES_FIRST = [
   {id: 1, company_logo: '', language: '', name: 'first', type: 'first_type_1', color_palette: '1', font_family: '', congrats_msg: ''},
   {id: 2, company_logo: '', language: '', name: 'first', type: 'first_type_2', color_palette: '1', font_family: '', congrats_msg: '' },
   {id: 3, company_logo: '', language: '', name: 'first', type: 'first_type_3', color_palette: '1', font_family: '', congrats_msg: '' },
   {id: 4, company_logo: '', language: '', name: 'first', type: 'first_type_4', color_palette: '1', font_family: '', congrats_msg: '' },
   {id: 5, company_logo: '', language: '', name: 'first', type: 'first_type_5', color_palette: '1', font_family: '', congrats_msg: '' },
   {id: 6, company_logo: '', language: '', name: 'first', type: 'first_type_6', color_palette: '1', font_family: '', congrats_msg: '' },
   {id: 7, company_logo: '', language: '', name: 'first', type: 'first_type_7', color_palette: '1', font_family: '', congrats_msg: '' },
   {id: 8, company_logo: '', language: '', name: 'first', type: 'first_type_8', color_palette: '1', font_family: '', congrats_msg: '' },
   {id: 9, company_logo: '', language: '', name: 'first', type: 'first_type_9', color_palette: '1', font_family: '', congrats_msg: '' },
   {id: 10, company_logo: '', language: '', name: 'first', type: 'first_type_10', color_palette: '1', font_family: '', congrats_msg: '' },
]

export const TYPE_SECOND_IMAGE_POSTIONS = {
   second_type_1: 'left bottom',
   second_type_2: 'right bottom',
   second_type_3: 'right bottom',
   second_type_4: 'left bottom',
   second_type_5: 'left bottom',
   second_type_6: 'right top',
   second_type_7: 'left top',
   second_type_8: 'right bottom',
   second_type_9: 'left bottom'
}
export const TYPE_SECOND_IMAGE_SIZE = {
   second_type_1: '43% 33%',
   second_type_2: '53% 33%',
   second_type_3: '53% 33%',
   second_type_4: '43% 33%',
   second_type_5: '43% 33%',
   second_type_6: '53% 48%',
   second_type_7: '43% 45%',
   second_type_8: '53% 33%',
   second_type_9: '43% 33%'
}

export const CERTIFICATE_TEMPLATES_SECOND = [
   {id: 11, company_logo: '', language: '', name: 'second', type: 'second_type_1', color_palette: '1', font_family: '', congrats_msg: DEFAULT_CONGRATS_MSG}, 
   {id: 12, company_logo: '', language: '', name: 'second', type: 'second_type_2', color_palette: '1', font_family: '', congrats_msg: DEFAULT_CONGRATS_MSG},
   {id: 13, company_logo: '', language: '', name: 'second', type: 'second_type_3', color_palette: '1', font_family: '', congrats_msg: DEFAULT_CONGRATS_MSG},
   {id: 14, company_logo: '', language: '', name: 'second', type: 'second_type_4', color_palette: '1', font_family: '', congrats_msg: DEFAULT_CONGRATS_MSG},
   {id: 15, company_logo: '', language: '', name: 'second', type: 'second_type_5', color_palette: '1', font_family: '', congrats_msg: DEFAULT_CONGRATS_MSG},
   {id: 16, company_logo: '', language: '', name: 'second', type: 'second_type_6', color_palette: '1', font_family: '', congrats_msg: DEFAULT_CONGRATS_MSG},
   {id: 17, company_logo: '', language: '', name: 'second', type: 'second_type_7', color_palette: '1', font_family: '', congrats_msg: DEFAULT_CONGRATS_MSG},
   {id: 18, company_logo: '', language: '', name: 'second', type: 'second_type_8', color_palette: '1', font_family: '', congrats_msg: DEFAULT_CONGRATS_MSG},
   {id: 19, company_logo: '', language: '', name: 'second', type: 'second_type_9', color_palette: '1', font_family: '', congrats_msg: DEFAULT_CONGRATS_MSG},
]

export const COURSES_SLIDER_DATA = [
    {
        folder: folderPurple,
        isNew: true,
        title: 'Translators School',
        people: '2873',
        readers: '13',
        id: 1
    },
    {
        folder: folderYellow,
        isNew: true,
        title: 'Translators School',
        people: '2873',
        readers: '13',
        id: 2
    },
    {
        folder: folderBlue,
        isNew: true,
        title: 'Translators School',
        people: '2873',
        readers: '13',
        id: 3
    },
    {
        folder: folderGreen,
        isNew: true,
        title: 'Translators School',
        people: '2873',
        readers: '13',
        id: 4
    },
    {
        folder: folderRed,
        isNew: true,
        title: 'Translators School',
        people: '2873',
        readers: '13',
        id: 5
    },
    {
        folder: folderPurple,
        isNew: true,
        title: 'Translators School',
        people: '2873',
        readers: '13',
        id: 6
    },
    {
        folder: folderYellow,
        isNew: true,
        title: 'Translators School',
        people: '2873',
        readers: '13',
        id: 7
    },
    {
        folder: folderBlue,
        isNew: true,
        title: 'Translators School',
        people: '2873',
        readers: '13',
        id: 8
    },
    {
        folder: folderGreen,
        isNew: true,
        title: 'Translators School',
        people: '2873',
        readers: '13',
        id: 9
    },
    {
        folder: folderRed,
        isNew: true,
        title: 'Translators School',
        people: '2873',
        readers: '13',
        id: 10
    },
]

export const COURSES_LIST_DATA = [
    {
        image: courseImg,
        title: 'Startup Academy by Google',
        text: "You're for agile without were just. Also panel were cross crystallize. At people alpha for agile without."
    }, {
        image: courseImg,
        title: 'Startup Academy by Google',
        text: "You're for agile without were just. Also panel were cross crystallize. At people alpha for agile without."
    }, {
        image: courseImg,
        title: 'Startup Academy by Google',
        text: "You're for agile without were just. Also panel were cross crystallize. At people alpha for agile without."
    }, {
        image: courseImg,
        title: 'Startup Academy by Google',
        text: "You're for agile without were just. Also panel were cross crystallize. At people alpha for agile without."
    }, {
        image: courseImg,
        title: 'Startup Academy by Google',
        text: "You're for agile without were just. Also panel were cross crystallize. At people alpha for agile without."
    }, {
        image: courseImg,
        title: 'Startup Academy by Google',
        text: "You're for agile without were just. Also panel were cross crystallize. At people alpha for agile without."
    }, {
        image: courseImg,
        title: 'Startup Academy by Google',
        text: "You're for agile without were just. Also panel were cross crystallize. At people alpha for agile without."
    }, {
        image: courseImg,
        title: 'Startup Academy by Google',
        text: "You're for agile without were just. Also panel were cross crystallize. At people alpha for agile without."
    },
]

export const TAGS = ['Recruting', 'HR', 'Work', 'Work', 'Work']

export const SUBMENU_BASIC_SETTINGS = 1
export const SUBMENU_CERTIFICATE = 2
export const SUBMENU_END_COURSE = 3
// export const SUBMENU_SELECT_DEADLINE = 4
export const SUBMENU_ACCESS = 4

export const STUDENT_STATUS_ACTIVE = 1;
export const STUDENT_STATUS_PAUSED = 2;
export const STUDENT_STATUS_DELETED = 3;

export const COURSE_STATUS_ACTIVE = 1;
export const COURSE_STATUS_HIDEN = 2;
export const COURSE_STATUS_DELETED = 3;

export const LESSON_STATUS_ACTIVE = 1;

export const FOLDER_STATUS_ACTIVE = 1;
export const FOLDER_STATUS_HIDEN = 2;
export const FOLDER_STATUS_DELETED = 3;

export const SELECT_ALL_ITEMS = [{name: 'Select', label: 'select'}, {name: "Select all", label: "select_all"}]

export const COURSE_SETTINGS_SUBMENU = [
    {
        image: settingsImg,
        activeImage: settingsImgWhite,
        title: 'Basic settings',
        value: SUBMENU_BASIC_SETTINGS,
        text: 'Basic settings'
    },
    {
        image: certificate,
        activeImage: certificateWhite,
        title: 'Certificate',
        value: SUBMENU_CERTIFICATE,
        text: 'Here you can customize the appearance of the certificate'
    },
    {
        image: dots,
        activeImage: dotsWhite,
        title: 'End of course',
        value: SUBMENU_END_COURSE,
        text: 'Congratulatory text about the end of the course, which will appear in the course finale'
    },
   //  {
   // FOR FUTURE
   //      image: calendar,
   //      activeImage: calendarWhite,
   //      title: 'Select deadline',
   //      value: SUBMENU_SELECT_DEADLINE,
   //      text: 'Limit the availability of the course to a certain date, or the number of days to complete it'
   //  },
    {
        image: password,
        activeImage: passwordWhite,
        title: 'Access to the course',
        value: SUBMENU_ACCESS,
        text: 'Limit the availability of the course to a certain date, or the number of days to complete it'
    },
]

export const TEAM_TAB = 1
export const STUDENTS_TAB = 2

export const ACCESS_TABS = [
   {title: 'Users', image: studentsImg, activeImage: studentsImgWhite, value: STUDENTS_TAB},
    {title: 'Teams', image: teamImg, activeImage: teamImgWhite, value: TEAM_TAB},
]

export const TEMPLATES_TAB = 1
export const STYLES_TAB = 2

export const CERTIFICATE_TABS = [
   {title: 'Templates', image: templateImg, activeImage: templateWhite, value: TEMPLATES_TAB},
    {title: 'Styles', image: stylesImg, activeImage: stylesWhite, value: STYLES_TAB},
]

export const STUDENT_JUST_HEADERS = ["Name & Surname", "Department", "Position", "Course", "Progress", "Status"];
export const STUDENT_JUST_FIELDS = [ "team", "name", "department", "position", "was", "role", 'progress', "status" ]

export const MAX_VIDEO_SIZE = 5 * 1024 * 1024 * 1024; // 5 GB 
export const MAX_VIDEO_SIZE_GB = 5; // 5 GB 
export const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 10 MB 

export const STUDENTS_TABLE_HEADERS = [
   {title: 'Team', field: 'team'},
   {title: 'Name & Surname', field: 'name'},
   {title: 'Department', field: 'department'},
   {title: 'Position', field: 'position'},
   {title: 'Was', field: 'date'},
   {title: 'Score', field: 'score'},
   {title: 'Status', field: 'status'},
] 

export const COURSES_JUST_HEADERS = [ "Folder", "Course", "Was", "Status" ];
export const COURSES_JUST_FIELDS = [ "folder", "name", "was", "status" ];
export const COURSE_MODIFIED_COLUMNS = ["name", "last_modified", "role"];

export const COURSES_TABLE_HEADERS = [
   {title: 'Folder', field: 'folder'},
   {title: 'Course', field: 'name'},
   {title: 'Was', field: 'was'},
   {title: 'Role', field: 'role'},
   {title: 'Progress', field: 'progress'},
   {title: 'Status', field: 'status'},
] 

export const COLOR_PRESET_ORANGE = 1
export const COLOR_PRESET_GREEN = 2
export const COLOR_PRESET_PURPLE = 3
export const COLOR_PRESET_RED = 4
export const COLOR_PRESET_BLUE = 5

export const COLOR_PRESETS = [
    {color: 'linear-gradient(107.52deg, #E8B249 0%, #DE841B 100%)', hexColor: '#DE841B', id: COLOR_PRESET_ORANGE, image: folderYellow},
    {color: 'linear-gradient(107.52deg, #45B858 0%, #56BA84 100%)', hexColor: '#56BA84', id: COLOR_PRESET_GREEN, image: folderGreen},
    {color: 'linear-gradient(107.52deg, #9A60DE 0%, #C774EE 100%)', hexColor: '#C774EE', id: COLOR_PRESET_PURPLE, image: folderPurple},
    {color: 'linear-gradient(107.52deg, #9A60DE 0%, #DE6060 0.01%, #EE9274 100%)', hexColor: '#EE9274', id: COLOR_PRESET_RED, image: folderRed},
    {color: 'linear-gradient(107.52deg, #608BDE 0%, #74ACEE 100%)', hexColor: '#74ACEE', id: COLOR_PRESET_BLUE, image: folderBlue},
]


export const SELECT_COURSES_DATA = [
    {title: 'Startup Academy by Google', id: 1},
    {title: 'Startup Academy by Google', id: 12},
    {title: 'Startup Academy by Google', id: 13},
    {title: 'Startup Academy by Google', id: 14},
    {title: 'Startup Academy by Google', id: 15},
    {title: 'Startup Academy by Google', id: 16},
    {title: 'Startup Academy by Google', id: 17},
    {title: 'Startup Academy by Google', id: 18},
    {title: 'Startup Academy by Google', id: 19},
    {title: 'Startup Academy by Google', id: 10},
    {title: 'Startup Academy by Google', id: 111},
]


export const MODULES_DATA = [
    {
        title: 'Module 1. Brief',
        amount: 4,
        id: 1,
        elements: [
            {title: 'Lesson', id: 12, isLesson: true},
            {title: 'Testing', id: 13, isLesson: false},
        ],
    },

    {
        title: 'Module 2. Activity',
        amount: 4,
        id: 10,
        elements: [{title: 'Lesson', id: 10, isLesson: true},
            {title: 'Testing', id: 111, isLesson: false},
            {title: 'Text lesson', id: 102, isLesson: true},
            {title: 'Testing', id: 103, isLesson: false},
        ],
    },

    {
        title: 'Module 3',
        amount: 4,
        id: 100,
        elements: [{title: 'Lesson', id: 19, isLesson: true},
            {title: 'Testing', id: 1091, isLesson: false},
            {title: 'Text lesson', id: 1002, isLesson: true},
            {title: 'Testing', id: 100003, isLesson: false},
        ],
    },
]


export const QUESTION_TYPES_DATA = [
    {type: 1, title: 'Multiple choice', image: multiChoice, id: 1, imageForStudent: multiChoiceStudent},
    {type: 0, title: 'One choice', image: oneChoice, id: 2, imageForStudent: oneChoiceStudent},
   //  FOR FUTURE
   //  {type: 5, title: 'Stars 1-10', image: stars, id: 3},
   //  {type: 4, title: 'Rate 1-10', image: rate, id: 4},
    {type: 6, title: 'True/False', image: true_false, id: 5, imageForStudent: trueFalseStudent},
    {type: 8, title: 'Sequence', image: sequence, id: 6, imageForStudent: sequenceStudent},
    {type: 3, title: 'Written answer', image: written_answer, id: 7, imageForStudent: writtenAnswerStudent},
    {type: 2, title: 'File upload', image: file_upload, id: 8, imageForStudent: fileUploadStudent},
    {type: 9, title: 'Video Answer', image: video_answer, id: 10, imageForStudent: videoAnswerStudent},
    {type: 7, title: 'Compare', image: compare, id: 9, imageForStudent: compareStudent},
   ]

export const LESSON_TYPE_WITH_TESTING_NUMBER = 1;
export const LESSON_TYPE_WITHOUT_TESTING_NUMBER = 0;


export const levelsData = [
   { planetImage: planet_1, level: 3, progress: 77 },
   { planetImage: planet_2, level: 4, progress: 45 },
   { planetImage: planet_3, level: 5, progress: 39 },
   { planetImage: planet_4, level: 6, progress: 22 },
   { planetImage: planet_5, level: 7, progress: 88 },
   { planetImage: planet_6, level: 8, progress: 55 },
];

export const DEFAULT_CONTENT = /Default content/;






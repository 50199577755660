import React, {useEffect, useRef, useState} from "react";
import MainLayout from "../../../layouts/MainLayout/MainLayout";
import SubmenuBlock from "./SubmenuBlock/SubmenuBlock";
import QuestionsBlock from "./QuestionsBlock/QuestionsBlock";
import {
    LESSON_STATUS_ACTIVE,
    LESSON_TYPE_WITH_TESTING_NUMBER,
    LESSON_TYPE_WITHOUT_TESTING_NUMBER
} from "../../../constants/courses";
import {useNavigate, useParams} from "react-router-dom";
import CourseApi from "../../../utils/api/CourseApi";
import ModuleApi from "../../../utils/api/ModuleApi";
import LessonApi from "../../../utils/api/LessonApi";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import {
    COMPARE_ANSWER_CODE,
    SEQUENCE_ANSWER_CODE,
    shuffleArray,
    shuffleArrayContent,
    shuffleArrayWithWrongAnswers
} from "../../../utils/questionsHelper";
import {toast} from "react-toastify";
import {
    decodeSanitizedContent,
    decodeSanitizedInputValue,
    replaceIframesWithLiteYouTube,
    returnUpdatedContent,
    sortByDisplaySequence,
    sortBySequence
} from "../../../utils/coursesHelper";
import {useDispatch, useSelector} from "react-redux";
import shortLogo from "../../../assets/images/favicon.png";
import {setCompanyInfo} from '../../../store/actions/sidebar';

import arrowRight from '../../../assets/images/arrow_right.svg';
import arrowLeft from '../../../assets/images/arrow_left.svg';
import congratsIcon from '../../../assets/images/magic_img.png';
import salute from '../../../assets/images/salute.png';
import alerIcon from '../../../assets/images/alert_image.png';
import failedIcon from '../../../assets/images/failed_icon.png';
import testCheckingIcon from '../../../assets/images/test_checking_image.png';

import KnowledgeCheckAPI from "../../../utils/api/KnowledgeCheckAPI";
import Button from "../../../components/Button/Button";
import StartTestModal from "../../../components/Modals/StartTestModal/StartTestModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import UserApi from "../../../utils/api/UserApi";
import {
    TEST_STATUS_APPROVED,
    TEST_STATUS_ON_CHECK,
    TEST_STATUS_ON_REWORK,
    TEST_STATUS_REJECTED
} from "../../../constants/tests";
import TestResultsBlock from "../../../components/TestResultsBlock/TestResultsBlock";
import Loader from "../../../components/Loader/Loader";
import InfoModal from "../../../components/Modals/InfoModal/InfoModal";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import CertificateApi from "../../../utils/api/CertificateApi";

import moment from "moment";
import cn from "classnames";
import styles from "./styles.module.scss";
import EndOfCourseModal from "../../../components/Modals/EndOfCourseModal/EndOfCourseModal";
import CertificateModal from "../../../components/Modals/CertificateModal/CertificateModal";
import {ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN} from "../../../constants/user";
import useUserRole from "../../../hooks/useUserRole";
import {
    COURSE_WITH_CERTIFICATE_ONLY_GROUP,
    MODULES_EXTRA_GROUP,
    QUESTIONS_IN_LESSON_GROUP
} from "../../../utils/api/serialization_groups";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import {COURSE_LANGUAGES_LIST} from "../../../constants/languages";
import ChangeCourseLanguageModal from "../../../components/Modals/ChangeCourseLanguageModal/ChangeCourseLanguageModal";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import {isMobileDevice, returnTemporaryCompanyLink} from '../../../utils/usersHelper';
import CourseMobileHeader from "./CourseMobileHeader/CourseMobileHeader";

const CourseContentPage = () => {
    const params = useParams();
    const startTestModal = useHandleModal();
    const endCourseModalCongrats = useHandleModal();
    const endCourseModalFailed = useHandleModal();
    const retakeModal = useHandleModal();
    const failedCourseModal = useHandleModal();
    const certificateModal = useHandleModal();
    const changeLanguageModal = useHandleModal();
    const dispatch = useDispatch();
    const [defaultCourseLanguage, setDefaultCourseLanguage] = useState(params?.defaultLang || 'en_US');
    const [isFewLangs, setIsfewLangs] = useState(params?.is_few_langs || 'false');

    const [userLocaleLoading, setUserLocaleLoading] = useState(false);

    const navigate = useNavigate();

    const lessonWrapperRef = useRef(null);
    const activeLessonRef = useRef(null);

    const userId = useSelector((state) => state?.user?.info?.id);
    const companyId = useSelector(state => state.sidebar.companyId);
    const [currentCompanyId, setCurrentCompanyId] = useState(companyId);

    const currentCompanyLogo = useSelector((state) => state.sidebar.companyLogo);
    const currentCompanyName = useSelector((state) => state.sidebar.companyName);
    const {userRoleId} = useUserRole(currentCompanyId)

    // User id from "detailed statistics for a user" page.
    // Means that we are showing current user course progress and results and hiding all fucntionality for passing the course in that scenario.
    const statisticsUserId = params?.statisticsUserId;

    const t = useFindTranslationsByString();
    const tNew = useFindTranslationsByString();

    const [isCourseCompletedByUser, setIsCourseCompletedByUser] = useState(false);

    const [course, setCourse] = useState({});
    const [certificate, setCertificate] = useState({});
    const [questions, setQuestions] = useState([]);
    const [questionsLoading, setQuestionsLoading] = useState(true);
    const [isLessonsSidebarOpen, setIsLessonsSidebarOpen] = useState(true);
    const [isTestStarted, setIsTestStarted] = useState(false);

    const [modules, setModules] = useState([]);
    const [modulesLoading, setModulesLoading] = useState(true);
    const [currentLessonCount, setCurrentLessonCount] = useState(1);

    const [currentTest, setCurrentTest] = useState(null);
    const [userTestsInCourse, setUserTestsInCourse] = useState([]);

    const [activeModule, setActiveModule] = useState(0);
    const [activeElement, setActiveElement] = useState(null);
    const [userCourseLocale, setUserCourseLocale] = useState(null);
    const [lastVisitedLesson, setLastVisitedLesson] = useState(null);
    const [lessonContent, setLessonContent] = useState('Loading...');
    const [updatableActiveElement, setUpdatableActiveElement] = useState(null);

    const [questionsDataFromInputs, setQuestionsDataFromInputs] = useState([]);

    const [openedModulesIds, setOpenedModulesIds] = useState([]);

    const [userStatusOnCoursePaused, setUserStatusOnCoursePaused] = useState(false);
    const [courseLanguage, setCourseLanguage] = useState({});
    const [courseTranlatedLocales, setCourseTranlatedLocales] = useState([]);

    useEffect(() => {
        if (!params?.defaultLang && course?.id) {
            setDefaultCourseLanguage(course?.defaultLanguage)
            setIsfewLangs(course?.availableLanguages?.length > 1 ? 'true' : 'false');
        }
    }, [course])

    const getUserCourseStatus = async () => {
        if (statisticsUserId || !userId) return;

        const data = {
            user_ids: [userId],
            course_ids: [+params?.id]
        }

        const res = await new UserApi().getYourCoursesStatuses(data);

        if (res?.success?.data?.[0]?.status) {
            setUserStatusOnCoursePaused(res?.success?.data?.[0]?.status === 2)
        }
    }

    const getUserCourseLocale = async () => {
        setUserLocaleLoading(true);
        const data = {
            locale_data: [
                {
                    user_id: statisticsUserId ? statisticsUserId : userId,
                    course_id: params?.id
                },
            ]
        }

        const res = await new UserApi(params?.id).getUserCourseLocale(data);
        const locale = res?.success?.data?.[0]?.success?.data?.userCourseLocale?.locale;

        if (locale) {
            const lang = COURSE_LANGUAGES_LIST?.find(lang => lang?.code === locale);

            setCourseLanguage(lang);
            setUserCourseLocale(locale);
        } else {
            setCourseLanguage(defaultCourseLanguage);
            setUserCourseLocale(null);
        }

        setUserLocaleLoading(false)
    }

    useEffect(() => {
        if (userId) getUserCourseLocale();
    }, [userId])

    const getCourseTranlatedLocales = async () => {
        const res = await new CourseApi().getAllCourseTranslatedLocales(params?.id);
        if (res?.success?.data) {
            setCourseTranlatedLocales(res?.success?.data);
        }
    }
    useEffect(() => {
        getCourseTranlatedLocales();
    }, [course, modules])

    useEffect(() => {
        if (isFewLangs === 'true' && course.id && !userCourseLocale && !userLocaleLoading) {
            changeLanguageModal.open();
        }
    }, [course, userCourseLocale, lastVisitedLesson])

    useEffect(() => {
        getUserCourseStatus();
    }, [userId])

    const endOfLessonRef = useIntersectionObserver(
        () => {
            if (!activeElement || !userId || modulesLoading || !modulesWithUpdatedTests?.length || userStatusOnCoursePaused) return;
            justUpdateCurrentLesson();
        },
        []
    );

    const handleInputsDataChange = (data, index) => {
        setQuestionsDataFromInputs((prevQuestionsData) => {
            const updatedQuestionsData = [...prevQuestionsData];

            if (index >= updatedQuestionsData.length) {
                for (let i = updatedQuestionsData.length; i <= index; i++) {
                    updatedQuestionsData.push({});
                }
            }

            updatedQuestionsData[index] = data;
            return updatedQuestionsData;
        });
    };

    const onClickModule = (id) => {
        setActiveModule(id);
        setOpenedModulesIds([...new Set([...openedModulesIds, id])])
    };

    const findUserTestsInLesson = (lesson) => {
        return userTestsInCourse?.filter(test => test?.lesson?.id === lesson?.id) || [];
    }

    const onClickElement = (el, index) => {
        const isSmallScreen = window.matchMedia("(max-width: 769px)").matches;
        const userTests = findUserTestsInLesson(el);

        if (isSmallScreen && (el?.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER || userTests?.length > 0)) {
            setIsLessonsSidebarOpen(false);
        }

        updateLastVisitedLesson(el?.id);
        setActiveElement(el);
        setCurrentLessonCount(index + 1);
        setUpdatableActiveElement({...el, userTests: userTests});
        setCurrentTest(null);
        lessonWrapperRef?.current?.scrollTo({
            top: 0
        });
    }

    const fetchCourse = async () => {
        if (!params?.id) return;

        const courseApi = new CourseApi();

        const courseResponse = userRoleId === ROLE_ID_SUPER_ADMIN
            ? await courseApi.getCourseByID(params.id, COURSE_WITH_CERTIFICATE_ONLY_GROUP, languageToGet)
            : await courseApi.getYourCourseByID(params.id, COURSE_WITH_CERTIFICATE_ONLY_GROUP, languageToGet);

        if (courseResponse?.success) {
            setCourse(courseResponse?.success?.data);

            const company = courseResponse?.success?.data?.branch?.company;
            const branch = courseResponse?.success?.data?.branch;

            // In case user current company is different to company that has the course.
            // When user visit an external link
            if (company && +company?.id !== +currentCompanyId) {
                const resChange = await new UserApi().changeCurrentSuperAdminCompany({new_current_company_id: company?.id});

                const cCompany = resChange?.success?.data?.user?.currentCompany;

                if (cCompany) {
                    const updatedCompanyIcon = await returnTemporaryCompanyLink(userId, cCompany);
                    const companyIcon = updatedCompanyIcon?.[0]?.fileUrl;

                    const logo = cCompany?.companyLogo?.iconLogo ? companyIcon : shortLogo;

                    setCurrentCompanyId(company.id)
                    dispatch(setCompanyInfo(company.id, company?.name, logo, branch?.id));
                }
            }
        }
    };

    const fetchData = async (loading) => {
        if (!loading) setQuestionsLoading(true);
        try {
            const lessonApi = new LessonApi();

            const questionsRes = await lessonApi.getAllQuestionsInLesson(activeElement?.id, QUESTIONS_IN_LESSON_GROUP, languageToGet);

            if (questionsRes?.success?.data) {
                const questionsData = questionsRes.success.data;

                questionsData.forEach(question => {
                    if (question?.lesson?.isShuffleAnswers && question.type !== SEQUENCE_ANSWER_CODE && question.type !== COMPARE_ANSWER_CODE) {
                        shuffleArray(question.answers);
                    }

                    if (question.type !== SEQUENCE_ANSWER_CODE && question.type !== COMPARE_ANSWER_CODE) {
                        question.answers = question.answers?.sort(sortBySequence) || []
                    }

                    if (question.type === SEQUENCE_ANSWER_CODE) {
                        shuffleArray(question.answers);
                    }

                    if (question?.lesson?.isShuffleAnswers && question.type === COMPARE_ANSWER_CODE) {
                        shuffleArrayWithWrongAnswers(question.answers, question?.options);
                    }

                    if (!question?.lesson?.isShuffleAnswers && question.type === COMPARE_ANSWER_CODE) {
                        shuffleArray(question.answers);
                        shuffleArrayContent(question?.answers, question?.options)
                    }
                });

                const decodedQuestions = questionsData?.map(question => ({
                    ...question,
                    content: decodeSanitizedInputValue(question?.content),
                    description: decodeSanitizedInputValue(question?.description),
                    title: decodeSanitizedInputValue(question?.title),
                }))

                setQuestions(decodedQuestions.sort(sortByDisplaySequence));
            } else {
                setQuestions([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            if (!loading) setQuestionsLoading(false);
        }
    };

    const returnCompletedByUserLessons = async () => {
        const res = await new LessonApi().getCompletedUserLesssonsInCourse(statisticsUserId ? statisticsUserId : userId, params?.id, languageToGet);

        if (res?.success?.data) {
            return res?.success?.data || [];
        }
    }

    const updateCourseProgress = async (currentProgress) => {
        const allLessons = modulesWithUpdatedTests?.flatMap((module) => module.lessons);
        const completedLessons = allLessons?.filter(lesson => {

            if (lesson?.userTests && lesson?.userTests.length > 0) {
               const latestTest = lesson?.userTests?.length 
                  ? lesson.userTests.reduce((latest, current) => 
                     new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest
                  ) 
                  : null;

                return latestTest?.status === TEST_STATUS_APPROVED;
            } else {
                return lesson.completed;
            }
        });

        const allLessonsCount = allLessons?.length;
        const allCompletedCount = completedLessons?.length;

        let completionPercentage = 0;

        if (allLessonsCount !== 0) {
            completionPercentage = Math.round((allCompletedCount / allLessonsCount) * 100);
        }

        if (!!completionPercentage && +currentProgress !== +completionPercentage) {
            await new UserApi().updateUserProgressOnCourse(userId, params?.id, {progress: completionPercentage})
        }
    }

    const getUserProgressOnCourse = async () => {
        const data = {
            progress_data: [
                {user_id: userId, course_id: params?.id}
            ]
        }

        const res = await new UserApi().getUserProgressOnCourses(data);
        const resOfTheCourse = res?.success?.data?.[0]?.success?.data?.userCourseProgress?.progress;

        if (resOfTheCourse) {
            updateCourseProgress(resOfTheCourse)
        } else {
            updateCourseProgress(null);
        }
    }

    useEffect(() => {
        if (modulesWithUpdatedTests?.length && userId && params?.id) getUserProgressOnCourse();
    }, [modules])

    const fetchModulesData = async () => {
        try {
            setModulesLoading(true);

            const moduleApi = new ModuleApi();

            const modulesResponse = userRoleId === ROLE_ID_SUPER_ADMIN
                ? await moduleApi.getModulesInCourse(params.id, MODULES_EXTRA_GROUP, languageToGet)
                : await moduleApi.getYourModulesInCourse(params.id, MODULES_EXTRA_GROUP, languageToGet);

            const modulesData = modulesResponse?.success?.data;
            const modulesThatHaveLessons = modulesData?.filter(module => module?.lessons?.length);
            const sortedModulesData = modulesThatHaveLessons?.sort(sortBySequence);

            const completedLessons = await returnCompletedByUserLessons();

            if (modulesResponse?.error) {
                if (modules.length === 1) setModules([]);
            }

            const modulesWithCount = sortedModulesData?.map((module) => {
                return {
                    ...module,
                    lessons: module?.lessons?.filter(lesson => lesson?.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER || lesson?.questions?.length > 0).filter(lesson => lesson?.status === LESSON_STATUS_ACTIVE).sort(sortBySequence)?.map((lesson) => {
                        // Check if lesson ID is in completed lessons of this module
                        const lessonCompleted = completedLessons?.find(item => item?.id === lesson.id);

                        if (lessonCompleted?.id) {
                            return {
                                ...lessonCompleted,
                                completed: true
                            }
                        } else {
                            return {
                                ...lesson,
                                completed: false
                            }
                        }
                    }),
                };
            })

            setModules(modulesWithCount);

            if (activeElement?.id) {
                const matchingLesson = modulesWithCount?.flatMap((module) => module.lessons)?.find((lesson) => lesson.id === activeElement.id);

                // we get userTests
                const res = await new KnowledgeCheckAPI().getUserTestsFilteredByLesson(currentCompanyId, matchingLesson?.id, statisticsUserId);

                matchingLesson.userTests = [];

                if (res?.success?.data && matchingLesson) {
                    return {...matchingLesson, userTests: [...res?.success?.data] || []};
                }
            } else {
                return modulesWithCount;
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setModulesLoading(false);
        }
    }

    const updateLessonAvailability = (lessonToUpdate, isCompleted) => {
        setModules((prevModules) => {
            return prevModules?.map((module) => {
                return {
                    ...module,
                    lessons: module?.lessons?.map((lesson) => {
                        if (lesson?.id === lessonToUpdate?.id) {
                            return {
                                ...lesson,
                                completed: isCompleted,
                            };
                        }
                        return lesson;
                    }),
                };
            });
        });
    };

    const onPrevLesson = () => {
        const currentModuleIdx = modules?.findIndex((module) =>
            module.lessons.some((lesson) => lesson.id === activeElement?.id)
        );
        const curLessonIdx = modules[currentModuleIdx]?.lessons.findIndex(lesson => lesson.id === activeElement.id);

        if (modules[currentModuleIdx].lessons[curLessonIdx - 1]) {
            onClickElement(modules[currentModuleIdx].lessons[curLessonIdx - 1], curLessonIdx - 1);
        }
    }

    const updateCurrentLessonAndMoveToNext = async (currentModuleIdx, curLessonIdx) => {
        if (modules[currentModuleIdx]?.lessons[curLessonIdx] && !modules[currentModuleIdx]?.lessons[curLessonIdx]?.completed) {
            const res = await new UserApi().updateLessonCompletedStatus(userId, modules[currentModuleIdx].lessons[curLessonIdx]?.id, {
                status: true
            });
            if (res?.success?.data) {
                updateLessonAvailability(modules[currentModuleIdx]?.lessons[curLessonIdx], true);
            }
        }

        if (modules[currentModuleIdx]?.lessons[curLessonIdx + 1]) {
            onClickModule(modules[currentModuleIdx].id);
            onClickElement(modules[currentModuleIdx].lessons[curLessonIdx + 1], curLessonIdx + 1);
        } else {
            const nextModuleIdx = currentModuleIdx + 1;
            if (modules[nextModuleIdx]) {
                const firstLessonId = modules[nextModuleIdx]?.lessons[0]?.id;

                if (firstLessonId) {
                    onClickModule(modules[nextModuleIdx].id);
                    onClickElement(modules[nextModuleIdx].lessons[0], 0);
                }
            } else {
                setIsTestStarted(false)
            }
        }
    }

    const justUpdateCurrentLesson = async () => {
        const currentModuleIdx = modulesWithUpdatedTests?.findIndex((module) =>
            module.lessons.some((lesson) => lesson.id === activeElement?.id));
        const curLessonIdx = modulesWithUpdatedTests[currentModuleIdx]?.lessons.findIndex(lesson => lesson.id === activeElement.id);
        const curLesson = modulesWithUpdatedTests[currentModuleIdx]?.lessons.find(lesson => lesson.id === activeElement.id);

        if (curLesson?.completed) return; // don't update it if its already completed.

        const res = await new UserApi().updateLessonCompletedStatus(userId, modules[currentModuleIdx].lessons[curLessonIdx]?.id, {
            status: true
        });

        if (res?.success?.data) {
            updateLessonAvailability(modules[currentModuleIdx]?.lessons[curLessonIdx], true);
        }
    }

    const onNextLesson = async () => {
        if (
            !statisticsUserId
            && course?.isProgressiveValidationLock
            && course?.isLinear
            && (currentTest?.status === TEST_STATUS_ON_CHECK || currentTest?.status === TEST_STATUS_ON_REWORK)
            && !isTestStarted
            && activeElement?.type === LESSON_TYPE_WITH_TESTING_NUMBER
        ) {
            return;
        }
        const currentModuleIdx = modules?.findIndex((module) =>
            module.lessons.some((lesson) => lesson.id === activeElement?.id)
        );
        const curLessonIdx = modules[currentModuleIdx]?.lessons.findIndex(lesson => lesson.id === activeElement.id);

        lessonWrapperRef?.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        updateCurrentLessonAndMoveToNext(currentModuleIdx, curLessonIdx);
        setModulesLoading(false);
    }

    useEffect(() => {
        setLessonContent('Loading...')
    }, [activeElement]);

    const getQuestionsAndAnswers = async () => {
        await fetchData();
    };

    const restartTest = () => {
        setIsTestStarted(false);
        startTestModal.open();
    }

    const isCheckLangs = setIsfewLangs === 'true' ? (!!courseLanguage?.id) : true

    useEffect(() => {
        if (userRoleId && isCheckLangs) fetchCourse();
    }, [params?.id, userRoleId]);

    const returnCurrentTest = () => {
      const userTests = updatableActiveElement?.userTests?.filter(test => test.user.id === +userId)

      const latestTest = userTests?.length 
            ? userTests.reduce((latest, current) => 
               new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest
            ) 
            : null;

        return latestTest;
    }

    const returnCurrentUserTest = () => {
      const userTests = updatableActiveElement?.userTests?.filter(test => test.user.id === +statisticsUserId)

     const latestTest = userTests?.length 
         ? userTests.reduce((latest, current) => 
            new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest
         ) 
         : null;

        return latestTest
    }

    useEffect(() => {
        if (updatableActiveElement) {
            const test = statisticsUserId ? returnCurrentUserTest() : returnCurrentTest();
            
            setCurrentTest(test);

            if (updatableActiveElement?.type === LESSON_TYPE_WITH_TESTING_NUMBER &&
                test?.status !== TEST_STATUS_APPROVED &&
                test?.status !== TEST_STATUS_ON_CHECK && !userStatusOnCoursePaused) {
                startTestModal.open();
            }
        }
    }, [updatableActiveElement]);

    useEffect(() => {
        if (activeElement?.id) {
            getQuestionsAndAnswers();
            setQuestionsDataFromInputs([]);
            setIsTestStarted(false);
        }
    }, [activeElement?.id]);

    const activeModuleData = modules?.find((module) =>
        module?.lessons?.some((lesson) => lesson.id === activeElement?.id)
    );

    const onTestRedirect = (testId, isStudView) => {
        const urlStart = "/knowledge_check";
        const testUrl = statisticsUserId && !isStudView
            ? `${urlStart}/check/${testId}`
            : `${urlStart}/info/${testId}`;

        const target = isMobileDevice() ? '_self' : '_blank';
        window.open(testUrl, target);
    };

    const onRedirectToCoursesPage = () => {
        navigate(`/courses`);
    };

    const toggleLessonsSidebar = () => {
        setIsLessonsSidebarOpen(!isLessonsSidebarOpen);
    }

    const isLastLesson = () => {
        return modules[modules?.length - 1]?.lessons?.[modules[modules?.length - 1].lessons?.length - 1]?.id === activeElement?.id
    }

    const getCompletedCourseStatus = async () => {
        const res = await new CourseApi().getCompletedCourseStatus(params?.id, statisticsUserId ? statisticsUserId : userId);

        if (res?.success?.data?.status) {
            const status = res?.success?.data?.status;

            if (typeof status == "boolean") {
                setIsCourseCompletedByUser(status)
            } else {
                setIsCourseCompletedByUser(false)
            }
        } else {
            setIsCourseCompletedByUser(false)
        }
    }

    useEffect(() => {
        if (params?.id && userId) {
            getCompletedCourseStatus()
        }
    }, [params, userId])

    const percentagesToPassCourse = +course?.percentageToReceiveCertificate || null;

    const isPassedTestsEqualLessonsTypeTest = () => {
        const combinedLessonsTypeTest = modules.flatMap((module) =>
            module.lessons.filter((lesson) => lesson.type === LESSON_TYPE_WITH_TESTING_NUMBER)
        );

        const filteredUserTests = userTestsInCourse?.filter(test => {
            return combinedLessonsTypeTest?.some(combinedLesson => combinedLesson?.id === test?.lesson.id);
        });

        return filteredUserTests?.length === combinedLessonsTypeTest.length
    }

    const isSuccessfullyPassedTestsEqualLessonsTypeTest = () => {
        const combinedLessonsTypeTest = modules.flatMap((module) =>
            module.lessons.filter((lesson) => lesson.type === LESSON_TYPE_WITH_TESTING_NUMBER)
        );

        const filteredUserTests = userTestsInCourse.filter(test => {
            return combinedLessonsTypeTest?.some(combinedLesson => combinedLesson?.id === test?.lesson.id);
        });

        const testsThatPassedSuccessfuly = filteredUserTests?.filter(test => test?.status === TEST_STATUS_APPROVED);

        return testsThatPassedSuccessfuly?.length === combinedLessonsTypeTest.length
    }

    const calculatedCourseResult = () => {
        const totalScore = userTestsInCourse?.reduce((sum, test) => sum + test.successPercentage, 0);
        const averageScore = (totalScore / userTestsInCourse?.length)?.toFixed(2);
        return isNaN(averageScore) ? 100 : averageScore;
    }

    const isUserMatchedScore = () => {
        return +percentagesToPassCourse <= +calculatedCourseResult()
    }

    const getTestsInCourse = async () => {
        const res = await new KnowledgeCheckAPI().getYourTestsFilteredByCourse(currentCompanyId, params?.id);
        if (res?.success?.data) {
            setUserTestsInCourse(res?.success?.data);

            return res?.success?.data;
        }
    }

    const getUserTestsInCourse = async () => {
        const res = await new KnowledgeCheckAPI().getUserTestsFilteredByCourse(currentCompanyId, params?.id, statisticsUserId);
        if (res?.success?.data) {
            setUserTestsInCourse(res?.success?.data);
        }
    }

    const modulesWithUpdatedTests = modules?.map((module, moduleIndex) => {
        const lessonsWithTests = module?.lessons?.map((lesson, lessonIndex) => {
            const matchingTests = userTestsInCourse?.filter(test => test?.lesson?.id === lesson?.id);

            if (moduleIndex === 0 && lessonIndex === 0 && course?.isLinear) {
                return {
                    ...lesson,
                    unlocked: !userStatusOnCoursePaused,
                    userTests: []
                };
            }

            if (matchingTests?.length) {
                return {
                    ...lesson,
                    userTests: matchingTests
                };
            } else {
                return {...lesson, userTests: []};
            }
        });

        const lessonsWithUnlockedStatuses = lessonsWithTests.map((lesson, idx) => {
            const currentLesson = lessonsWithTests[idx];
            const nextLesson = lessonsWithTests[idx + 1];
            const nextLessonInNextModule = modules[moduleIndex + 1]?.lessons?.[0];

            if (currentLesson?.completed) {
                if (nextLesson && !userStatusOnCoursePaused) {
                    nextLesson.unlocked = true;
                } else if (nextLessonInNextModule && !userStatusOnCoursePaused) {
                    nextLessonInNextModule.unlocked = true;
                } else if (nextLesson && userStatusOnCoursePaused) {
                    nextLesson.unlocked = false;
                } else if (nextLessonInNextModule && userStatusOnCoursePaused) {
                    nextLessonInNextModule.unlocked = false;
                }
            } else {
                if (nextLesson) {
                    nextLesson.unlocked = false;
                } else if (nextLessonInNextModule) {
                    nextLessonInNextModule.unlocked = false;
                }
            }

            return lesson;
        });

        return {
            ...module,
            lessons: lessonsWithUnlockedStatuses
        };
    });

    useEffect(() => {
        if (params?.id && !statisticsUserId && currentCompanyId) {
            getTestsInCourse();
        }
    }, [params, isTestStarted, currentCompanyId])
    
    useEffect(() => {
        if (statisticsUserId && currentCompanyId) {
            getUserTestsInCourse();
        }
    }, [currentCompanyId])

    const sendFinishCourse = async (status = true) => {
        const res = await new UserApi().completeUserCourse(userId, params?.id, {
            status
        });

        if (res?.success) {
            fetchCourse();
            getCompletedCourseStatus();
        }

        if (!status || !course?.isIssueCertificate) return;

        const resCertificate = await new CertificateApi().createCertificate({
            user_id: userId,
            course_id: params?.id,
            certificate_template_id: course?.certificateTemplate?.id || 1,
            date_completed: moment().format("Y-MM-DD"),
            result: `${calculatedCourseResult()}`
        });

        if (resCertificate?.success?.data) {
            const item = resCertificate?.success?.data?.certificate;

            setCertificate({
                ...item,
                course_name: item?.course?.name,
                result: item?.result,
                serial_number: item?.serialNumber,
                user_name: `${item?.user?.firstName} ${item?.user?.lastName || ''}`,
                company_logo: currentCompanyLogo,
                company_name: currentCompanyName,
                end_date: item?.dateCompleted,
                color_palette: item?.certificateTemplate?.colorPalette || "1",
                font_family: item?.certificateTemplate?.fontFamily,
                sertificateTemplateId: item?.certificateTemplate?.id,
                language: item?.certificateTemplate?.language,
                name: item?.certificateTemplate?.name,
                type: item?.certificateTemplate?.type,
            });
        }
    }

    const getUserCertificate = async () => {
        const res = await new CertificateApi().getUserCertificates(1, 1, userId, companyId, null, null, null, course?.id);

        if (res.success?.data?.length) {
            const item = res.success?.data[0];

            setCertificate({
                ...item,
                course_name: item?.course?.name,
                result: item?.result,
                serial_number: item?.serialNumber,
                user_name: `${item?.user?.firstName} ${item?.user?.lastName || ''}`,
                company_logo: currentCompanyLogo,
                company_name: currentCompanyName,
                end_date: item?.dateCompleted,
                color_palette: item?.certificateTemplate?.colorPalette || "1",
                font_family: item?.certificateTemplate?.fontFamily,
                sertificateTemplateId: item?.certificateTemplate?.id,
                language: item?.certificateTemplate?.language,
                name: item?.certificateTemplate?.name,
                type: item?.certificateTemplate?.type,
            });
        }
    }

    useEffect(() => {
        if (userId && course?.id) {
            getUserCertificate();
        }
    }, [course, userId])

    const setFinishStatusToTrue = async () => {
        const res = await new UserApi().completeUserCourse(userId, params?.id, {
            status: true
        });

        setIsCourseCompletedByUser(true);

        if (res?.success) {
            fetchCourse();
        }
    }

    const areAllLessonsOfType = () => {
        return modules.every(module =>
            module.lessons.every(lesson => lesson.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER)
        );
    };

    const onFinishCourse = () => {
        if (areAllLessonsOfType()) {
            sendFinishCourse();
            endCourseModalCongrats.open();
            return;
        }

        if (!userTestsInCourse?.length) {
            failedCourseModal.open();
            return;
        }

        if (!course?.isDetailedCourseCompletion && course?.isIssueCertificate && userTestsInCourse?.length) {
            sendFinishCourse();
            if (isUserMatchedScore()) {
                endCourseModalCongrats.open();
            } else {
                endCourseModalFailed.open();
            }
            return;
        }

        if (course?.isDetailedCourseCompletion && course?.isIssueCertificate && isSuccessfullyPassedTestsEqualLessonsTypeTest()) {
            sendFinishCourse();
            if (isUserMatchedScore()) {
                endCourseModalCongrats.open();
            } else {
                endCourseModalFailed.open();
            }
            return;
        }

        if (!course?.isIssueCertificate && isPassedTestsEqualLessonsTypeTest()) {
            setFinishStatusToTrue();

            if (isUserMatchedScore()) {
                endCourseModalCongrats.open();
            } else {
                endCourseModalFailed.open();
            }
            return;
        }

        failedCourseModal.open();
    }

    const onRetakeCourse = async () => {
        const res = await new UserApi().deleteProgressInCourse(params?.id, userId);

        if (res?.success) {
            setActiveElement(null);
            setActiveModule(0);
            setCurrentTest(null);
            setOpenedModulesIds([]);
            updateLastVisitedLesson(modules?.[0]?.lessons?.[0]?.id).then(() => {
                setModules([]);
                fetchModulesData();
            })
            fetchCourse();
            getCompletedCourseStatus();
            setUserTestsInCourse([]);

            toast(<ToastMsg text={'You can pass the course again!'}/>)
        }

        if (res?.error?.message) {
            toast(<ToastMsg text={res?.error?.message} isError/>)
        }

        retakeModal.close();
    }

    const previousContentRequest = useRef(null);

    const languageToGet = courseTranlatedLocales?.includes(courseLanguage?.code)
        ? courseLanguage?.code
        : defaultCourseLanguage;

    const getUpdatedLesson = async () => {
        const controller = new AbortController();
        if (previousContentRequest?.current) {
            previousContentRequest.current.abort();
        }

        previousContentRequest.current = controller;
        const signal = controller.signal;

        const res = await new LessonApi().getOneLesson(activeElement?.id, true, signal, languageToGet);
        if (res?.success?.data) {
            const decodedContent = decodeSanitizedContent(res?.success?.data?.content);
            const displayedContent = replaceIframesWithLiteYouTube(decodedContent);
       
            const content = await returnUpdatedContent(displayedContent, userId, params?.id, true);

           setLessonContent(content || displayedContent);
        }

        if (res?.error?.message && res?.error?.message !== 'canceled') {
            return toast(<ToastMsg text={res.error.message} isError/>)
        }
    }

    useEffect(() => {
        if (activeElement?.id && activeElement?.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER) getUpdatedLesson();
    }, [activeElement])

    const findLessonAndModuleById = (lessonId) => {
        for (let i = 0; i < modulesWithUpdatedTests.length; i++) {
            const lesson = modulesWithUpdatedTests[i]?.lessons.find(lesson => +lesson.id === +lessonId);

            if (lesson && !lesson?.unlocked && course?.isLinear && !userStatusOnCoursePaused) return null; // Return null if the lesson is not unlocked

            if (lesson && !lesson?.completed && course?.isLinear && userStatusOnCoursePaused) return null; // Return null if the lesson is not completed and course on pause

            if (lesson) {
                return {module: modulesWithUpdatedTests[i], lesson: lesson};
            }
        }

        return null;
    };

    const findLastVisitedLesson = async () => {
        if (activeElement?.id) return;

        const res = await new LessonApi().getLastVisitedLesson(userId, params?.id);

        if (res?.success?.data) {
            setLastVisitedLesson(res?.success?.data);

            const lessonID = res?.success?.data?.userCourseLessonLastVisited?.lesson?.id;

            const activeModuleAndLessonData = findLessonAndModuleById(lessonID);

            if (activeModuleAndLessonData?.module?.id) {
                const lessonData = activeModuleAndLessonData?.lesson;
               //  const userTests = await findUserTestsInLesson(lessonData);
               const tests = await getTestsInCourse();
               const userTests = tests?.filter(test => test?.lesson?.id === lessonData?.id) || [];

              const latestTest = userTests?.length 
                  ? userTests.reduce((latest, current) => 
                     new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest
                  ) 
                  : null;

                onClickModule(activeModuleAndLessonData?.module?.id);

                if (lessonData?.completed && userStatusOnCoursePaused) {
                    setActiveElement(lessonData);
                } else if (!lessonData?.completed && userStatusOnCoursePaused && latestTest?.status === 1) {
                    setActiveElement(lessonData);
                } else if (!userStatusOnCoursePaused) {
                    setActiveElement(lessonData);
                }

                setUpdatableActiveElement({
                    ...lessonData,
                    userTests
                });

                activeLessonRef?.current?.scrollIntoView({behavior: 'smooth', block: 'center'})
            }
        }
    }

    const updateLastVisitedLesson = async (lessonId) => {
        if (!lessonId || statisticsUserId) return;

        const data = {
            lesson_id: lessonId
        }
        await new LessonApi().updateLastVisitedLesson(userId, params?.id, data);
    }

    useEffect(() => {
        if (statisticsUserId) return;

        if (modules?.length && userId && params?.id) {
            findLastVisitedLesson();
        }
    }, [modulesLoading, params, userId])

    useEffect(() => {
        if (!course?.isLinear || statisticsUserId) return;

        if ((currentTest?.status === TEST_STATUS_ON_CHECK && !course?.isProgressiveValidationLock)) {
            justUpdateCurrentLesson();
        }
    }, [currentTest, updatableActiveElement])

    const changeUserCourseLocale = async (locale) => {
        const res = await new UserApi(params?.id).changeUserCourseLocale({locale: locale?.code}, params?.id, userId);

        if (res?.success?.message) {
            return toast(<ToastMsg text={"Course language has been successfully changed"}/>)
        }
    }

    const changeCourseLanguage = (lang) => {
        setCourseLanguage(lang);
        changeUserCourseLocale(lang);
    }

    useEffect(() => {
        if (courseLanguage?.code && (userId || statisticsUserId)) {
            fetchModulesData()
            fetchCourse();
            fetchData();
            if (activeElement?.id) getUpdatedLesson();
        }
    }, [courseLanguage])


    if (!course?.id && !modulesLoading) {
        return (
            <MainLayout>
                <div className={styles.no_access}>
                    <p>You have no access to the course </p>
                    <p>or the course does no exist.</p>
                </div>
            </MainLayout>
        )
    }

    const isAdminViewForReworkAndFailedTests = ((currentTest?.status === TEST_STATUS_ON_REWORK || currentTest?.status === TEST_STATUS_REJECTED) && (activeElement?.type === LESSON_TYPE_WITH_TESTING_NUMBER && statisticsUserId && !isTestStarted));

    const isShowTestResults = isAdminViewForReworkAndFailedTests || currentTest?.status === TEST_STATUS_APPROVED && !isTestStarted && activeElement?.type === LESSON_TYPE_WITH_TESTING_NUMBER;

    const initialAvailableLanguages = COURSE_LANGUAGES_LIST?.filter(lang =>
        course?.availableLanguages?.includes(lang?.code)
    );

    const defaultLang = COURSE_LANGUAGES_LIST?.find(lang =>
        course?.defaultLanguage === lang?.code
    );

    const isDefaultLangInAvailableLanguages = course?.availableLanguages?.some(item => item === defaultLang?.code);

    const availableLanguages = isDefaultLangInAvailableLanguages
        ? initialAvailableLanguages
        : [...initialAvailableLanguages, defaultLang];

    const isUserHasTestsWithPassedRes = updatableActiveElement?.userTests?.filter(t => t?.status === 1 || t?.status === 0)?.length

    const isNoNeedArrowToCloseMenu = !updatableActiveElement?.id || (updatableActiveElement?.type === LESSON_TYPE_WITH_TESTING_NUMBER && !isUserHasTestsWithPassedRes);

    return (
        <MainLayout>
            <div className={styles.courseContentPage}>
                {modulesLoading &&
                    <div className="default_loader_wrapper"><Loader/></div>
                }

                <CourseMobileHeader
                    title={activeElement?.name?.length > 0 ? activeElement?.name : ''}
                    isMenuOpened={isLessonsSidebarOpen}
                    onCloseMenu={toggleLessonsSidebar}
                    onExitCourse={() => navigate('/courses')}
                    isNoNeedArrowToCloseMenu={isNoNeedArrowToCloseMenu}
                />

                <SubmenuBlock
                    userStatusOnCoursePaused={userStatusOnCoursePaused}
                    activeLessonRef={activeLessonRef}
                    activeElement={activeElement}
                    activeModule={activeModule}
                    onClickElement={onClickElement}
                    onClickModule={onClickModule}
                    course={course}
                    questionsDataFromInputs={questionsDataFromInputs}
                    fetchData={fetchModulesData}
                    loading={modulesLoading}
                    modules={modulesWithUpdatedTests}
                    questions={questions}
                    setModules={setModules}
                    fetchCourse={fetchCourse}
                    isLessonsSidebarOpen={isLessonsSidebarOpen}
                    toggleLessonsSidebar={toggleLessonsSidebar}
                    isTestStarted={isTestStarted}
                    userId={userId}
                    setOpenedModulesIds={setOpenedModulesIds}
                    openedModulesIds={openedModulesIds}
                    courseLanguage={courseLanguage}
                    changeCourseLanguage={changeCourseLanguage}
                    isFewLangs={isFewLangs}
                />

                {isShowTestResults
                    &&
                    <div className={styles.blockResult}>
                        <div>
                            <span
                                className={cn(styles.lesson_subtitle, styles.lesson_subtitle__res)}> {currentLessonCount} part / {activeModuleData?.lessons?.length}</span>
                            <h3 className={cn(styles.lesson_name, styles.lesson_name_res)}>{activeElement?.name}</h3>

                            <TestResultsBlock
                                originalWidth
                                comment={currentTest?.commentTests?.[0]}
                                status={+currentTest?.status}
                                score={currentTest?.successPercentage?.toFixed(2)}
                                courseId={params?.id}
                            />
                            <div className={styles.res_page_btn}>
                                <Button onClick={() => onTestRedirect(currentTest?.id, true)}
                                        title={t('Go to results page')}/>
                            </div>
                        </div>
                        <div>
                            {!isCourseCompletedByUser && !statisticsUserId &&
                                <div className={styles.completeLessonEl} ref={endOfLessonRef}></div>
                            }

                            {isCourseCompletedByUser && !statisticsUserId && userRoleId !== ROLE_ID_STUDENT &&
                                <div className={styles.completeLessonEl} ref={endOfLessonRef}></div>
                            }

                            <div className={styles.end_lesson_btns}>
                                {!isCourseCompletedByUser && !statisticsUserId && !userStatusOnCoursePaused &&
                                    <>
                                        <Button
                                            onClick={onPrevLesson}
                                            disabled={currentLessonCount === 1}
                                            image={arrowLeft}
                                            isBlack
                                            title={t('Previous')}
                                        />
                                        <Button
                                            image={arrowRight}
                                            disabled={modulesLoading}
                                            onClick={isLastLesson() ? onFinishCourse : onNextLesson}
                                            title={isLastLesson() ? t('Finish the course') : t('Next')}
                                            imageRight
                                        />
                                    </>
                                }

                                {isCourseCompletedByUser &&
                                    <div className={styles.course_finished}>
                                        <div className={styles.end}>
                                            <img src={salute} alt=""/>
                                            <h2>{t("The course has been finished")} {statisticsUserId ? t('By user') : ''}!</h2>
                                        </div>


                                        {course?.isIssueCertificate &&
                                            <div className={styles.certificates_btn}>
                                                <Button onClick={certificateModal.open} title={'Certificate'}/>
                                            </div>
                                        }

                                        {!statisticsUserId &&
                                            <div className={styles.end_course_btns}>
                                                <Button isBlack onClick={retakeModal.open} title={'Retake course'}/>
                                                <Button isBlack onClick={onRedirectToCoursesPage} title={'Next time'}/>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {currentTest?.status === TEST_STATUS_ON_CHECK && !isTestStarted && activeElement?.type === LESSON_TYPE_WITH_TESTING_NUMBER &&
                    <div className={cn(styles.blockResult, styles.test_checking_status_wrap)}>
                        <div className={styles.test_checking_status}>
                            {/*<span className={styles.lesson_subtitle}> {currentLessonCount} lesson / {activeModuleData?.lessons?.length}</span>*/}
                            {/*   <h3 className={styles.lesson_name}>{activeElement?.name}</h3>*/}
                            <img src={testCheckingIcon} alt={"Test is checking"}/>
                            <h3>
                                {t("Test results are in progress:")}
                            </h3>
                            {!statisticsUserId &&
                                <p className={styles.gray}>
                                    {course?.isProgressiveValidationLock && course?.isLinear &&
                                        t("Please wait until the test has been checked, you cannot move on to the next lesson.")
                                    }
                                    <p></p>
                                    {t("As soon as an administrator checks your test, you will be notified of the result. You will also be able to find verified tests in the 'Knowledge Check' section")}.
                                </p>
                            }
                            {statisticsUserId &&
                                <p className={styles.gray}>
                                    {t("Given that you're monitoring the user's progress in the course and have access to the course materials, you can navigate to the test page and assess the user's performance now")}.
                                </p>
                            }
                        </div>

                        <div className={cn(styles.end_lesson_btns, styles.tet_checking)}>
                            <Button onClick={() => onTestRedirect(currentTest?.id)} title={t('Go to test page')}
                                    isBlack/>
                            <Button
                                imageRight
                                image={arrowRight}
                                disabled={modulesLoading || (!statisticsUserId && course?.isProgressiveValidationLock && course?.isLinear)}
                                onClick={isLastLesson() ? onFinishCourse : onNextLesson}
                                title={isLastLesson() ? t('Finish the course') : t('Next')}
                            />
                        </div>
                    </div>
                }

                {activeElement?.type === LESSON_TYPE_WITH_TESTING_NUMBER &&
                    isTestStarted && (
                        <div
                            id="question_block"
                            className={cn(
                                styles.block,
                                styles.questions_block,
                                !isLessonsSidebarOpen && styles.questions_block_wider
                            )}
                        >
                            <QuestionsBlock
                                goToResult={() => onTestRedirect(currentTest?.id, true)}
                                questions={questions}
                                modules={modules}
                                setQuestions={setQuestions}
                                handleInputsDataChange={handleInputsDataChange}
                                questionsDataFromInputs={questionsDataFromInputs}
                                setQuestionsDataFromInputs={setQuestionsDataFromInputs}
                                questionsLoading={questionsLoading}
                                activeTest={activeElement}
                                updatableActiveElement={updatableActiveElement}
                                fetchModulesData={fetchModulesData}
                                onClickElement={onClickElement}
                                fetchQuestionsData={fetchData}
                                isLessonsSidebarOpen={isLessonsSidebarOpen}
                                onNextLesson={onNextLesson}
                                setIsTestStarted={setIsTestStarted}
                                restartTest={restartTest}
                                setUpdatableActiveElement={setUpdatableActiveElement}
                                setIsLessonsSidebarOpen={setIsLessonsSidebarOpen}
                            />
                        </div>
                    )}

                <StartTestModal
                    onClose={() => {
                        startTestModal.close();
                        setIsLessonsSidebarOpen(true);
                    }}
                    isOpen={startTestModal.isActive}
                    isStatisticsUser={statisticsUserId}
                    notFirstTry={currentTest?.status === TEST_STATUS_ON_REWORK || currentTest?.status === TEST_STATUS_REJECTED}
                    maxHeight={statisticsUserId ? '340px' : isMobileDevice() ? "235px" : "230px"}
                    title={
                        statisticsUserId
                            ?
                            t(`The test was not passed yet`)
                            :
                            t(`Ready to start the test?`)
                    }
                    onConfirm={() => {
                        const noAttemptsCondition = updatableActiveElement?.passAttempts > 0 ? currentTest?.passAttemptsCounters?.length >= updatableActiveElement?.passAttempts : course?.globalTestTryCount > 0 && currentTest?.passAttemptsCounters?.length >= course?.globalTestTryCount

                        if (noAttemptsCondition && currentTest?.status === TEST_STATUS_REJECTED) {
                            toast(<ToastMsg text={"You do not have any more attempts. Contact administrator or mentor."}
                                            isError/>)
                        } else {
                            setIsTestStarted(true);
                            startTestModal.close();
                        }
                    }}
                />
                <ConfirmModal
                    isLoading={modulesLoading}
                    dontShowLoader
                    confirmButtonText={tNew("Retake course")}
                    onConfirm={onRetakeCourse}
                    onClose={retakeModal.close}
                    isOpen={retakeModal.isActive}
                    subtitle={tNew(
                        "If you restart the course, all your current progress and results will be lost.",
                    )}
                    title={tNew("Are you sure you want to retake the course?")}
                    maxWidth={"416px"}
                />

                <CertificateModal
                    onClose={certificateModal.close}
                    isOpen={certificateModal.isActive}
                    certificate={certificate}
                />
                <EndOfCourseModal
                    icon={congratsIcon}
                    certificateModal={certificateModal}
                    onClose={endCourseModalCongrats.close}
                    isOpen={endCourseModalCongrats.isActive}
                    title={course?.popupTitle ? course?.popupTitle : t(`Congratulations!`)}
                    text={course?.isIssueCertificate ? t("You have successfully completed the course and can now download the certificate") : t('You have successfully completed the course')}
                    popupText={course?.popupText}
                    score={calculatedCourseResult()}
                    certificate={certificate}
                    course={course}
                    maxHeight={isMobileDevice() ? "500px" : '540px'}
                    maxWidth="500px"
                    goToResult={() => onTestRedirect(currentTest?.id, true)}
                />
                <EndOfCourseModal
                    icon={failedIcon}
                    onClose={endCourseModalFailed.close}
                    isOpen={endCourseModalFailed.isActive}
                    title={t(`Unsuccessful attempt`)}
                    text={t("Unfortunately, you did not receive enough points to successfully complete the course")}
                    score={calculatedCourseResult()}
                    onResetCourse={() => {
                        retakeModal.open();
                        endCourseModalFailed.close();
                    }
                    }
                    maxHeight={isMobileDevice() ? "480px" : '540px'}
                    isFailed
                    onLater={onRedirectToCoursesPage}
                    course={course}
                    goToResult={() => onTestRedirect(currentTest?.id, true)}
                />
                <InfoModal
                    icon={alerIcon}
                    onClose={failedCourseModal.close}
                    maxHeight={isMobileDevice() ? "240px" : '350px'}
                    maxWidth={'500px'}
                    isOpen={failedCourseModal.isActive}
                    title={t(`You didn't pass all tests in course!`)}
                    text={t('To finish the course all tests should be passed')}
                />

                {activeElement?.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER && (
                    <div
                        ref={lessonWrapperRef}
                        className={cn(
                            styles.lesson_wrapper,
                            activeElement?.isProtected ? 'noselect' : '',
                            styles.block
                        )}
                        onContextMenu={(e) => activeElement?.isProtected && e.preventDefault()}
                    >
                        <span
                            className={styles.lesson_subtitle}> {currentLessonCount} {t("lesson")} / {activeModuleData?.lessons?.length}</span>
                        <h3 className={styles.lesson_name}>{
                            activeModuleData?.lessons?.find(lesson => lesson?.id === activeElement?.id)?.name
                        }</h3>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: replaceIframesWithLiteYouTube(
                                    decodeSanitizedContent(lessonContent)
                                ),
                            }}
                        />

                        {lessonContent !== 'Loading...' && !isCourseCompletedByUser && !statisticsUserId &&
                            <div
                                className={styles.completeLessonEl}
                                ref={endOfLessonRef}>
                            </div>
                        }

                        {isCourseCompletedByUser && !statisticsUserId && userRoleId !== ROLE_ID_STUDENT &&
                            <div
                                className={styles.completeLessonEl}
                                ref={endOfLessonRef}>
                            </div>
                        }

                        <div className={styles.end_lesson_btns}>
                            {!isCourseCompletedByUser && !statisticsUserId && !userStatusOnCoursePaused &&
                                <>
                                    <Button
                                        onClick={onPrevLesson}
                                        disabled={currentLessonCount === 1}
                                        image={arrowLeft}
                                        isBlack
                                        title={t('Previous')}
                                    />
                                    <Button
                                        imageRight
                                        image={arrowRight}
                                        disabled={modulesLoading}
                                        onClick={isLastLesson() ? onFinishCourse : onNextLesson}
                                        title={isLastLesson() ? t('Finish the course') : t('Next')}
                                    />
                                </>
                            }

                            {isCourseCompletedByUser &&
                                <div className={styles.course_finished}>
                                    <div className={styles.end}>
                                        <img src={salute} alt=""/>
                                        <h2>{t("The course has been finished!")} {statisticsUserId ? t('by user') : ''}</h2>
                                    </div>

                                    {course?.isIssueCertificate &&
                                        <div className={styles.certificates_btn}>
                                            <Button onClick={certificateModal.open} title={'Certificate'}/>
                                        </div>
                                    }

                                    {!statisticsUserId &&
                                        <div className={styles.end_course_btns}>
                                            <Button isBlack onClick={retakeModal.open} title={t('Retake course')}/>
                                            <Button isBlack onClick={onRedirectToCoursesPage} title={t('Next time')}/>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                )}

                <ChangeCourseLanguageModal
                    confirmButtonText={t('Confirm')}
                    onConfirm={() => {
                        changeLanguageModal.close();
                        setUserCourseLocale(courseLanguage?.code);
                    }}
                    onClose={changeLanguageModal.close}
                    maxHeight="340px"
                    onSelect={changeCourseLanguage}
                    selectedItem={courseLanguage}
                    isOpen={changeLanguageModal.isActive}
                    title={t("Choose a language")}
                    subtitle={t(`You can choose the language in which you want to take the course`)}
                    isDropdown
                    languages={availableLanguages}
                />
            </div>
        </MainLayout>
    );
};

export default CourseContentPage;

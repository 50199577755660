import React, {useEffect, useState} from 'react';

import   './textarea.scss'
import  Quill  from "react-quill-new"
import 'react-quill-new/dist/quill.snow.css';

// Настраиваем доступные модули
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }],
  ],
};


const formats = ["bold", "italic", "underline", "strike", "color"];

const TextAreaWithEditor = ({ initialValue,  placeHolder, onChange }) => {
  const [value, setValue] = useState("")

  const handleTextChange = (value) => {
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue]);

  return (
        <div
            className={"textarea_editor_wrapper"}
        >
          <Quill
              modules={modules}
              formats={formats}
              placeholder={placeHolder}
              className={'quill_wrapper'}
              theme="snow"
              id={'description'}
              value={value}
              onChange={(value) => {
                  handleTextChange(value)
              }}
          />
        </div>
  );
};

export default TextAreaWithEditor;
import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import Input from "../Input/Input";
import Loader from "../Loader/Loader";
import arrow from "../../assets/images/symbols/arrow.svg";
import arrowWhite from "../../assets/images/symbols/arrow_white.svg";
import cn from "classnames";
import Checkbox from "../Checkbox/Checkbox";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

const DropdownWithCheckboxes = ({
  data,
  placeholder,
  onChange,
  value = "",
  field = null,
  title = null,
  isWhiteBlack = false,
  maxHeight,
  isRadiobuttons = false,
  dontCloseOnClick = false,
  selectedItems,
  mainItem,
  inputClassName = "",
  icon,
  isAddLoading,
  isMultiple= true,
    isDark
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef();
  const inputRef = useRef();
  const inputRefTop = useRef();

  const onClose = () => {
    setIsOpen(false);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onChangeValue = (val) => onChange(val);

  useOutsideClick(dropdownRef, onClose, inputRef);

  return (
    <>
      {title && <p className={styles.title}>{title}</p>}
      <div
        className={cn(styles.dropdown_wrapper, isDark && styles.dark)}
        onClick={onToggle}
        ref={inputRef}
      >
        <div className={styles.front}>
          <Input
            placeholder={placeholder}
            ref={inputRefTop}
            customClass={cn(
              isOpen ? styles.input_open : styles.inputWrapper,
              inputClassName,
                (value || selectedItems?.length) && styles.has_value
            )}
            disabled={true}
            value={value}
            isWhiteBlack={isWhiteBlack}
            icon={icon}
          />
        </div>
        <div className={styles.click_el}></div>
        {isAddLoading && isOpen && (
          <div className={styles.loader_wrapper}>
            <Loader size={"small"} />
          </div>
        )}
        <img
          src={isWhiteBlack ? arrowWhite : arrow}
          alt={""}
          className={cn(styles.arrow, isOpen && styles.arrow_open)}
        />
        {isOpen && (
          <div
            style={{ maxHeight: maxHeight ? maxHeight : "300px" }}
            className={cn(
              styles.dropdown,
              isWhiteBlack && styles.dropdown_isWhiteBlack,
            )}
          >
            {data?.map((item, key) => {
              const classNameActive = field
                ? cn(
                    styles.dropdown_item,
                    item?.id === value?.id && styles.dropdown_item_active,
                  )
                : cn(
                    styles.dropdown_item,
                    item === value && styles.dropdown_item_active,
                  );

              return (
                <div
                  key={key}
                  onClick={(e) => {
                    if (dontCloseOnClick) e.stopPropagation();
                    onChangeValue(item);
                  }}
                  className={cn(classNameActive, styles.drop_item)}
                >
                  {isMultiple &&
                    <Checkbox
                      hiddenTitle={true}
                      isChecked={
                        isRadiobuttons
                          ? mainItem?.id === item?.id
                          : selectedItems.some((lang) => lang.id === item.id)
                      }
                      className={styles.checkbox}
                      inputClassName={styles.inputCheckbox}
                    />
                  }
                  <div className={styles.info}>
                    <CustomTooltip
                      text={field ? item[field] : item}
                      id={item?.id}
                      limit={15}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default DropdownWithCheckboxes;

import { ROLE_ID_STUDENT, USER_STATUS_ACTIVE, USER_STATUS_BANNED } from "../../constants/user";
import Base from "./Base";

export default class UserApi extends Base {
    register(data) {
        return super.post("api/v1/user/register", data);
    }

    registerManyUsers(data) {
        return super.post("api/v1/user/register-many/", data);
    }

    replaceS3Links(data) {
        return super.post("api/v1/file/get-aws-s3-presigned-urls", data);
    }

    updateUser(userID, data) {
      return super.put(`api/v1/user/${userID}`, data);
    }

    updateUserEmail(userID, data) {
      return super.put(`api/v1/user/${userID}/change-email`, data);
    }

    changeCurrentSuperAdminCompany(data) {
      return super.post(`api/v1/user/change-your-current-company`, data);
    }

    resetPassword(data) {
      return super.post(`api/v1/reset-password-request/process-sending-password-reset-email`, data);
    }

    createNewPasswordByToken(data) {
      return super.post(`api/v1/reset-password-request/reset-your-password`, data);
    }

    changeUserPassword(data) {
      return super.put(`api/v1/user/change-your-password`, data);
    }

    changeUserAvatar(data) {
      return super.post(`api/v1/user/change-your-avatar`, data);
    }

    deleteUserAvatar() {
      return super.delete(`api/v1/user/delete-your-avatar`);
    }

    deleteUser(userID) {
      return super.delete(`api/v1/user/${userID}`);
    }

    deleteProgressInCourse(courseID, userID) {
      return super.delete(`api/v1/user/${userID}/course/${courseID}/delete-progress-in-course`);
    }

    completeUserCourse(userID, courseID, data) {
      return super.put(`api/v1/user/${userID}/course/${courseID}/change-completed-status
      `, data)
    } 

    registerSpecialUser(data, registrationToken) {
        return super.post(`api/v1/user/register/${registrationToken}`, data);
    }

    generateRegistrationToken(data) {
      return super.post(`api/v1/registration-token`, data);
    }

    generateManyRegistrationTokens(data) {
      return super.post(`api/v1/registration-token/create-many`, data);
    }

    getYourUserData() {
        return super.get("api/v1/user/get-your-user-data");
    }

    getYourCoursesStatuses(data) {
        return super.post("api/v1/course/get-users-courses-statuses", data);
    }

    getYourAvatar() {
        return super.get("api/v1/user/get-your-avatar");
    }

    changeYourUserData(data) {
        return super.put(`api/v1/user/change-your-user-data`, data);
    }

    getUsers(page, limit, companyID, searchString) {
        return super.get(`api/v1/user?page=${page}&limit=${limit}&filter[company]=${companyID}${searchString ? `&filter[search]=${searchString}` : ''}&order=desc`);
    }
    
    getExcludedUsers(page, limit, companyID, position, department, notExcludedCompanyId,  searchString) {
        return super.get(`api/v1/user?page=${page}&limit=${limit}&filter[excluded_company]=${companyID}${position ? `&filter[position]=${position}`: ''}${department ? `&filter[department]=${department}`: ''}${searchString ? `&filter[search]=${searchString}` : ''}${notExcludedCompanyId ? `&filter[company]=${notExcludedCompanyId}`: ''}&order=desc`);
    }

    getUsersByRoles(page, limit, companyID, isStudents, isUsersOfSpecialRoles, isBlocked, searchString, signal) {
      let url = `api/v1/user?page=${page}&limit=${limit}&filter[company]=${companyID}`;
  
      if (isStudents) {
          url += `&filter[role]=${ROLE_ID_STUDENT}`;
      }

      if (isUsersOfSpecialRoles) {
          url += `&filter[exclude_special_roles]=${0}`;
      }

      if (!isBlocked) {
          url += `&filter[status]=${USER_STATUS_ACTIVE}`;
      }

      if (isBlocked) {
          url += `&filter[status]=${USER_STATUS_BANNED}`;
      }

      if (searchString) {
          url += `&filter[search]=${searchString}`;
      }

      url += `&order=desc`;
  
      return super.get(url, null, signal);
   }

   getUsersByCourseID({ companyId, limit, page, courseID, searchValue, orderBy, order, serializationGroup, emails}) {
// TODO add &filter[get_user_course_result]=${courseID} to the end of url when backend will be merged to stable
      let url = `api/v1/user?limit=${limit}&page=${page}&filter[company]=${companyId}&filter[users_with_access_to_course]=${courseID}&filter[status]=${USER_STATUS_ACTIVE}`

      if (searchValue) {
         url += `&filter[search]=${searchValue}`;
      }

      if (Array.isArray(emails) && emails?.length > 0) {
         const emailsString = emails?.map(email => `'${email}'`).join(', '); 
         
         url += `&filter[search_by_emails]=[${emailsString}]`;
      }

      if (typeof emails === 'string' && emails?.length > 0) {
         url += `&filter[search]=${emails}`;
      }

      if (orderBy) {
         url += `&order_by=${orderBy}`;
      } else {
         url += `&order_by=progress`;
      }

      if (order) {
         url += `&order=${order}`;
      } else {
         url += `&order=desc`;
      }

      return super.get(url, null, null, serializationGroup);
  }

  getAllUsersOfFolder(folderID, companyID) {
   const url = `api/v1/user?limit=100&page=1&filter[users_see_folder]=${folderID}&filter[company]=${companyID}&filter[status]=${USER_STATUS_ACTIVE}&order=desc`;
   return super.get(url)
 }

   getUsersByFolderID(limit, page, folderID, searchValue, companyID, selectedTeamId, serializationGroup) {
      let url = `api/v1/user?limit=${limit}&page=${page}&filter[users_see_folder]=${folderID}&filter[status]=${USER_STATUS_ACTIVE}&filter[company]=${companyID}`

      if (selectedTeamId) {
         url += `&filter[team]=${selectedTeamId}`;
      }

      if (searchValue) {
         url += `&filter[search]=${searchValue}`;
      }

      url += `&order=desc`;

      return super.get(url, null, null, serializationGroup);
  }

    getActiveUsersOfCompany(page, limit, companyID, searchValue, isStudents, isSpecialUsers, selectedDepartment, selectedPosition, courseId, serializationGroup, emails) {
        let url = `api/v1/user?limit=${limit}&page=${page}&filter[company]=${companyID}`;

         if (searchValue) {
            url += `&filter[search]=${searchValue}`;
         }

         if (isStudents) {
            url += `&filter[role]=${ROLE_ID_STUDENT}`;
         }

         if (Array.isArray(emails) && emails?.length > 0) {
            const emailsString = emails?.map(email => `'${email}'`).join(', '); 
            
            url += `&filter[search_by_emails]=[${emailsString}]`;
         }
   
         if (typeof emails === 'string' && emails?.length > 0) {
            url += `&filter[search]=${emails}`;
         }

         if (courseId) {
            url += `&filter[users_with_access_to_course]=${courseId}`;
         }

         if (isSpecialUsers) {
            url += `&filter[exclude_special_roles]=${0}`;
         }

         if (selectedDepartment) {
            url += `&filter[department]=${selectedDepartment}`;
         }

         if (selectedPosition) {
            url += `&filter[position]=${selectedPosition}`;
         }

         url += `&order=desc`;

         return super.get(url, null, null, serializationGroup);
    }

    getAllUsersOfCompany(page, limit, companyID, searchValue, isStudents, isSpecialUsers, selectedDepartment, selectedPosition, courseId, serializationGroup, emails) {
        let url = `api/v1/user?limit=${limit}&page=${page}&filter[company]=${companyID}`;

         if (searchValue) {
            url += `&filter[search]=${searchValue}`;
         }

         if (isStudents) {
            url += `&filter[role]=${ROLE_ID_STUDENT}`;
         }

         if (courseId) {
            url += `&filter[users_with_access_to_course]=${courseId}`;
         }

         if (Array.isArray(emails) && emails?.length > 0) {
            const emailsString = emails?.map(email => `'${email}'`).join(', '); 
            
            url += `&filter[search_by_emails]=[${emailsString}]`;
         }
   
         if (typeof emails === 'string' && emails?.length > 0) {
            url += `&filter[search]=${emails}`;
         }

         if (isSpecialUsers) {
            url += `&filter[exclude_special_roles]=${0}`;
         }

         if (selectedDepartment) {
            url += `&filter[department]=${selectedDepartment}`;
         }

         if (selectedPosition) {
            url += `&filter[position]=${selectedPosition}`;
         }

         url += `&order=desc`;

         return super.get(url, null, null, serializationGroup);
    }

    getCuratorsOfCourse(companyID, courseID) {
        let url = `api/v1/user?page=${1}&limit=${100}&filter[company]=${companyID}&filter[course_curators]=${courseID}&filter[status]=${USER_STATUS_ACTIVE}`;

        url += `&order=desc`;
    
        return super.get(url);
    }

    changeAdminStatusInCourse(courseId, data) {
      return super.put(`api/v1/course/${courseId}/change-admin-status`, data);
    }

    changeManyAdminAsStudentStatuses(data) {
      return super.put(`api/v1/course/change-many-admin-status`, data);
    }

    getUser(userID, serializationGroup) {
        return super.get(`api/v1/user/${userID}`, null, null, serializationGroup);
    }

    updateUserStatus(userId, data) {
        return super.put(`api/v1/user/${userId}/change-status`, data);
    }

    changeUserRole(userId, branchId, roleId, data) {
        return super.put(`api/v1/user/${userId}/branch/${branchId}/role/${roleId}`, data);
    }
    
    updateUserStatusInCourse(userId, courseId, data) {
        return super.put(`api/v1/user/${userId}/course/${courseId}`, data);
    }

    updateLessonCompletedStatus(userID, lessonID, data) {
      return super.put(`api/v1/user/${userID}/lesson/${lessonID}`, data);
    }
    
    giveAccessToCourses(userID, data) {
      return super.put(`api/v1/user/${userID}/courses`, data);
    }

    updateUserPermissions(userId, data) {
        return super.put(`api/v1/user/${userId}/permissions`, data);
    }

    updateUserProgressOnCourse(userId, courseId, data) {
        return super.put(`api/v1/user/${userId}/course/${courseId}/update-progress`, data);
    }

    getUserProgressOnCourses(data) {
        return super.post(`api/v1/user/get-progress-and-result`, data);
    }

    getUserCourseLocale (data) {
      let url = `api/v1/user/get-locale`;
      return super.post(url, data, null, 'user_course_locale:default')
   }

    changeUserCourseLocale (data, courseId, userId) {
      let url = `api/v1/course/${courseId}/user/${userId}/change-locale`;
      return super.put(url, data)
   }

   changeMassStatuses (data) {
      let url = `api/v1/user/change-multiple-statuses`;
      return super.put(url, data)
   }

   deleteManyUsers (data) {
      let url = `api/v1/user`;
      return super.delete(url, data)
   }
}
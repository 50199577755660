import styles from "./styles.module.scss";
import backIcon from "../../../../assets/images/icon_back.svg";
import menuIcon from "../../../../assets/images/menu/list_icon.svg";
import Button from "../../../../components/Button/Button";
import introIcon from "../../../../assets/images/introduction_icon.svg";
import cn from "classnames";
import ConfirmModal from "../../../../components/Modals/ConfirmModal/ConfirmModal";
import useHandleModal from "../../../../hooks/dom/useHandleModal";
import { useParams } from "react-router-dom";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";
import { isMobileDevice } from "../../../../utils/usersHelper";
import {decodeContentToDisplay} from "../../../../utils/coursesHelper";

const IntroductionBlock = ({
   onActualStart,
   welcomeMsg,
   introMsg,
   isStartNeeded,
   initialTime,
   setIsTestStarted,
}) => {
   const confirmModal = useHandleModal();
   const params = useParams();

   const t = useFindTranslationsByString();

   return (
      <div className={styles.introduction_block}>
         <button onClick={confirmModal.open} className={cn(styles.back_icon, styles.back_icon_intro)}>
            <img src={isMobileDevice() ? menuIcon : backIcon} alt="" />
         </button>

         <ConfirmModal
            confirmButtonText={t("Confirm")}
            onConfirm={() => setIsTestStarted(false)}
            onClose={confirmModal.close}
            isOpen={confirmModal.isActive}
            title={t("Are you sure?")}
            maxHeight={isMobileDevice() ? "270px" : '300px'}
         />

         {welcomeMsg && (
            <div className={cn(styles.block, styles.welcome_block)}>
               <h3>{t("Welcome message")}</h3>
               <p>{welcomeMsg}</p>
            </div>
         )}

         {!welcomeMsg && !introMsg && (
            <div className={cn(styles.block, styles.welcome_block)}>
               <h3>Welcome message</h3>
               <p>{t("Dear student, Welcome to the test. This test is designed to assess your understanding of the course material and your readiness for the upcoming challenges. Before you begin, please take a moment to read the following instructions:")}
               </p>
               <p>
               {t("Test duration: you will have")} {
                  initialTime?.hours && initialTime?.minutes 
                     ? `${initialTime?.hours} ${t("hours")} ${initialTime?.minutes} ${'minutes'}. ${t("Make sure to manage your time wisely to answer all the questions. If you close the test before submitting the results won't be saved")}.` 
                     : `${t("Unlimited time")}`}. 
               </p>
            </div>
         )}

         <div className={cn(styles.block, styles.intro_block, !introMsg && !isStartNeeded ? styles.no_intro : '', styles.start_test)}>
            {introMsg && (
               <>
                  <div className={styles.intro_header}>
                     <img src={introIcon} alt="Introduction icon" />
                     <span>{t("Introduction")}</span>
                  </div>
                   <p className={styles.intro_msg}>
                       <div dangerouslySetInnerHTML={{__html: decodeContentToDisplay(introMsg)}}/>
                   </p>
               </>
            )}

             {isStartNeeded && (
                 <div>
                  {initialTime?.hours || initialTime?.minutes ? (
                     <div className={styles.how_much_time}>
                        <p>{t('Time for test:')}</p>
                        <span>
                           {initialTime?.hours} hours {initialTime?.minutes}{" "}
                           {t('minutes')}
                        </span>
                     </div>
                  ) : null}
                  <div className={styles.start_btn}>
                     <Button title={params?.statisticsUserId ? t("View questions") : t("Start the test")} onClick={onActualStart} />
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default IntroductionBlock;

import React, { useState } from "react";

import styles from "./styles.module.scss";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import Button from "../../../components/Button/Button";

const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];


const IconUploadModal = ({ icon, isFavicon, setRenderedIconFile, setIconFile, onClose, isActive, title, desc, openSecondStepSmallLogo }) => {
   const [drag, setDrag] = useState(false);

   const t = useFindTranslationsByString();

   const fileLoadHandler = (e) => {
      e.preventDefault();
      if(!e.target.files[0]) return;
      if (!allowedTypes.includes(e.target.files[0]?.type)) {
          return toast(<ToastMsg text="Only PNG, JPEG, JPG files are allowed." isError/>);
      }

      if(e.target.files[0]) setIconFile(e.target.files[0]);

      // for show icons straign away
      const reader = new FileReader();

      reader.onload = () => {
         setRenderedIconFile(reader.result);
      };
      
       if(e.target.files[0]) {
         reader.readAsDataURL(e.target.files[0]);
       }
      onNextButton(e.target.files[0])
   };

   const dragStartHandler = (e) => {
      e.preventDefault();
      setDrag(true);
   };

   const dragLeaveHandler = (e) => {
      e.preventDefault();
      setDrag(false);
   };

   const dropHandler = (e) => {
      e.preventDefault();

      const files = [...e.dataTransfer.files];
      if (!allowedTypes.includes(files[0]?.type)) {
         return toast(<ToastMsg text="Only PNG, JPEG, JPG files are allowed." isError/>);
      }
      if(files[0]) setIconFile(files[0])

       // for show icons straign away
       const reader = new FileReader();
      
       reader.onload = () => {
          setRenderedIconFile(reader.result);
       };
        if(files[0]) {
          reader.readAsDataURL(files[0]);
        }
      onNextButton(files[0])
   };

   const onNextButton = (file) => {
      const icon1 = icon || file
      const size = isFavicon ? 500 * 1024 : 1024 * 1024;

      if (icon1 && icon1.size > size) {
         return toast(<ToastMsg text="Icon file size exceeds required size. Please select a smaller file." isError/>);
      }

      if (!icon1) {
         return toast(<ToastMsg text="Add an icon, please" isError/>);
      }

      openSecondStepSmallLogo();
   }

   return (
      <ModalLayout
         isOpen={isActive}
         maxHeight={"343px"}
         maxWidth={"459px"}
         onClose={onClose}
         withCross
         customPadding={"20px"}
         customCrossStyles={{top: "0", right: "0"}}
      >
         <div className={styles.popup_upload_container}>
            <div className={styles.settings_logo_header}>
               <span
                  className={`${styles.settings_box_icon} ${styles.settings_box_icon_favicon}`}
               />
               <div>
                  <p className={styles.settings_logo_title}>{t(title)}</p>
                  <p className={styles.settings_logo_description}>{t(desc)}</p>
               </div>
            </div>
            <div
               onDragStart={dragStartHandler}
               onDragOver={dragStartHandler}
               onDragLeave={dragLeaveHandler}
               onDrop={dropHandler}
               className={styles.upload_image_box}
            >
               <div
                  className={`${styles.drag_drop_image_box}
                              ${drag ? styles.drag : ""}`}
               >
                  <div className={styles.drag_drop_image_text}>
                     <label className={styles.drag_drop_image_button}>
                        <input 
                           accept=".png, .jpeg, .jpg"
                           onChange={fileLoadHandler}
                           type="file"
                        />
                        {t("Choose file")}
                     </label>
                     {t("or Drag & Drop to upload")}
                  </div>
                  <div>{icon?.name}</div>
               </div>
            </div>
            <div className={styles.popup_button_grid}>
               <button
                  onClick={()=> {
                     onClose();
                     setRenderedIconFile();
                  }}
                  type="button"
                  className={styles.button_popup_cancel}
               >
                  {t("Cancel")}
               </button>
               <Button
                disabled={true}
                title={t("Save changes")}
                className={styles.button_popup_save_disabled}
               />
            </div>
         </div>
      </ModalLayout>
   );
};

export default IconUploadModal;

import iconInfo from "../../../../assets/images/icon_info.svg";
import correctImg from "../../../../assets/images/symbols/check.svg";
import iconWrong from "../../../../assets/images/symbols/cross_white.svg";
import commentIcon from "../../../../assets/images/comment_icon.svg";
import iconWrongRed from "../../../../assets/images/close.svg";
import attachFile from "../../../../assets/images/attach_icon.svg";
import partiallyCorrectIcon from "../../../../assets/images/partially_correct_icon.svg";
import correctImgGreen from "../../../../assets/images/correct_green.svg";
import compareImg from "../../../../assets/images/compare_icon.svg";
import reworkIcon from "../../../../assets/images/icon_rework.svg";
import emodjiIcon from "../../../../assets/images/icon_emoji.svg";
import { v4 as uuidv4 } from "uuid";

import CommonTypes from "../AnswersTypes/CommonTypes";
import {
  COMPARE_ANSWER_CODE,
  FILE_UPLOAD_ANSWER_CODE,
  RATE_ANSWER_CODE,
  SEQUENCE_ANSWER_CODE,
  STARS_ANSWER_CODE,
  VIDEO_ANSWER_CODE,
  WRITTEN_ANSWER_CODE,
} from "../../../../utils/questionsHelper";
import {
  TEST_STATUS_APPROVED,
  TEST_STATUS_ON_REWORK,
  TEST_STATUS_REJECTED,
  commonTypes,
  typesThatNeededTips,
} from "../../../../constants/tests";

import CheckQuestionResult from "../CheckQuestionResult/CheckQuestionResult.js.js";
import WrittenAnswer from "../AnswersTypes/WrittenAnswer";
import CheckManualQuestion from "../CheckManualQuestion/CheckManualQuestion";
import FileAnswer from "../AnswersTypes/FileAnswer";
import SequenceAnswers from "../AnswersTypes/SequenceAnswers";
import CompareAnswer from "../AnswersTypes/CompareAnswers";
import RateAnswer from "../AnswersTypes/RateAnswer";
import StarsAnswer from "../AnswersTypes/StarsAnswer";
import TextArea from "../../../../components/TextArea/TextArea";
import CustomStaticTooltip from "../../../../components/CustomStaticTooltip/CustomStaticTooltip";
import EmojiPicker from "emoji-picker-react";

import styles from "./styles.module.scss";
import cn from "classnames";

import Dropzone from "react-dropzone-uploader";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import Loader from "../../../../components/Loader/Loader";
import SecondLoader from "../../../../components/SecondLoader/SecondLoader";
import useOutsideClick from "../../../../hooks/dom/useOutsideClick";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import VideoFromUser from "../AnswersTypes/VideoFromUser.js";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString.js";
import { decodeContentToDisplay } from "../../../../utils/coursesHelper";

const CenterBlock = ({
  lessonContent,
  lessonName,
  isTheory,
  testQuestionsList,
  scrolledBlockRef,
  refs,
  openAnswersInfoModal,
  isEmodjiOpen,
  commentInputValue,
  setCommentInputValue,
  emodjiRef,
  setIsEmodjiOpen,
  emodjiWrapperRef,
  handleEmodji,
  attachCommentFile,
  fileName,
  uploadFileProgress,
  addManualResult,
  activeTestResult,
  handleTestResultClick,
  questionUpdateLoadingId,
  dataLoading,
  testData,
}) => {
  const t = useFindTranslationsByString();

  const navigate = useNavigate();

  const onReturnBack = () => {
    navigate("/knowledge_check");
  };

  useOutsideClick(emodjiWrapperRef, () => setIsEmodjiOpen(false), emodjiRef);

  const renderLessonContent = () => {
    if (lessonContent?.length && isTheory) {
      return (
        <div className={cn(styles.lesson_wrapper, styles.center_block)}>
          <h3 className={styles.lesson_name}>{lessonName}</h3>
          <div dangerouslySetInnerHTML={{ __html: lessonContent }} />
        </div>
      );
    } else if (lessonContent?.length === 0 && isTheory) {
      return (
        <div className={cn(styles.lesson_wrapper, styles.center_block)}></div>
      );
    }
    return null;
  };

  const renderedLesssonContent = renderLessonContent();

  if (dataLoading) {
    return (
      <div className={styles.emptyBlock}>
        <div className="default_loader_wrapper">
          <SecondLoader />
        </div>
      </div>
    );
  }

  return (
    <>
      {renderedLesssonContent}

      {!isTheory && (
        <div ref={scrolledBlockRef} className={styles.center_block}>
          <h3 className={styles.test_title}>{testData?.lesson?.name}</h3>
          {testQuestionsList.map((question, idx) => (
            <div
              ref={refs?.[idx]}
              className={cn(styles.test_question_item, "user_test_block")}
              key={question.id}
            >
              <div className={cn(styles.small_title, styles.question_type)}>
                <span>{idx + 1}</span>
                <img className={styles.left_img} src={question.image} alt="" />
                <span>{t(question.name)}</span>
              </div>
              <div className={styles.question_name}>
                {question.title ? question?.title : question.content}
              </div>
              {question?.comment && (
                <div className={styles.commentFromAdmin}>
                  <CustomStaticTooltip
                    icon={commentIcon}
                    id={uuidv4()}
                    hint={t("Hint that author of the test left")}
                    text={question.comment}
                  />
                </div>
              )}

              {question?.description && (
                  <div className={styles.description}>
                    <div dangerouslySetInnerHTML={{__html: decodeContentToDisplay(question.description)}}/>
                  </div>
              )}

              {question.type === VIDEO_ANSWER_CODE && (
                <FileAnswer
                  fileLink={question?.content}
                  key={question.id}
                  questionId={question?.id}
                  isVideo
                  courseId={testData?.lesson?.module?.course?.id}
                />
              )}

              {question?.type === SEQUENCE_ANSWER_CODE && (
                <div className={styles.sequence_right_answers_wrapper}>
                  <div className={styles.small_title}>
                    <span>{t("Right pairs")}</span>
                  </div>
                  <div className={styles.sequence_right_answers}>
                    {question?.sequence?.map((answer, answerIdx) => (
                      <div
                        key={answerIdx}
                        className={styles.sequence_right_answer}
                      >
                        {answerIdx + 1} <span> {answer} </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {question?.type === COMPARE_ANSWER_CODE && (
                <div className={styles.sequence_right_answers_wrapper}>
                  <div className={styles.small_title}>
                    <span>{t("Right pairs")}</span>
                  </div>
                  <div className={styles.sequence_right_answers}>
                    {question?.options?.map((option, optionIdx) => (
                      <div
                        key={optionIdx}
                        className={styles.compare_right_wrapper}
                      >
                        <div className={styles.compare_left_side}>
                          {" "}
                          {option}{" "}
                        </div>

                        <div className={styles.compare_right_side}>
                          <img src={compareImg} alt="" />
                          {
                            question?.answers?.find(
                              (answer) => answer.matchingOption === optionIdx,
                            )?.initialContent
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {typesThatNeededTips.includes(question?.type) && (
                <div className={styles.small_title}>
                  <span>{t("Answers")}</span>
                  <img onClick={openAnswersInfoModal} src={iconInfo} alt="" />
                </div>
              )}

              {!typesThatNeededTips.includes(question?.type) && (
                <div className={styles.small_title}>
                  <span>{t("Answers")}</span>
                </div>
              )}

              {commonTypes.includes(question?.type) && (
                <div className={styles.answers_block}>
                  {question?.answers?.map((answer, answerIdx) => (
                    <CommonTypes
                      key={answerIdx}
                      answer={answer}
                      answerIdx={answerIdx}
                      correctImg={correctImg}
                      iconWrong={iconWrong}
                      correctImgGreen={correctImgGreen}
                      iconWrongRed={iconWrongRed}
                    />
                  ))}

                  <CheckQuestionResult
                    key={idx}
                    question={question}
                    correctImg={correctImg}
                    iconWrong={iconWrong}
                    partiallyCorrectIcon={partiallyCorrectIcon}
                  />
                </div>
              )}

              {question.type === WRITTEN_ANSWER_CODE && (
                <div className={styles.answers_block}>
                  {question?.answers?.map((answer, answerIdx) => (
                    <WrittenAnswer
                      key={answerIdx}
                      answer={answer}
                      answerIdx={answerIdx}
                      correctImg={correctImg}
                      iconWrong={iconWrong}
                      correctImgGreen={correctImgGreen}
                      iconWrongRed={iconWrongRed}
                    />
                  ))}

                  <CheckManualQuestion
                    key={"written answer"}
                    correctImgGreen={correctImgGreen}
                    partiallyCorrectIcon={partiallyCorrectIcon}
                    iconWrongRed={iconWrongRed}
                    addManualResult={addManualResult}
                    question={question}
                  />
                  {questionUpdateLoadingId === question?.id && (
                    <div className={styles.loader}>
                      <Loader size={"small"} key={question?.id} />
                    </div>
                  )}
                </div>
              )}

              {question.type === FILE_UPLOAD_ANSWER_CODE && (
                <div className={styles.answers_block}>
                  <FileAnswer
                    key={question?.id}
                    fileLink={question?.answers?.[0]?.content}
                    questionId={question?.id}
                    courseId={testData?.lesson?.module?.course?.id}
                  />

                  <CheckManualQuestion
                    key={"file_upload"}
                    correctImgGreen={correctImgGreen}
                    partiallyCorrectIcon={partiallyCorrectIcon}
                    iconWrongRed={iconWrongRed}
                    addManualResult={addManualResult}
                    question={question}
                  />
                  {questionUpdateLoadingId === question?.id && (
                    <div className={styles.loader}>
                      <Loader size={"small"} key={question?.id} />
                    </div>
                  )}
                </div>
              )}

              {question.type === VIDEO_ANSWER_CODE && (
                <div className={styles.answers_block}>
                  <div className={styles.video_wrapper}>
                    <VideoFromUser
                      question={question}
                      courseId={testData?.lesson?.module?.course?.id}
                    />
                  </div>

                  <CheckManualQuestion
                    key={"video_answer"}
                    correctImgGreen={correctImgGreen}
                    partiallyCorrectIcon={partiallyCorrectIcon}
                    iconWrongRed={iconWrongRed}
                    addManualResult={addManualResult}
                    question={question}
                  />
                  {questionUpdateLoadingId === question?.id && (
                    <div className={styles.loader}>
                      <Loader size={"small"} key={question?.id} />
                    </div>
                  )}
                </div>
              )}

              {question.type === SEQUENCE_ANSWER_CODE && (
                <div className={styles.answers_block}>
                  {question?.sequenceAnswers?.map((answer, answerIdx) => (
                    <SequenceAnswers
                      key={answerIdx}
                      answer={answer}
                      answerIdx={answerIdx}
                      correctImg={correctImg}
                      iconWrong={iconWrong}
                      correctImgGreen={correctImgGreen}
                      iconWrongRed={iconWrongRed}
                    />
                  ))}

                  <CheckQuestionResult
                    key={idx}
                    question={question}
                    correctImg={correctImg}
                    iconWrong={iconWrong}
                    partiallyCorrectIcon={partiallyCorrectIcon}
                  />
                </div>
              )}

              {question.type === COMPARE_ANSWER_CODE && (
                <div className={styles.answers_block}>
                  {question?.answers?.map((answer, answerIdx) => (
                    <CompareAnswer
                      key={answerIdx}
                      answer={answer}
                      question={question}
                      answerIdx={answerIdx}
                      compareImg={compareImg}
                      correctImg={correctImg}
                      iconWrong={iconWrong}
                      correctImgGreen={correctImgGreen}
                      iconWrongRed={iconWrongRed}
                    />
                  ))}

                  <CheckQuestionResult
                    key={question.id}
                    question={question}
                    correctImg={correctImg}
                    iconWrong={iconWrong}
                    partiallyCorrectIcon={partiallyCorrectIcon}
                  />
                </div>
              )}

              {question.type === RATE_ANSWER_CODE && (
                <div className={styles.answers_block}>
                  <RateAnswer key={question.id} question={question} />
                  <CheckQuestionResult
                    key={idx}
                    question={question}
                    correctImg={correctImg}
                    iconWrong={iconWrong}
                    partiallyCorrectIcon={partiallyCorrectIcon}
                  />
                </div>
              )}

              {question.type === STARS_ANSWER_CODE && (
                <div className={styles.answers_block}>
                  <StarsAnswer
                    key={question.id}
                    question={question}
                    total={question?.rating}
                  />
                  <CheckQuestionResult
                    key={idx}
                    question={question}
                    correctImg={correctImg}
                    iconWrong={iconWrong}
                    partiallyCorrectIcon={partiallyCorrectIcon}
                  />
                </div>
              )}
            </div>
          ))}

          <div className={cn(styles.complete_test_block)}>
            <div className={styles.complete_title}>{t("Final result")}</div>

            <div className={styles.upload_comment_info}>
              <div className={styles.comment_left}>
                <div>
                  <CustomTooltip id={fileName} text={fileName} limit={20} />
                </div>
                <div>{fileName && uploadFileProgress}</div>
              </div>

              {fileName && (
                <div className={styles.line_full}>
                  <div
                    className={styles.upload_comment_line}
                    style={{
                      width: uploadFileProgress ? uploadFileProgress : "0%",
                    }}
                  ></div>
                </div>
              )}
            </div>

            <div className={styles.comment_block}>
              <div className={styles.dropzoneLabel}>
                <img src={attachFile} alt="Attach icon" />
                <Dropzone
                  onChangeStatus={attachCommentFile}
                  maxFiles={1}
                  multiple={false}
                  canCancel={true}
                  inputContent={t("Upload file")}
                />
              </div>

              <TextArea
                height={96}
                withEmodji
                isResize
                minRows={4}
                classNameResize={styles.text_area}
                maxRows={30}
                placeholder={t("Enter a comment")}
                value={commentInputValue}
                onChangeValue={(e) => setCommentInputValue(e.target.value)}
              />
              <img 
                  alt="emodjiIcon"
                  ref={emodjiRef}
                  onClick={() => setIsEmodjiOpen(!isEmodjiOpen)}
                  src={emodjiIcon}
                  className={styles.emodjiIcon}
              />

              {isEmodjiOpen && (
                <div ref={emodjiWrapperRef} className={styles.emodji_wrapper}>
                  <EmojiPicker
                    previewPosition="none"
                    searchDisabled
                    theme="dark"
                    onEmojiClick={handleEmodji}
                    width={320}
                    height={400}
                    previewConfig={{ showPreview: false }}
                  />
                </div>
              )}
            </div>

            <div>{t("Final result of the test")}:</div>
            <div className={styles.complete_btns}>
              <button
                onClick={() => handleTestResultClick(TEST_STATUS_APPROVED)}
                className={cn(styles.button_true, {
                  [styles.activePass]:
                    activeTestResult === TEST_STATUS_APPROVED,
                })}
              >
                <img src={correctImgGreen} alt="" />
                <span>{t("Pass")}</span>
              </button>
              <button
                className={cn(styles.button_half_right, {
                  [styles.activeRework]:
                    activeTestResult === TEST_STATUS_ON_REWORK,
                })}
                onClick={() => handleTestResultClick(TEST_STATUS_ON_REWORK)}
              >
                <img src={reworkIcon} alt="" />
                <span>{t("On rework")}</span>
              </button>
              <button
                onClick={() => handleTestResultClick(TEST_STATUS_REJECTED)}
                className={cn(styles.button_false, {
                  [styles.activeFailed]:
                    activeTestResult === TEST_STATUS_REJECTED,
                })}
              >
                <img src={iconWrongRed} alt="" />
                <span>{t("Failed")}</span>
              </button>
            </div>

            {activeTestResult && (
              <Button
                className={styles.go_back_button}
                isBlack
                title={t("Go back")}
                onClick={onReturnBack}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CenterBlock;

import DOMPurify from 'dompurify';
import 'lite-youtube-embed/src/lite-yt-embed.css';
import LessonApi from './api/LessonApi';
import courseImg from "../assets/images/course_thumbnail.png";

import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { COURSE_STATUS_DELETED, COURSE_STATUS_HIDEN } from '../constants/courses';
import { TEST_STATUS_APPROVED, TEST_STATUS_ON_CHECK, TEST_STATUS_ON_REWORK, TEST_STATUS_REJECTED, statusMap } from '../constants/tests';
import moment from 'moment';
import UserApi from './api/UserApi';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const filterActiveCourses = (course) => {
   return course?.status !== COURSE_STATUS_DELETED && course?.status !== COURSE_STATUS_HIDEN
}

export function formatFileSize(fileSize) {
   if (fileSize >= 1024 * 1024 * 1024) {
       // Convert to GB
       return `${(fileSize / (1024 * 1024 * 1024)).toFixed(2)} GB`;
   } else if (fileSize >= 1024 * 1024) {
       // Convert to MB
       return `${(fileSize / (1024 * 1024)).toFixed(2)} MB`;
   } else {
       // Remain in KB
       return `${Math.round(fileSize / 1024) || '0'} KB`;
   }
}

const extractVideoId = (url) => {
  const youtubeUrlPattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/embed\/|youtu\.be\/)([a-zA-Z0-9_-]+)/;
  const match = url.match(youtubeUrlPattern);
  return match ? match[1] : null;
}

export const replacePastedInEditorLinks = (pastedContent, editorRef) => {
   const content = pastedContent?.replace('?usp=sharing', '');

   const driveLinkRegex = /https:\/\/drive\.google\.com\/file\/d\/([^/]+)\/(view|sharing)/g;
   const youtubeLinkRegex = /https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([^&\s]+)/g;

   // Replace Google Drive links with iframes
   const replacedTextDrive = content?.replace(driveLinkRegex, function(match, fileId) {
       return '<iframe src="https://drive.google.com/file/d/' + fileId + '/preview"></iframe>';
   });

   // Replace YouTube links with iframes
   const replacedTextYoutube = replacedTextDrive?.replace(youtubeLinkRegex, function(match, videoId) {
       return '<iframe allowfullscreen src="https://www.youtube.com/embed/' + videoId + '"></iframe>';
   });

   editorRef?.current.execCommand('mceInsertContent', false, replacedTextYoutube);
}

export const replaceIframesWithLiteYouTube = (content) => {
   const decodedContent = content?.replace(/<a/g, '<a target="_blank"');
   const tempDiv = document.createElement('div');
   tempDiv.innerHTML = decodedContent;
 
   // Select all existing iframes in the content
   const iframes = tempDiv.querySelectorAll('iframe');
 
   // Helper function to extract YouTube video ID
   const extractVideoId = (url) => {
     const regExp = /(?:youtube\.com\/.*v=|youtu\.be\/)([^"&?/ ]{11})/;
     const match = url.match(regExp);
     return match ? match[1] : null;
   };
 
   // Loop through each iframe and replace it with Lite YouTube embed
   iframes.forEach(iframe => {
     const youtubeUrl = iframe.getAttribute('src');
     if (youtubeUrl && (youtubeUrl.includes('youtube.com') || youtubeUrl.includes('youtu.be'))) {
       const videoId = extractVideoId(youtubeUrl);
       if (videoId) {
         const liteYoutubeHtml = `<lite-youtube videoid="${videoId}"></lite-youtube>`;
         iframe.outerHTML = liteYoutubeHtml;
       }
     }
   });
 
   return tempDiv.innerHTML;
 };
 

export const INITIAL_TEXT = 'Initial content';

export const addAllowFullScreenToIframes = (content) => {
   const updatedContent = content?.replace(/<iframe/g, '<iframe');
   return updatedContent;
 }

export const encodeSanitizedContent = (content) => {
   const sanitizedContent = DOMPurify.sanitize(content, { ADD_TAGS: ['iframe'], ADD_ATTR: ['src'] });
   const encodedContent = btoa(unescape(encodeURIComponent(content)));
   return encodedContent;
 };

// Decode sanitized content
export const decodeSanitizedContent = (content) => {
   if (content !== INITIAL_TEXT) {
     try {
       const decoded = decodeURIComponent(escape(atob(content)));
       const result = addAllowFullScreenToIframes(decoded);
       return result;
     } catch (error) {
       return content; // Return the original content on error
     }
   } else {
     return content;
   }
 };

 export const encodeSanitizedInputValue = (content) => {
   if(!content?.length) return '';

   const encodedContent = btoa(unescape(encodeURIComponent(content)));
   const prefixedEncodedContent = "encoded:" + encodedContent;
   return prefixedEncodedContent;
};

export const decodeSanitizedInputValue = (encodedContent) => {
   // Check if the content starts with the "encoded:" prefix
   if (encodedContent && encodedContent.startsWith("encoded:")) {
       try {
           // Remove the prefix before decoding
           const contentWithoutPrefix = encodedContent.slice(8);
           const decodedContent = decodeURIComponent(escape(atob(contentWithoutPrefix)));
           return decodedContent;
       } catch (error) {
           // Handle decoding error, e.g., return an error message or throw an exception
           console.error('Error decoding content:', error);
           return null;
       }
   } else {
       // Content is not encoded, return it as is
       return encodedContent;
   }
};

export const sortBySequence = (a, b) => {
   // In case we create new antity we waned to put it in the last position
   if (a.sequence === null && b.sequence !== null) {
     return 1;
   } else if (a.sequence !== null && b.sequence === null) {
     return -1;
   } else {
     return a.sequence - b.sequence;
   }
 };

export const sortByDisplaySequence = (a, b) => {
   if (a.displaySequence === null && b.displaySequence !== null) {
     return 1;
   } else if (a.displaySequence !== null && b.displaySequence === null) {
     return -1;
   } else {
     return a.displaySequence - b.displaySequence;
   }
 };

 export function getRandomLetter() {
   const alphabet = 'abcdefghijklmnopqrstuvwxyz';
   let randomLetters = '';

   for (let i = 0; i < 2; i++) {
     const randomIndex = Math.floor(Math.random() * alphabet.length);
     randomLetters += alphabet[randomIndex];
   }

   return randomLetters;
 }

 export const renderPDF = async (amazonFileUrl, editorRef, setIsUpdateLoading, activeElement, file) => {

   setIsUpdateLoading(true);

     const dataToGetUrl = [{
       lesson_id: activeElement?.id,
     }];

     const res = await new LessonApi().getPresignedUploadUrls(dataToGetUrl);

     const uploadUrl = res?.success?.data?.[0]?.success?.data?.upload_signed_url;
     const amazonImgUrl = res?.success?.data?.[0]?.success?.data?.file_signed_url;

   setIsUpdateLoading(false);

   let pdfHTML = `
   <a href="${amazonFileUrl}" target="_blank" style="color: white;">Link to PDF</a>
 `;

   editorRef.current.execCommand('mceInsertContent', false, pdfHTML);
 };
//  export const renderPDF = async (amazonFileUrl, editorRef, setIsUpdateLoading, activeElement) => {
//    const pdf = await pdfjsLib.getDocument({ url: amazonFileUrl }).promise;
//    const numPages = pdf.numPages;

//    setIsUpdateLoading(true);

//    let sliderHtml = `
//      <a href="${amazonFileUrl}" target="_blank" style="color: white;">Link to PDF</a>
//      <div class="pdf_slider">
//        <div class="slider-container" style="display: flex; overflow-x: scroll;">
//    `;

//    const uploadPromises = [];

//    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
//      const page = await pdf.getPage(pageNum);

//      // Convert the PDF page to an image
//      const scale = 1.5;
//      const viewport = page.getViewport({ scale });
//      const canvas = document.createElement('canvas');
//      const context = canvas.getContext('2d');
//      canvas.height = viewport.height;
//      canvas.width = viewport.width;

//      const renderContext = {
//        canvasContext: context,
//        viewport: viewport,
//      };

//      await page.render(renderContext).promise;

//      const imageBlob = await new Promise((resolve) => {
//        canvas.toBlob(resolve, 'image/jpeg');
//      });

//      const dataToGetUrl = [{
//        lesson_id: activeElement?.id,
//        filename: `${Date.now()}_${pageNum}.jpeg`
//      }];

//      const res = await new LessonApi().getPresignedUploadUrls(dataToGetUrl);

//      const uploadUrl = res?.success?.data?.[0]?.success?.data?.upload_signed_url;

//      // Queue the upload promise
//      uploadPromises.push(
//        axios.put(uploadUrl, imageBlob, {
//          headers: {
//            'Content-Type': imageBlob.type,
//          },
//        })
//      );

//      const amazonImgUrl = res?.success?.data?.[0]?.success?.data?.file_signed_url;

//      sliderHtml += `
//        <div class="slide" style="flex: 0 0 auto; width: 450px; height: auto; margin-right: 10px;">
//          <img src="${amazonImgUrl}" style="max-width: 100%; height: auto;" />
//        </div>
//      `;
//    }

//    // Wait for all uploads to finish
//    await Promise.all(uploadPromises);

//    sliderHtml += `
//        </div>
//      </div>
//      <div> </div>
//    `;

//    setIsUpdateLoading(false);

//    editorRef.current.execCommand('mceInsertContent', false, sliderHtml);
//  };

 export const parseIsoDuration = (isoDuration) => {
   const durationMatch = /P\d+Y\d+M\d+DT(\d+)H(\d+)M\d+S/.exec(isoDuration);

   if (!durationMatch) {
     console.error("Invalid ISO 8601 duration format");
     return { hours: 0, minutes: 0 };
   }

   const hours = parseInt(durationMatch[1], 10);
   const minutes = parseInt(durationMatch[2], 10);

   return {
     hours,
     minutes,
   };
 };

export const parseIsoDurationStr = (isoDuration) => {
  const durationMatch = /P(\d+Y)?(\d+M)?(\d+DT)?(\d+H)?(\d+M)?(\d+S)?/.exec(isoDuration);

  if (!durationMatch) {
    return 'no time';
  }

  const years = parseInt(durationMatch[1] || '0', 10);
  const months = parseInt(durationMatch[2] || '0', 10);
  const days = parseInt(durationMatch[3] || '0', 10);
  const hours = parseInt(durationMatch[4] || '0', 10);
  const minutes = parseInt(durationMatch[5] || '0', 10);
  const seconds = parseInt(durationMatch[6] || '0', 10);

  return `${hours ? hours + ' h': ''} ${minutes ? minutes + ' min': ''} ${seconds ? seconds + ' sec' : ''}`;
};


export const DELAY_ON_INPUTS_CHANGE = 350;

export const filterPass = (test) => test?.status === TEST_STATUS_APPROVED;
export const filterFailed = (test) => test?.status === TEST_STATUS_REJECTED;
export const filterRework = (test) => test?.status === TEST_STATUS_ON_REWORK;

export const filterOnCheck = (test) => test?.status === TEST_STATUS_ON_CHECK
export const filterOther = (test) => test?.status === TEST_STATUS_APPROVED || test?.status === TEST_STATUS_ON_REWORK || test?.status === TEST_STATUS_REJECTED

export const filterFunctions = {
   All: filterOther,
   Pass: filterPass,
   Failed: filterFailed,
   'On rework': filterRework,
 };

export const getGridStylesFirstTemplateType = (type) => {
   const gridStylesMap = {
      "first_type_1": {
         gridTemplateAreas: `
            "logoBlock certificateBlock"
            "courseBlock studentsBlock"
            "resultsBlock resultsBlock"
         `,
      },
      "first_type_2": {
         gridTemplateAreas: `
            "certificateBlock logoBlock"
            "_ studentsBlock"
            "_ courseBlock"
            "_ resultsBlock"
         `,
      },
      "first_type_3": {
         gridGap: "30px",
         gridTemplateAreas: `
            "certificateBlock logoBlock"
            "_ studentsBlock"
            "_ courseBlock"
            "_ resultsBlock"
         `,
      },
      "first_type_4": {
         gridTemplateAreas: `
            "logoBlock _"
            "certificateBlock studentsBlock"
            "resultsBlock courseBlock"
         `,
      },
      "first_type_5": {
         gridTemplateAreas: `
            "logoBlock none"
            "certificateBlock studentsBlock"
            "_ courseBlock"
            "_ resultsBlock"
         `,
      },
      "first_type_6": {
         gridTemplateAreas: `
            "certificateBlock logoBlock"
            "studentsBlock courseBlock"
            "_ resultsBlock"
         `,
      },
      "first_type_7": {
         gridTemplateRows: '2rem',
         gridTemplateAreas: `
            "logoBlock certificateBlock"
            "studentsBlock _"
            "courseBlock _"
            "resultsBlock _"
         `,
      },
      "first_type_8": {
         gridTemplateAreas: `
            "logoBlock certificateBlock"
            "studentsBlock _"
            "courseBlock _"
            "resultsBlock _"
         `,
      },
      "first_type_9": {
         gridTemplateAreas: `
            "_ logoBlock"
            "studentsBlock certificateBlock"
            "courseBlock resultsBlock"
         `,
      },
      "first_type_10": {
         gridTemplateAreas: `
            "none logoBlock"
            "studentsBlock certificateBlock"
            "courseBlock _"
            "resultsBlock _"
         `,
      },
   };

   return gridStylesMap[type] || {
      gridTemplateAreas: `
         "logoBlock certificateBlock"
         "courseBlock studentsBlock"
         "resultsBlock resultsBlock"
      `,
   };
};

export const getGridStylesSecondTemplateType = (type, size) => {
   const gridStylesMap = {
      "second_type_1": {
         gridTemplateAreas: `
            "logoBlock mone"
            "certificateBlock studentsBlock"
            "_ courseAndResultsBlock"
         `,

      },
      "second_type_2": {
         gridTemplateAreas: `
            "logoBlock mone"
            "certificateBlock studentsBlock"
            "courseAndResultsBlock _"
         `,
      },
      "second_type_3": {
         gridTemplateAreas: `
            "logoBlock mone"
            "certificateBlock courseAndResultsBlock"
            "studentsBlock _"
         `,
         gridTemplateRows: '0.7rem'
      },
      "second_type_4": {
         gridTemplateAreas: `
         "logoBlock certificateBlock"
         "courseAndResultsBlock nome"
         "_ studentsBlock"
      `,
      gridTemplateRows: '1rem'
      },
      "second_type_5": {
         gridTemplateAreas: `
         "logoBlock nome"
         "certificateBlock studentsBlock"
         "_ courseAndResultsBlock"
      `,
      },
      "second_type_6": {
         gridTemplateAreas: `
            "logoBlock nome"
            "certificateBlock _"
            "studentsBlock courseAndResultsBlock"
         `,
         gridTemplateRows: '0.5rem'
      },
      "second_type_7": {
         gridTemplateAreas: `
            "nome logoBlock"
            "_ certificateBlock"
            "courseAndResultsBlock studentsBlock"
         `,
         gridTemplateRows: '0.5rem'
      },
      "second_type_8": {
         gridTemplateAreas: `
            "logoBlock nome"
            "certificateBlock studentsBlock"
            "courseAndResultsBlock _"
         `,
         gridTemplateRows: '1rem'
      },
      "second_type_9": {
         gridTemplateAreas: `
            "logoBlock nome"
            "studentsBlock courseAndResultsBlock"
            "_ certificateBlock"
         `,
         gridTemplateRows: '0.8rem'
      },
   }

   return gridStylesMap[type] || {
      gridTemplateAreas: `
      "logoBlock mone"
      "certificateBlock studentsBlock"
      "_ courseAndResultsBlock"
   `,
      gridTemplateRows: '0.5rem'
   };
};


export const transoformedResult = (result, language) => {
   const translations = {
     en: {
       excellent: 'Excellent',
       good: 'Good',
       failed: 'Failed',
       unknown: 'Unknown Language',
     },
     ru: {
       excellent: 'Отлично',
       good: 'Хорошо',
       failed: 'Неудача',
       unknown: 'Неизвестный язык',
     },
     uk: {
       excellent: 'Відмінно',
       good: 'Добре',
       failed: 'Не вдалося',
       unknown: 'Невідома мова',
     },
   };

   const resultCategory =
     result > 75 ? 'excellent' : result >= 30 ? 'good' : 'failed';

   return translations[language] ? translations[language][resultCategory] : translations.en.unknown;
 };

 export const monthTranslations = {
   en: [
     'January', 'February', 'March', 'April', 'May', 'June',
     'July', 'August', 'September', 'October', 'November', 'December',
   ],
   ru: [
     'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
     'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
   ],
   uk: [
     'Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень',
     'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень',
   ],
 };

 export const transformCertificateDate = (date, language) => {
   moment.locale(language);

   const monthIndex = moment(date).month();
   const translatedMonth = monthTranslations[language]
     ? monthTranslations[language][monthIndex]
     : monthTranslations.en[monthIndex];

   return moment(date).format(`MMMM DD, YYYY`).replace(moment(date).format('MMMM'), translatedMonth);
 };

 export const returnTemporaryCourseLink = async(userId, course) => {
   const res = await new UserApi().replaceS3Links([{
      user_id: userId,
      filename: course?.image,
      course_id: course?.id
   }]);

   if(res?.success?.data) {
      return res?.success?.data?.map(item => ({
         fileUrl: item?.success?.data?.file_signed_url,
         fileName: item?.success?.data?.filename
      }));
   }
 }

 export const returnTemporaryCoursesLinks = async(userId, courses) => {
   if(!courses?.length) return [];

   const res = await new UserApi().replaceS3Links(courses?.map(course => ({
      user_id: userId,
      filename: course?.image,
      course_id: course?.id
   })));

   if(res?.success?.data) {
      return res?.success?.data?.map(item => ({
         fileUrl: item?.success?.data?.file_signed_url,
         fileName: item?.success?.data?.filename
      }));
   }
 }

 export const mapCoursesWithNewLinks = (course, links) => {
   const matchingLink = links?.find(link => link?.fileName === course?.image);

   if (matchingLink) {
      return {
         ...course,
         image: matchingLink.fileUrl || courseImg
      };
   } else {
      return course;
   }
}

export const returnUpdatedContent = async (content, userId, courseId, isDisableDownload) => {
   const urlLessonRegex = /\/lessons\/([^\/]+\.\w+)/g;

   if(!content) return;

   const fileNamesArray = [...content?.matchAll(urlLessonRegex)]
         .map(match => match[1]);

   if(!fileNamesArray?.length) return content;

   const data = fileNamesArray?.map(name => ({
      user_id: userId,
      filename: name,
      course_id: courseId
   }));

   const res = await new UserApi().replaceS3Links(data);

   if(res?.success?.data) {
      const links = res?.success?.data?.map(item => ({
            fileUrl: item?.success?.data?.file_signed_url,
            fileName: item?.success?.data?.filename
         }));

      let changedContent = content;

      links.forEach(linkObj => {
         const fileNameEscaped = linkObj?.fileName?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
         const regex = new RegExp(`"https?://[^/]+/${process.env.REACT_APP_S3_CURRENT_FOLDER_NAME}/files/lessons/${fileNameEscaped}[^"]*"`, 'g');

         changedContent = changedContent?.replace(regex, `"${linkObj.fileUrl}"`);
     });

     if (isDisableDownload) {
        changedContent = changedContent.replace(/<video/g, '<video controlsList="nodownload"');
      }

      return changedContent;
 } else {
   return content;
 }
}

export const testsMap = ((test) => ({
   ...test,
    team: test?.user?.teams?.map(team => team.name).join(', ') || '-',
    student: test?.user?.firstName || test?.user?.lastName ? `${test?.user?.firstName || ''} ${test?.user?.lastName || ''}` : 'no name',
    course_name: test?.lesson?.module?.course?.name || '',
    test_name: test?.lesson?.name || '',
    // attempt: test?.passAttemptsCounters?.length  || 1,
    end_date: test?.completedAt? moment(test?.completedAt).format("MMMM DD, YYYY h:mm a") : "-",
    pass_time: parseIsoDurationStr(test?.timeSpent || '')  || '',
    result: statusMap[test?.status],
    score: `${test?.successPercentage}%`
}))

export const removeDataSheetsValue = (html) => {
    return html.replace(/data-sheets-value="[^"]*"/g, '');
};

 export const prepearContentToSend = (editorContent) => {
     if(!editorContent) return "";
     const content = removeDataSheetsValue(editorContent);
     return encodeSanitizedContent(content);
}

export const decodeContentToDisplay = (content) => {
    const decodedContent = decodeSanitizedContent(content);
    return addAllowFullScreenToIframes(decodedContent);
}


import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import AgencyApi from "../../../utils/api/AgencyApi";
import { useParams } from "react-router-dom";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";

const LogoBlock = ({
   blockRef,
   faviconUploadModal,
   favicon,
   setFavicon,
   setAgencyFavicon,
   t
}) => {
   const [currentFavicon, setCurrentFavicon] = useState(null);

   const params = useParams();

   const getFavicon = async () => {
      new AgencyApi().getAgencyIconLogo(params.id).then((res) => {
         if (
            res?.success &&
            res?.success?.data[0] !== "No company icon logo found."
         ) {
            setCurrentFavicon(res?.success?.data[0]);
         }
      });
   };

   const deleteFavicon = async () => {
      if(params.id !== ":id") {
         new AgencyApi().deleteAgencyIconLogo(params.id).then((res) => {
            if (res?.success) {
               setFavicon(null);
               setCurrentFavicon(null);
               setAgencyFavicon(null);
               return toast(<ToastMsg text={res?.success?.message}/>)
            }
            if (res?.error) {
               return toast(<ToastMsg text={res?.error?.message} isError />)
            }
         });
      } else {
         setFavicon(null);
         setCurrentFavicon(null);
         setAgencyFavicon(null)
      }
   }; 

   const openUploadModal = () => {
      faviconUploadModal.open();
   }


   useEffect(() => {
      if(params.id !== ':id') {
         getFavicon();
      }
   }, []);


   return (
      <div className={styles.settings_box} ref={blockRef}>
         <p className={styles.setting_box_name}>{t?.uploadLogotypes}</p>
         <div className={styles.settings_favicon}>
            <div className={styles.logo_small_wrapper}>
               {favicon ? (
                  <img
                     alt="Logotype"
                     src={favicon}
                     width="64"
                     height="64"
                  />
               ) : (
                  currentFavicon && 
                     <img
                         src={currentFavicon}
                         width="64"
                         height="64"
                         alt="Logotype"
                     />
               )}
            </div>
            <div className={styles.logo_small_body}>
               <div className={styles.settings_logo_header}>
                  <span
                     className={`${styles.settings_box_icon} ${styles.settings_box_icon_favicon}`}
                  />
                  <div className={styles.settings_logo_header_text}>
                     <p className={styles.settings_logo_title}>
                        {t?.returnTranslation('Upload Icon logotype')}
                     </p>
                     <p className={styles.settings_logo_description}>
                        {`${t?.recommended} 500 KB & 400x400px (PNG ${t?.or} JPG)`} 
                     </p>
                  </div>
               </div>
               <div className={styles.logo_small_buttons}>
                  <button   
                     onClick={openUploadModal}
                     type="button"
                     className={styles.button_upload_image}
                  >
                     {t?.uploadNewImage}
                  </button>
                  <button
                     onClick={deleteFavicon}
                     type="button"
                     className={styles.button_delete_image}
                  >
                     {t?.deleteImage}
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
};

export default LogoBlock;

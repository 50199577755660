import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import searchIcon from "../../../assets/images/symbols/Search.svg";
import avatarPlaceholder from "../../../assets/images/avatar.svg";
import notFound from "../../../assets/images/symbols/nothing_found.svg";
import arrow from "../../../assets/images/symbols/arrow.svg";
import filtersImg from "../../../assets/images/symbols/filters.svg";
import Button from "../../Button/Button";
import Checkbox from "../../Checkbox/Checkbox";
import Dropdown from "../../Dropdown/Dropdown";
import StepIndicator from "../../StepIndicator/StepIndicator";
import { toast } from 'react-toastify';
import ToastMsg from '../../ToastMsg/ToastMsg';
import {
   ACCESS_TABS,
   STUDENTS_TAB,
   TEAM_TAB,
} from "../../../constants/courses";

import cn from "classnames";
import { transformRoleText } from "../../../utils/rolesHelper";
import { DEFAULT_ROLES, ROLE_ID_GUEST, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from "../../../constants/user";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import ChooseAdminAsStudentModal from "../ChooseAdminAsStudentModal/ChooseAdminAsStudentModal";
import TabButton from "../../TabButton/TabButton";
import Roles from "../../../utils/api/Roles";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import useGetDepartments from "../../../hooks/api/departmentsAndPostitions/useGetDepartments";
import useGetPositions from "../../../hooks/api/departmentsAndPostitions/useGetPositions";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import PaginatedDropdown from "../../PaginatedDropdown/PaginatedDropdown";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";


const CompanyUsersAndTeamsModal = ({
   isOpen,
   onClose,
   onConfirm,
   setSelectedUsers,
   selectedUsers,
   usersList,
   setSelectedTeamList,
   selectedTeamList,
   teamManager,
   changeRoleInCompany,
   setExcludedUsersSearch,
   excludedUsersSearch,
   lastExcludedUsersRef,
   companyId,
   companyName,
   selectedPosition,
   setSelectedPosition,
   selectedDepartment,
   setSelectedDepartment,
   setSelectedCompany,
   selectedCompany,
   allCompaniesApartFromCurrent,
   lastAgencyRef,
   lastTeamRef,
   onApplyFilter,
}) => {
   const [search, setSearch] = useState("");
   const [usersSearch, setUsersSearch] = useState("");

   const [stepSecondRole, setStepSecondRole] = useState('');
   const [stepSecondDepartment, setStepSecondDepartment] = useState('');
   const [clickedId, setClickedId] = useState(null);
   const [step, setStep] = useState(1);
   const [selectedMode, setSelectedMode] = useState(STUDENTS_TAB);

   const [isShowFilters, setIsShowFilters] = useState(false);
   const filtersOptionRef = useRef(null);
   const dropDownFiltersRef = useRef(null);

   const t = useFindTranslationsByString();

   const closeFiltersBlock = () => setIsShowFilters(false);
   const toggleFiltersBlock = (e) => setIsShowFilters((prev) => !prev);

   useOutsideClick(dropDownFiltersRef, closeFiltersBlock, filtersOptionRef);
   
   // roles dropdown
   const [rolesDropdownId, setRolesDropdownId] = useState('');

   const onCloseRoleDropdown = () => setRolesDropdownId('');
   const onSetRolesDropdown = (id) => setRolesDropdownId(id);

   const confirmModal = useHandleModal();

   const [isSelectAllUsers, setIsSelectUsersAll] = useState(false);
   const [isSelectAllTeams, setIsSelectTeamsAll] = useState(false);

   const {
      data: departments,
      lastItemRef: lastFilterDepartmentRef,
      searchedValue: filterSearchedValueDep,
      setSearchedValue: setFilterSearchedValueDep,
   } = useGetDepartments({
      companyId: companyId,
   });

   const {
      data: positions,
      lastItemRef: lastFilterPositionRef,
      searchedValue: filterSearchedValuePos,
      setSearchedValue: setFilterSearchedValuePos,
      refetch: refetchFilterPositions,
   } = useGetPositions({
      companyId: companyId,
   });

   const toggleSelectAllUsers = () => {
      if (isSelectAllUsers) {
         setSelectedUsers([]);
      } else {
         setSelectedUsers(usersList);
      }
      setIsSelectUsersAll(!isSelectAllUsers);
   };

   const toggleSelectAllTeams = () => {
      if (isSelectAllTeams) {
         setSelectedTeamList([]);
      } else {
         setSelectedTeamList(teamManager?.teamsList);
      }
      setIsSelectTeamsAll(!isSelectAllTeams);
   };

   const onSelectDepartment = (department) => {
      setSelectedDepartment(department);
   };

   const onSelectPosition = (position) => {
      setSelectedPosition(position);
   };

   const onSelectFilters = (user) => {
      if (selectedUsers?.find((item) => item?.id === user?.id))
         setSelectedUsers(
            selectedUsers?.filter((item) => item?.id !== user?.id)
         );
      else setSelectedUsers([...selectedUsers, user]);
   };

   const onSelectTeamsFilters = (team) => {
      if (selectedTeamList?.find((item) => item?.id === team?.id))
         setSelectedTeamList(
            selectedTeamList?.filter((item) => item?.id !== team?.id)
         );
      else setSelectedTeamList([...selectedTeamList, team]);
   };

   const filterByTeamSearch = (list) => {
      const searchLower = search.toLowerCase();

      return list?.filter((item) => {
         const name = item?.name?.toLowerCase();

         return name && name.includes(searchLower);
      });
   };

   const filterByUserSearch = (list) => {
      const searchLower = usersSearch.toLowerCase().trim();

      return list?.filter((item) => {
        const firstName = item?.firstName?.toLowerCase()?.trim();
        const lastName = item?.lastName?.toLowerCase()?.trim();
        const email = item?.email?.toLowerCase()?.trim();
        const name = item?.name?.toLowerCase()?.trim();
        const role = item?.role?.toLowerCase()?.trim();
  
        return (
          (firstName && firstName?.includes(searchLower)) ||
          (lastName && lastName?.includes(searchLower)) ||
          (email && email?.includes(searchLower)) ||
          (name && name?.includes(searchLower)) ||
          (role && role?.includes(searchLower))
        );
      });
    };


   const closeModal = () => {
      onClose();
      setStep(1);
      setSelectedMode(STUDENTS_TAB);
      setSelectedDepartment("");
      setSelectedPosition("");
      setSelectedCompany("");
      setSelectedUsers([]);
      setSelectedTeamList([]);
      setIsSelectTeamsAll(false);
      setIsSelectUsersAll(false);
      setUsersSearch('')
      setSearch('')
      setExcludedUsersSearch('')
   };

   const changeStatus = (asWho) => {
      confirmModal.close();
      changeAdminAsStudentStatus(clickedId, asWho);

      const userToMark = usersList?.find((item) => item?.id === clickedId);

      // Check if the user is not already in selectedUsers before adding
      if (userToMark && !selectedUsers.some((user) => user.id === clickedId)) {
         setSelectedUsers([...selectedUsers, userToMark]);
      }
   };

   const [roles, setRoles] = useState([]);
   const [roleLoading, setRoleLoading] = useState(false);

   const getRoles = async () => {
      setRoleLoading(true);
      const res = await new Roles().getRoles(companyId);

      if (res?.success?.data) {
         const excludedRoleIds = [ROLE_ID_GUEST, ROLE_ID_SUPER_ADMIN];
         const filteredRoles = res?.success?.data?.filter(role => !excludedRoleIds?.includes(role?.id));

         setRoles(filteredRoles);
      }
      setRoleLoading(false);
   };

   useEffect(() => {
       if(companyId && !roles?.length) {
          getRoles();
       }
    }, [companyId]);
 
    const setRoleForSelectedUser = (role, passedUser) => {
      const newUsers = selectedUsers.map(user => {
          if (user.id === passedUser?.id) {
              return {
                  ...user,
                  selectedRole: role.name,
                  selectedRoleId: role.id,
              };
          } else {
              return user;
          }
      });

      const newTeams = selectedTeamList.map(team => {
         const newUsers = team.users.map(user => {
             if (user.id === passedUser?.id) {
                 return {
                     ...user,
                     selectedRole: role.name,
                     selectedRoleId: role.id,
                 };
             } else {
                 return user;
             }
         });
 
         return {
             ...team,
             users: newUsers
         };
     });

      setRolesDropdownId('');
      setSelectedUsers(newUsers);
      setSelectedTeamList(newTeams);
  };
  
   const defineTitle = () => {
      if (step === 2) return "Select roles";
      if (selectedMode === STUDENTS_TAB) return "Select users";
      if (selectedMode === TEAM_TAB) return "Select teams";
   };

   const allUsersFromTeams = selectedTeamList.reduce((allUsers, team) => {
      return allUsers.concat(team.users);
  }, []);

  const mappedUsersFromTeam = allUsersFromTeams?.map(user => ({
      ...user,  
      name: `${user?.firstName} ${user?.surname || ''}`,
      role: '-',
  }))

  const allSelectedUsers = Array.from(new Set([...selectedUsers, ...mappedUsersFromTeam]));

  const filterByRole = (list) => {
   const searchLowerRole = stepSecondRole?.toLowerCase().trim();

   if(searchLowerRole === 'all') return list;

   return list.filter(item => {
       const role = item?.role?.toLowerCase()?.trim();
       return role && role.includes(searchLowerRole);
   });
};

  const filterByDepartment = (list) => {
   const searchLowerDepartment = stepSecondDepartment?.toLowerCase().trim();

   if(searchLowerDepartment === 'all') return list;

   return list.filter(item => {
       const role = item?.role?.toLowerCase()?.trim();
       return role && role.includes(searchLowerDepartment);
   });
};

    const displayedUsers = filterByDepartment(filterByRole(allSelectedUsers));

// временные состояния фильтров для возможности применения после нажатия на apply
    const [tempStepSecondRole, setTempStepSecondRole] = useState('');
    const [tempStepSecondDepartment, setTempStepSecondDepartment] = useState('');

// applyFilterStepTwo — обновляет значения основного состояния фильтров
    const applyFilterStepTwo = () => {
        setStepSecondRole(tempStepSecondRole);
        setStepSecondDepartment(tempStepSecondDepartment);
        setIsShowFilters(false);
    };


   const renderSecondStepList = (team) => {
       return filterByUserSearch(displayedUsers)?.length === 0 ?
       <div
           className={styles.notFound}
       >
           <img src={notFound} alt={""} />
           {t("Not found")}
       </div>
      : (
         <div  className={styles.list}>
                  {filterByUserSearch(displayedUsers)?.map((item, key) => (
                     <div className={styles.flexedModified} key={key}>
                        <div
                           key={key}
                           className={styles.list_item_second}
                        >
                           <img
                              src={`${
                                 item?.avatar
                                    ? item?.avatar
                                    : avatarPlaceholder
                              }`}
                              alt=""
                           />
                           <div className={styles.user_info}>
                              <span>
                                 {item?.name || item?.email}
                              </span>
                              <div className={styles.role}>
                                 {item?.role === '-' ? '' : item?.role}
                              </div>
                           </div>
                        </div>
                        <button onClick={(e)=> e.stopPropagation()} className={styles.roleSetter}>
                           <div 
                              className={cn(
                                    styles.switch, 
                                    item?.id === rolesDropdownId ? styles.activeDrop : '')
                                 }
                              onClick={()=> setRolesDropdownId(item?.id !== rolesDropdownId ? item?.id : '')}>
                              <div className={styles.selectedRoleTitle}>
                                  <CustomTooltip
                                      id={`${item?.selectedRole}` ||'selectedRole'}
                                      text={transformRoleText(item?.selectedRole, companyName) || t('Role')}
                                      limit={7}
                                  />
                              </div>
                              <img className={rolesDropdownId === item?.id ? styles.rotated : ''} src={arrow} alt="" />
                           </div>
                           {rolesDropdownId === item?.id && <div className={styles.dropdownRoles}>
                              {[{id: ROLE_ID_STUDENT, name: "Student"}, ...(roles || [])].map((role) => 
                                 <p key={role.id} onClick={() => setRoleForSelectedUser(role, item)}>
                                    {transformRoleText(role.name, companyName)}
                                 </p>
                              )}
                           </div>}
                        </button>
                     </div>
                  ))}
               </div>
      )
   }

   if (selectedMode === TEAM_TAB) {
      return (
         <ModalLayout
            isOpen={isOpen}
            onClose={closeModal}
            maxHeight={"650px"}
            maxWidth={"490px"}
            withCross
            dFlex
         >
            <p className={styles.title}>{defineTitle()}</p>

            {step === 1 && (
               <div className={styles.tabs}>
                  {ACCESS_TABS?.map((item, key) => (
                     <TabButton
                        item={item}
                        key={key}
                        mode={selectedMode}
                        setMode={setSelectedMode}
                     />
                  ))}
               </div>
            )}

            <p className={styles.selected}>
               Selected:{" "}
               <span>{ step === 2 ? allSelectedUsers.length : selectedTeamList?.length}</span>
            </p>
          

            <div className={styles.searchAndFiltersBlock}>
               <div className={styles.search}>
                  <img src={searchIcon} alt={""} />
                  <input
                     placeholder={"Search"}
                     value={step === 1 ? teamManager?.searchedValue : usersSearch}
                     onChange={(event) => step === 1 ? teamManager.setSearchedValue(event?.target?.value) : setUsersSearch(event?.target?.value)}
                  />
               </div>
              {step === 2 && 
               <div className={cn(styles.filter_wrapper, isShowFilters ? styles.filter_opened : "", 'noselect')} ref={filtersOptionRef} onClick={toggleFiltersBlock}>
                  <img src={filtersImg} alt={""} />
                   <span>{t("Filters")}</span>

                  <img
                     className={cn(
                        styles.options_arrow,
                        isShowFilters ? styles.options_opened : ""
                     )}
                     src={arrow}
                     alt={""}
                  />
               </div>
              }
            </div>    

            {teamManager?.teamsList?.length > 0 && step === 1 && (
               <div
                  className={cn(styles.selectAllButton, 'noselect')}
                  onClick={toggleSelectAllTeams}
               >
                  <Checkbox isChecked={isSelectAllTeams} isGrey />
                  <span>{t("Select all")}</span>
               </div>
            )}

            {step === 2 && isShowFilters && (

            <div className={styles.searchAndFiltersBlock}>
               <div style={{top: '10px'}} ref={dropDownFiltersRef} className={styles.dropdowns}>
                  <div className={styles.dropdown}>
                     <Dropdown
                        data={DEFAULT_ROLES}
                        maxHeight={"180px"}
                        field={"name"}
                        value={tempStepSecondRole}
                        placeholder={t("Select by role")}
                        onChange={(role) => setTempStepSecondRole(role?.name)}
                        searchNeeded
                        isModalFilter
                     />
                  </div>
                  <div className={styles.dropdown}>
                     <PaginatedDropdown
                        data={departments}
                        field={"name"}
                        value={tempStepSecondDepartment}
                        placeholder={t("Select by department")}
                        onChange={(dep) => setTempStepSecondDepartment(dep?.name || "")}
                        maxHeight={"220px"}
                        searchNeeded
                        crossNeeded
                        searchValue={filterSearchedValueDep}
                        setSearchValue={setFilterSearchedValueDep}
                        lastItemRef={lastFilterDepartmentRef}
                     />
                  </div>

                   <Button
                       title={"Apply"}
                       onClick={(e) => {
                           applyFilterStepTwo()
                           setIsShowFilters(false)
                       }}
                       isHoverPurple
                   />
               </div>
            </div>
               
            )}

            {step === 2 && 
               renderSecondStepList(true)
            }

            {!!teamManager?.teamsList?.length && step === 1 && 
                  <div className={cn(styles.list, styles.team_list)}>
                  {teamManager?.teamsList?.map((item, key) => (
                     <div
                        ref={teamManager?.teamsList?.length - 1 === key && lastTeamRef ? lastTeamRef : null}
                        key={key}
                        onClick={() => onSelectTeamsFilters(item)}
                        className={cn(styles.list_item, styles.team_item)}
                     >
                        <Checkbox
                           isChecked={selectedTeamList?.some(
                              (sItem) => sItem?.id === item?.id
                           )}
                           isGrey
                        />
                        {item?.name}
                     </div>
                  ))}
               </div>
            }

             {teamManager?.teamsList?.length === 0 &&
                 <div
                     className={styles.notFound}
                  >
                     <img src={notFound} alt={""} />
                     {t("Not found")}
                  </div>
             }

            <div className={styles.btns}>
               <Button
                  isBlack
                  title={step === 1 ? t("Cancel") : t("Back")}
                  onClick={() => {
                     if (step === 1) {
                        closeModal();
                     }
                     if (step === 2) {
                        setStep(1);
                     }
                  }}
               />

               <Button
                  disabled={
                     selectedTeamList.length === 0 && selectedTeamList.length
                  }
                  title={step === 1 ? t("Next") : t("Confirm")}
                  onClick={() => {
                     if (step === 1) {
                        if(selectedUsers.length || selectedTeamList?.length) {
                           setStep(2);
                        } else {
                           toast(<ToastMsg text={"Choose at least one user or team"} isError/>)
                        }
                     }
                     if (step === 2) {
                        onConfirm();
                        closeModal();
                     }
                  }}
               />
            </div>

            <div className={styles.step_wrapper}>
               <StepIndicator step={step} bottom="0" />
            </div>
         </ModalLayout>
      );
   }

   if (selectedMode === STUDENTS_TAB) {
      return (
         <ModalLayout
            isOpen={isOpen}
            onClose={closeModal}
            maxHeight={"650px"}
            maxWidth={"520px"}
            withCross
            dFlex
            onClickToCloseDropdown={setRolesDropdownId}
         >
            <p className={styles.title}>{defineTitle()}</p>

            {step === 1 && (
               <div className={styles.tabs}>
                  {ACCESS_TABS?.map((item, key) => (
                     <TabButton
                        item={item}
                        key={key}
                        mode={selectedMode}
                        setMode={setSelectedMode}
                     />
                  ))}
               </div>
            )}

            <p className={styles.selected}>
               {t("Selected")}:{" "}
               <span>{step === 2 ? allSelectedUsers?.length : selectedUsers?.length}</span>
            </p>

            <div className={styles.searchAndFiltersBlock}>
               <div className={styles.search}>
                  <img src={searchIcon} alt={""} />
                  <input
                     placeholder={t("Search")}
                     value={step === 1 ? excludedUsersSearch : usersSearch}
                     onChange={(event) => step === 1 ? setExcludedUsersSearch(event?.target?.value) : setUsersSearch(event?.target?.value)}
                  />
               </div>

               <div className={cn(styles.filter_wrapper, isShowFilters ? styles.filter_opened : "", 'noselect')} ref={filtersOptionRef} onClick={toggleFiltersBlock}>
                  <img src={filtersImg} alt={""} />
                   <span>{t("Filters")}</span>

                  <img
                     className={cn(
                        styles.options_arrow,
                        isShowFilters ? styles.options_opened : ""
                     )}
                     src={arrow}
                     alt={""}
                  />
               </div>

               {step === 1 && isShowFilters && (
               <div ref={dropDownFiltersRef} className={styles.dropdowns}>
                  <div className={styles.dropdown}>
                     <PaginatedDropdown
                        data={departments}
                        field={"name"}
                        value={selectedDepartment?.name}
                        placeholder={t("Select by department")}
                        onChange={onSelectDepartment}
                        maxHeight={"220px"}
                        searchNeeded
                        searchValue={filterSearchedValueDep}
                        setSearchValue={setFilterSearchedValueDep}
                        lastItemRef={lastFilterDepartmentRef}
                        crossNeeded
                     />
                  </div>
                  <div className={styles.dropdown}>
                     <PaginatedDropdown
                        data={positions}
                        maxHeight={"180px"}
                        field={"name"}
                        value={selectedPosition?.name}
                        placeholder={t("Select by position")}
                        onChange={onSelectPosition}
                        searchNeeded
                        searchValue={filterSearchedValuePos}
                        setSearchValue={setFilterSearchedValuePos}
                        lastItemRef={lastFilterPositionRef}
                        crossNeeded
                     />
                  </div>
                  {allCompaniesApartFromCurrent?.length > 0 && 
                     <div className={styles.dropdown}>

                      <PaginatedDropdown
                           value={selectedCompany?.name}
                           lastItemRef={lastAgencyRef}
                           onChange={(company)=> setSelectedCompany(company)}
                           data={[{name: 'All'}, ...allCompaniesApartFromCurrent]}
                           placeholder={t("Select by company")}
                           field={"name"}
                           maxHeight={'200px'}
                        />
                     </div>
                  }
                   <Button
                       disabled={!allCompaniesApartFromCurrent?.length && !positions.length }
                       title={"Apply"}
                       onClick={(e) => {
                            onApplyFilter()
                            setIsShowFilters(false)
                       }}
                       isHoverPurple
                   />
               </div>
            )}
            </div>

            {usersList?.length > 0 && step === 1 && (
               <div
                  className={cn(styles.selectAllButton, 'noselect')}
                  onClick={toggleSelectAllUsers}
               >
                  <Checkbox isChecked={isSelectAllUsers} isGrey />
                  <span>{t("Select all")}</span>
               </div>
            )}

            {step === 2 && isShowFilters && (
               <div className={styles.searchAndFiltersBlock}>
                  <div style={{top: '10px'}} ref={dropDownFiltersRef} className={styles.dropdowns}>
                     <div className={styles.dropdown}>
                        <Dropdown
                           data={DEFAULT_ROLES}
                           maxHeight={"180px"}
                           field={"name"}
                           value={tempStepSecondRole}
                           placeholder={t("Select by role")}
                           onChange={(role) => setTempStepSecondRole(role?.name)}
                           searchNeeded
                           isModalFilter
                        />
                     </div>
                     <div className={styles.dropdown}>
                        <PaginatedDropdown
                           data={departments}
                           field={"name"}
                           value={tempStepSecondDepartment}
                           placeholder={t("Select by department")}
                           onChange={(dep) => setTempStepSecondDepartment(dep?.name || '')}
                           maxHeight={"220px"}
                           searchNeeded
                           searchValue={filterSearchedValueDep}
                           setSearchValue={setFilterSearchedValueDep}
                           lastItemRef={lastFilterDepartmentRef}
                           crossNeeded
                        />
                     </div>
                      <Button
                          title={"Apply"}
                          onClick={(e) => {
                              applyFilterStepTwo()
                              setIsShowFilters(false)
                          }}
                          isHoverPurple
                      />
                  </div>

               </div>

            )}

            {step === 2 &&
               renderSecondStepList()
            }

            {usersList?.length > 0 && step === 1 &&
               <div className={styles.list}>
                  {usersList?.map((item, key) => (
                     <div className={styles.flexed} key={key}>
                        <div
                           key={key}
                           onClick={() => onSelectFilters(item)}
                           className={styles.list_item}
                        >
                           <Checkbox
                              isChecked={selectedUsers?.some(
                                 (sItem) => sItem?.id === item?.id
                              )}
                              isGrey
                           />
                           <img
                              src={`${
                                 item?.avatar
                                    ? item?.avatar
                                    : avatarPlaceholder
                              }`}
                              alt=""
                           />
                           <div className={styles.user_info}>
                              <span>
                                 {item?.name}
                              </span>
                              <div className={styles.role}>
                                 {item?.role}
                              </div>
                           </div>
                        </div>
                     </div>
                  ))}
                  <div className={styles.flexedModified} ref={lastExcludedUsersRef ? lastExcludedUsersRef : null}></div>
               </div>
            }

            {usersList?.length === 0 && step === 1 &&
               <div
                  className={styles.notFound}
               >
                  <img src={notFound} alt={""} />
                  {t('Not found')}
               </div>
            }
             
            <div className={styles.btns}>
               <Button
                  isBlack
                  title={step === 1 ? t("Cancel") : t("Back")}
                  onClick={() => {
                     if (step === 1) {
                        closeModal();
                     }
                     if (step === 2) {
                        setStep(1);
                     }
                  }}
               />
               <Button
                  disabled={
                     selectedTeamList.length === 0 && selectedTeamList.length
                  }
                  title={step === 1 ? t("Next") : t("Confirm")}
                  onClick={() => {
                     if (step === 1) {
                        if(selectedUsers.length || selectedTeamList.length) {
                           setStep(2);
                        } else {
                           toast(<ToastMsg text={"Choose at least one user or team"} isError/>)
                        }
                     }
                     if (step === 2) {
                        onConfirm();
                        closeModal();
                     }
                  }}
               />
            </div>

            <ChooseAdminAsStudentModal
               onAdmin={() => changeStatus("admin")}
               onStudent={() => changeStatus("student")}
               onClose={confirmModal.close}
               isOpen={confirmModal.isActive}
               title={t("Adding an admin")}
               subtitle={t("You can add an admin as student or as admin")}
            />

            <div className={styles.step_wrapper}>
               <StepIndicator step={step} bottom="0"/>
            </div>
         </ModalLayout>
      );

      return <></>
   }
};

export default CompanyUsersAndTeamsModal;

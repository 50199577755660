import React, { useRef, useState } from "react";
import courseImg from "../../assets/images/course_thumbnail.png";
import passedImg from "../../assets/images/passed_img.svg";
import failedImg from "../../assets/images/failed_img.svg";
import arrow from "../../assets/images/symbols/arrow-course.svg";
import { v4 as uuidv4 } from "uuid";

import pauseImg from "../../assets/images/course_pause.svg";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import { Link, useNavigate } from "react-router-dom";
import {
  COURSE_STATUS_DELETED,
  COURSE_STATUS_HIDEN,
} from "../../constants/courses";

import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import {
  ROLE_ID_STUDENT,
  PART_OF_MENTOR_NAME,
  ROLE_ID_SUPER_ADMIN,
} from "../../constants/user";
import cn from "classnames";
import useUserRole from "../../hooks/useUserRole";
import { DEFAULT_TEXT, returnTranslation } from "../../constants/languages";
import CustomStaticTooltip from "../CustomStaticTooltip/CustomStaticTooltip";
import DotsButton from "../DotsButton/DotsButton";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const CourseBlock = ({
  course,
  refetchCourses,
  activeCourseFilter,
  folder,
  isDragging = false,
  isHomePage,
  onOpenDuplicateModal,
  studentCourseFilter,
  onConfirmOpenStatus,
  onConfirmOpenRestore,
  onConfirmOpenHide,
  onRetakeModalOpen,
  onConfirmOpenDelete,
  onConfirmOpen,
  onOpenDeleteFromFolder,
  lastCourseRef,
  maxWidth,
  onConfirmPausedOpen,
  isLoading,
  isByFolder,
  onOpenGiveAccess
}) => {
  const t = useFindTranslationsByString();
  const [isOpen, setIsOpen] = useState(false);
  const asStudent = useSelector((state) => state.sidebar.isUserAsStudent);
  const isSideBarFixed = useSelector((state) => state.sidebar.isFixed);

  const userInfo = useSelector((state) => state?.user?.info);
  const currentCompanyId = useSelector((state) => state.sidebar.companyId);
  const translations = useSelector((state) => state.sidebar.translations);
  const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

  const dotsRef = useRef();
  const dropdownRef = useRef();
  const navigate = useNavigate();

  const onToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
    // setAnchorEl(isOpen ? null : e.currentTarget);
  };
  const onClose = () => setIsOpen(false);

  const settingsLink = `/courses/settings/${course?.id}`;
  const contentLink = `/courses/course/${course?.id}/default_lang/${course?.defaultLanguage || "en_US"}/${course?.availableLanguages?.length > 1}`;
  const editLink = `/courses/edit/${course?.id}/default_lang/${course?.defaultLanguage || "en_US"}/${course?.availableLanguages?.length > 1}`;

  const onSettings = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(settingsLink);
  };

  const onContent = () => {
    if (isDragging) return;
    navigate(contentLink);
  };

  const onEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDragging) return;
    navigate(editLink);
  };

  const onOpenDuplicate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onOpenDuplicateModal) onOpenDuplicateModal(course);
    onClose();
  };

  useOutsideClick(dropdownRef, onClose, dotsRef);

  const courseImage =
    course?.image && !course.image.includes("static/media/")
      ? course?.image
      : courseImg;

  const progressCondition =
    studentCourseFilter === "ongoing" || !studentCourseFilter || isHomePage;

  const editLinkCondition =
    userRoleId !== ROLE_ID_STUDENT &&
    !userRoleName?.includes(PART_OF_MENTOR_NAME) &&
    !asStudent;

  const hideText = returnTranslation(translations, DEFAULT_TEXT.common.hide);
  const deleteText = returnTranslation(
    translations,
    DEFAULT_TEXT.common.delete,
  );
  const restoreText = returnTranslation(
    translations,
    DEFAULT_TEXT.common.restore,
  );
  const paused = returnTranslation(translations, DEFAULT_TEXT.common.paused);
  const changeToVisible = returnTranslation(
    translations,
    DEFAULT_TEXT.common.changeToVisible,
  );
  const duplicate = returnTranslation(
    translations,
    DEFAULT_TEXT.common.duplicate,
  );
  const settingsText = returnTranslation(
    translations,
    DEFAULT_TEXT.common.settings,
  );
  const editText = returnTranslation(translations, DEFAULT_TEXT.common.edit);
  const deleteFromFolderText = returnTranslation(
    translations,
    DEFAULT_TEXT.common.deleteFromFolder,
  );

  const openCourse = returnTranslation(
    translations,
    DEFAULT_TEXT.common.openCourse,
  );
  const retakeCourse = t("Retake Course");
  const addAccess = t("Add access");

  const clickHandler = (e) => {
    e.preventDefault();
    if (isLoading) return;

    if (editLinkCondition) {
      onEdit(e);
    } else {
      if (
        (userRoleId === ROLE_ID_STUDENT || asStudent) &&
        studentCourseFilter?.length &&
        studentCourseFilter !== "ongoing"
      ) {
        onRetakeModalOpen(course, e);
      } else if (
        course?.userStatus === COURSE_STATUS_HIDEN &&
        (studentCourseFilter === "ongoing" || !studentCourseFilter.length)
      ) {
        onConfirmPausedOpen(course, e);
      } else {
        onContent(e);
      }
    }
  };

  const hasPermissionToDuplicate = userInfo?.permissions?.some(
    (p) => p?.id === 284,
  );

  return (
    <>
      <Link
        to={editLinkCondition ? editLink : contentLink}
        style={{ maxWidth, width: maxWidth }}
        ref={lastCourseRef ? lastCourseRef : null}
        onClick={clickHandler}
        className={cn(
          styles.courseBlock,
          isByFolder && styles.isByFolder,
          isHomePage && styles.homePage,
          isSideBarFixed && !isHomePage && styles.sidebarFixed,
          studentCourseFilter?.length > 0 && studentCourseFilter !== "ongoing"
            ? styles.studentsCompletedCourse
            : "",
          course?.status === COURSE_STATUS_DELETED && styles.deleted,
          course.status === COURSE_STATUS_HIDEN ? styles.hidden_layout : "",
          course?.userStatus === COURSE_STATUS_HIDEN
            ? styles.user_course_paused
            : "",
        )}
      >
        <div
          style={{
            "--course-open": `"${openCourse}"`,
            "--course-rework": `"${retakeCourse}`,
            "--specific-width": maxWidth || "300px",
          }}
          className={cn(
            styles.image_wrapper,
            styles.home_image_wrapper,
            studentCourseFilter?.length && studentCourseFilter !== "ongoing"
              ? styles.image_wrapperComplete
              : "",
            course?.status === COURSE_STATUS_DELETED && styles.deleted,
            course?.userStatus === COURSE_STATUS_HIDEN
              ? styles.pausedCourse
              : "",
          )}
        >
          {course?.status !== COURSE_STATUS_DELETED && (
            <img src={courseImage} alt={""} className={styles.image} />
          )}

          <div className={styles.home_image_wrapper_after}>
            <span>
              {studentCourseFilter?.length && studentCourseFilter !== "ongoing"
                ? retakeCourse
                : openCourse}
            </span>{" "}
            <img src={arrow} alt="Arrow" />
          </div>

          {studentCourseFilter?.length > 0 &&
            studentCourseFilter !== "ongoing" && (
              <div className={styles.result}>
                <img
                  src={studentCourseFilter === "failed" ? failedImg : passedImg}
                  alt=""
                />
                <span>{course?.result || 0} / 100</span>
              </div>
            )}
        </div>

        {course?.userStatus === COURSE_STATUS_HIDEN && (
          <div className={styles.pauseWrapper}>
            <img src={pauseImg} alt="" />
            <span>{paused}</span>
          </div>
        )}

        <div className={styles.content}>
          {userRoleId !== ROLE_ID_STUDENT &&
            !userRoleName?.includes(PART_OF_MENTOR_NAME) &&
            userRoleId &&
            !asStudent && (
              <DotsButton
                dotsRef={dotsRef}
                isOpen={isOpen}
                onToggle={onToggle}
              />
            )}

          {isOpen && (
            <div
              onClick={(e) => e.stopPropagation()}
              ref={dropdownRef}
              className={cn(styles.dropdown, isByFolder && styles.isByFolder)}
            >
              {activeCourseFilter === "hidden" && (
                <p onClick={(e) => onConfirmOpenStatus(course, e)}>
                  {changeToVisible}
                </p>
              )}

              {activeCourseFilter === "deleted" && (
                <>
                  <p onClick={(e) => onConfirmOpenRestore(course, e)}>
                    {restoreText}
                  </p>
                  <p onClick={(e) => onConfirmOpenDelete(course, e)}>
                    {deleteText}
                  </p>
                </>
              )}

              <p onClick={onSettings}>{settingsText}</p>
              <p onClick={onEdit}>{editText}</p>

              {activeCourseFilter !== "hidden" && !isHomePage && (
                <p onClick={(e) => onConfirmOpenHide(course, e)}>{hideText}</p>
              )}

              {activeCourseFilter !== "deleted" && !isHomePage && (
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (folder) {
                      onOpenDeleteFromFolder(course, e);
                    } else {
                      onConfirmOpen(course, e);
                    }
                  }}
                >
                  {folder ? deleteFromFolderText : deleteText}
                </p>
              )}

              {activeCourseFilter !== "deleted" &&
                !isHomePage &&
                (userRoleId === ROLE_ID_SUPER_ADMIN ||
                  hasPermissionToDuplicate) && (
                  <p onClick={onOpenDuplicate}>{duplicate}</p>
                )}
                
                {!isHomePage &&
                  <p onClick={(e)=> onOpenGiveAccess(course, e)}>{addAccess}</p>
                }
            </div>
          )}

          <p className={styles.title}>
            {course?.title ? course?.title : course?.name}
          </p>

          {progressCondition && (
            <div className={styles.result_line}>
              <span>{course?.progress || "0"}%</span>
              <div className={styles.result_line_wrapper}>
                <span
                  style={{ width: `${course?.progress || 0}%` }}
                  className={cn(styles.line, styles.purple_line)}
                ></span>
              </div>
            </div>
          )}

          {!!course?.tagList?.length && !isHomePage && (
            <div className={styles.row}>
              {course?.tagList?.map((item, key) => (
                <span key={key}>{item}</span>
              ))}
            </div>
          )}

          {studentCourseFilter?.length > 0 &&
            studentCourseFilter !== "ongoing" && (
              <div className={styles.result_line}>
                <span>{course?.result || "0"}%</span>
                <div className={styles.result_line_wrapper}>
                  <span
                    style={{ width: `${course?.result || 0}%` }}
                    className={cn(
                      styles.line,
                      studentCourseFilter === "failed"
                        ? styles.red_line
                        : styles.greenline,
                    )}
                  ></span>
                </div>
              </div>
            )}

          {/*<div className={styles.info}>*/}
          {/*   <div className={styles.info_item}>*/}
          {/*      <img src={clock} alt={''}/>*/}
          {/*      <CustomStaticTooltip*/}
          {/*          id={uuidv4()}*/}
          {/*          hint={timeForCoursePassing}*/}
          {/*          text={`12 ${hoursText}`}*/}
          {/*      />*/}
          {/*   </div>*/}

          {/*   /!* <div className={styles.info_item}>*/}
          {/*            <img src={star} alt={''}/>*/}
          {/*            <CustomStaticTooltip*/}
          {/*               id={uuidv4()}*/}
          {/*               hint={'In progress'}*/}
          {/*               text={'4.7'}*/}
          {/*            />*/}
          {/*        </div> *!/*/}

          {/*   {userRoleId && userRoleId !== ROLE_ID_STUDENT && !!course?.users &&*/}
          {/*       <div className={styles.info_item}>*/}
          {/*          <img src={eye} alt={''}/>*/}
          {/*          <CustomStaticTooltip*/}
          {/*              id={uuidv4()}*/}
          {/*              hint={usersThatHaveAccessToCourse}*/}
          {/*              text={course?.users || 0}*/}
          {/*          />*/}
          {/*       </div>*/}
          {/*   }*/}

          {/*</div>*/}
          <div className={styles.text}>
            <CustomStaticTooltip
              maxWidth={"100%"}
              id={uuidv4()}
              hint={course?.description}
              text={course?.description}
            />
          </div>
          {!!course?.tags?.length && !isHomePage && (
            <div className={styles.row}>
              {course?.tags?.map((item, key) => (
                <span key={key}>{item}</span>
              ))}
            </div>
          )}
        </div>
      </Link>
    </>
  );
};

export default CourseBlock;

import React, { useState, useEffect } from "react";

import arrow from "../../../assets/images/symbols/Arrow - Down.svg";
import statusActiveImg from "../../../assets/images/symbols/arrow_green.svg";
import statusPausedImg from "../../../assets/images/paused_status.svg";
import statusDeletedImg from "../../../assets/images/deleted_status.svg";
import info from "../../../assets/images/icon_info.svg";
import rework_img from "../../../assets/images/rework_icon_purple.svg";
import cn from "classnames";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import CourseStatusModal from "../../../components/Modals/CourseStatusModal/CourseStatusModal";
import {
   STUDENT_STATUS_ACTIVE,
   STUDENT_STATUS_DELETED,
   STUDENT_STATUS_PAUSED,
} from "../../../constants/courses";
import { ROLES_COLORS } from "../../../constants/notifications";
import useSortableAndFuzzyData from "../../../hooks/useSortableAndFuzzyData";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import ProgressLine from "../../../components/ProgressLine/ProgressLine";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const CoursesTable = ({
   headers,
   data,
   activeTab,
   searchValue,
   refetchCourseUsers,
   isLoading,
   lastCourseRef,
}) => {
   const handleStatusModal = useHandleModal();
   const [clickedItem, setClickedItem] = useState(null);
   const [activeImage, setActiveImage] = useState("");

   const t = useFindTranslationsByString();

   const params = useParams();

   const onCloseStatusModal = () => handleStatusModal.close();
   const onOpenStatusModal = (item) => {
      handleStatusModal.open();
      setClickedItem(item);
   };

   const [sortColumn, setSortColumn] = useState("");
   const [sortDirection, setSortDirection] = useState("");

   const handleSort = (column) => {
      if (sortColumn === column) {
         setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
         setSortColumn(column);
         setSortDirection("asc");
      }
   };

   const defineIcon = (status) => {
      if (status === STUDENT_STATUS_ACTIVE) {
         return statusActiveImg;
      } else if (status === STUDENT_STATUS_PAUSED) {
         return statusPausedImg;
      } else if (status === STUDENT_STATUS_DELETED) {
         return statusDeletedImg;
      }
   };

   const sortedData = data?.sort((a, b) => {
      if (sortDirection === "asc") {
         if (typeof a[sortColumn] === "string") {
            return a[sortColumn].localeCompare(b[sortColumn]);
         } else {
            return a[sortColumn] - b[sortColumn];
         }
      } else {
         if (typeof a[sortColumn] === "string") {
            return b[sortColumn].localeCompare(a[sortColumn]);
         } else {
            return b[sortColumn] - a[sortColumn];
         }
      }
   });

   if (data?.length === 0 && searchValue?.length > 0) {
      return <EmptyBlock text={"courses"} key={activeTab} />;
   }

   return (
      <div className={styles.tableWrapper}>
         <table cellSpacing={0} className={styles.table}>
            <thead>
               <tr>
                  {headers?.map((item, key) => (
                     <th key={`${item?.title}_${key}`} className={cn(item.field === 'status' && styles.status)}>
                        <div
                           onClick={() => handleSort(item.field)}
                           className={styles.table_header}
                        >
                           {t(item.title)}
                           {sortColumn === item.field ? (
                              <img
                                 src={arrow}
                                 alt="arrow"
                                 className={
                                    sortDirection === "asc" ? styles.asc : ""
                                 }
                              />
                           ) : (
                              <img src={arrow} alt="arrow" />
                           )}
                        </div>
                     </th>
                  ))}
               </tr>
            </thead>

            <tbody className={styles.table_body}>
               {sortedData?.map((item, key) => (
                  <tr
                     key={`${item?.name}_${key}`}
                     ref={
                        lastCourseRef && sortedData?.length - 1 === key
                           ? lastCourseRef
                           : null
                     }
                     className={styles.table_row}
                  >
                     <td className={styles.table_data}>
                        <div className={cn(styles.table_data_depart)}>
                           {item?.folder}
                        </div>
                     </td>
                     <td className={styles.table_data}>
                        <div className={styles.course_wrapper}>
                           {item?.name}
                           {item?.isRework && (
                              <div className={styles.rework_wrapper}>
                                 <div className={styles.rework}>
                                    <img src={rework_img} alt="" />
                                    <span>Rework</span>
                                 </div>

                                 <div className={styles.info_rework}>
                                    <img src={info} alt="" />

                                    <div className={styles.hint}>
                                       {t("Course is being retaken by the user")}
                                    </div>
                                 </div>
                              </div>
                           )}
                        </div>
                     </td>
                     <td className={styles.table_data}>{item?.was}</td>
                     <td className={cn(styles.table_data, styles.role)}>
                        <div
                           style={{
                              backgroundColor: ROLES_COLORS?.find(
                                 (c) =>
                                    c?.title?.toLowerCase() ===
                                    item?.role?.toLowerCase()
                              )?.color,
                           }}
                           className={styles.dotM}
                        ></div>
                        {t(item?.role)}
                     </td>
                     <td className={cn(styles.table_data, styles.progress)}>
                        <ProgressLine progress={item?.progress} />
                     </td>
                     <td className={cn(styles.table_data, styles.status)}>
                        <div
                           className={styles.actions}
                           onClick={() => onOpenStatusModal(item)}
                        >
                           <img src={defineIcon(item?.status)} alt="icon" />
                           <img src={arrow} alt="" />
                        </div>
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>

         {data?.length === 0 && !isLoading && <EmptyBlock key={activeTab} />}

         <CourseStatusModal
            onClose={onCloseStatusModal}
            isOpen={handleStatusModal.isActive}
            item={clickedItem}
            isRevert
            refetchCourseUsers={refetchCourseUsers}
         />
      </div>
   );
};

export default CoursesTable;

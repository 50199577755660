import { useState, useEffect } from "react";
import { debounce } from "lodash";
import UserApi from "../../utils/api/UserApi";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import { DEFAULT_DATA_LIMIT, BIGGER_DATA_LIMIT } from "../../constants/user";
import { useSelector } from "react-redux";

const useGetUsersOfCourse = (courseID, serializationGroup) => {
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("");
  const [userList, setUserList] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUserListChangeStatus, setSelectedUserListChangeStatus] =
    useState([]);

  const [emails, setEmails] = useState([]);

  const companyId = useSelector((state) => state.sidebar.companyId);

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (
      selectAll &&
      userList?.length !== totalUsers &&
      userList?.length < BIGGER_DATA_LIMIT
    ) {
      setCurrentPage(1);
      debouncedFetchUsers();
    }

    return () => debouncedFetchUsers.cancel();
  }, [selectAll]);

  const fetchUsers = async (page, limit = null) => {
    setIsUsersLoading(true);

    try {
      const res = await new UserApi().getUsersByCourseID({
        companyId,
        limit: limit
          ? limit
          : selectAll
            ? BIGGER_DATA_LIMIT
            : DEFAULT_DATA_LIMIT,
        page: page ? page : currentPage,
        courseID,
        searchValue: searchedValue,
        orderBy,
        order,
        emails,
        serializationGroup,
      });

      if (res?.success?.data) {
        const users = res?.success?.data?.users || [];

        const mappedUsers = users?.map((user) => {
          const theCourseData = res?.success?.data?.extra_data?.find(
            (item) => +item?.user_id === +user?.id,
          );

          const progress = theCourseData
            ? theCourseData?.user_course_progress
            : "-";
          const status_on_course = theCourseData
            ? theCourseData?.user_course_status
            : "-";
          const is_as_student = !!theCourseData?.user_course_is_admin_student;
          const user_course_result = Number(theCourseData?.user_course_result);

          return {
            ...user,
            progress,
            status_on_course,
            is_as_student,
            user_course_result
          };
        });

        if (selectAll) {
          setSelectedUserListChangeStatus(mappedUsers);
        }

        if (page) {
          setUserList(mappedUsers);
        } else {
          setUserList((prev) => [...prev, ...mappedUsers]);
        }

        setTotalUsers(res?.success?.totalCount);
      }

      if (res?.success?.data?.length === 0) {
        setCurrentPage(1);
      }

      if (res?.error?.message && page) {
        setUserList([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsUsersLoading(false);
    }
  };

  const debouncedFetchUsers = debounce(
    () => fetchUsers(1),
    DEFAULT_DEBOUNCE_DELAY,
  );

  const refetchUsers = () => {
    debouncedFetchUsers();
    setCurrentPage(1);
  };

  useEffect(() => {
    if (currentPage > 1 && userList?.length <= totalUsers) {
      fetchUsers();
    }
  }, [currentPage]);

  useEffect(() => {
    if (courseID && companyId) {
      setCurrentPage(1);
      debouncedFetchUsers();
    }

    return () => debouncedFetchUsers.cancel();
  }, [searchedValue, courseID, companyId, orderBy, order, emails]);

  return {
    isUsersLoading,
    userList,
    totalUsers,
    currentPage,
    fetchUsers,
    debouncedFetchUsers,
    setCurrentPage,
    searchedValue,
    setSearchedValue,
    setTotalUsers,
    refetchUsers,
    orderBy,
    setOrderBy,
    setUserList,
    order,
    setOrder,
    selectedUserListChangeStatus,
    setSelectedUserListChangeStatus,
    setSelectAll,
    selectAll,
    emails,
    setEmails,
  };
};

export default useGetUsersOfCourse;

import React, { useState } from "react";

import shortLogo from "../../../assets/images/favicon.png";

import styles from "./styles.module.scss";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import Button from "../../../components/Button/Button";

const IconEditModal = ({ onClose, isActive, title, desc, renderedIcon, icon, onSave }) => {
   const t = useFindTranslationsByString();

   return (
      <ModalLayout
         isOpen={isActive}
         maxHeight={"474px"}
         maxWidth={"500px"}
         onClose={onClose}
         withCross
         customPadding={"20px"}
         customCrossStyles={{ top: "0", right: "0" }}
      >
         <div className={styles.popup_upload}>
            <div className={styles.popup_upload_container}>
               <div className={styles.settings_logo_header}>
                  <span
                     className={`${styles.settings_box_icon} ${styles.settings_box_icon_logo}`}
                  />
                  <div className={styles.settings_logo_header_text}>
                     <p className={styles.settings_logo_title}>
                        {title}
                     </p>
                     <p className={styles.settings_logo_description}>
                        {desc}
                     </p>
                  </div>
               </div>
               <div className={styles.upload_image_box}>
                  <div className={styles.image_resize_box}>
                     <img 
                        src={renderedIcon ? renderedIcon : shortLogo} 
                        alt="logo"
                     />
                  </div>
                  <div className={styles.upload_image_data}>
                     <div className={styles.upload_image_info}>
                        <span className={styles.upload_preview_wrapper}>
                           <img
                              src={renderedIcon ? renderedIcon : shortLogo}
                              width="24"
                              height="24"
                              alt="preview logo"
                           />
                        </span>
                        <div className={styles.upload_image_info_text}>
                           <p className={styles.upload_image_name}>
                              Irens logotype icon.png
                           </p>
                           <p className={styles.upload_image_size}>
                              {(icon?.size / 1024).toFixed(2)} KB
                           </p>
                        </div>
                     </div>

                     <button className={styles.button_popup_close} onClick={onClose}>
                        X
                     </button>
                  </div>
               </div>

               <div className={styles.popup_button_grid}>
                  <Button isBlack onClick={onClose} title={t("Cancel")}/>
                  <Button onClick={onSave} title={t("Save changes")} isHoverPurple/>
               </div>
            </div>
         </div>
      </ModalLayout>
   );
};

export default IconEditModal;

import dotsWhite from "../../../../assets/images/symbols/three_dots_white.svg";
import tests from "../../../../assets/images/icon_task1.svg";
import testsActive from "../../../../assets/images/icon_task.svg";
import lessonImg from "../../../../assets/images/icon_play.svg";
import lockIcon from "../../../../assets/images/lock_icon.svg";
import unlockIcon from "../../../../assets/images/unlock_icon.svg";
import unlockWhite from "../../../../assets/images/unlock_white.svg";
import lessonDoneImg from "../../../../assets/images/progress/lesson_done.svg";
import lessonOnCheckImg from "../../../../assets/images/progress/lesson_on_check.svg";
import lessonOnReworkImg from "../../../../assets/images/progress/lesson_on_rework.svg";
import lessonFailedImg from "../../../../assets/images/progress/lesson_failed.svg";
import cn from "classnames";

import styles from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import LessonApi from "../../../../utils/api/LessonApi";
import {
   LESSON_TYPE_WITHOUT_TESTING_NUMBER,
   LESSON_TYPE_WITH_TESTING_NUMBER,
} from "../../../../constants/courses";
import { useEffect, useState } from "react";
import useOutsideClick from "../../../../hooks/dom/useOutsideClick";
import DraggingArrows from "../../../../components/DraggingArrows/DraggingArrows";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import { Tooltip } from "react-tooltip";
import useHandleModal from "../../../../hooks/dom/useHandleModal";
import {
   TEST_STATUS_APPROVED,
   TEST_STATUS_ON_CHECK,
   TEST_STATUS_ON_REWORK,
   TEST_STATUS_REJECTED,
} from "../../../../constants/tests";
import { v4 as uuidv4 } from "uuid";
import CustomStaticTooltip from "../../../../components/CustomStaticTooltip/CustomStaticTooltip";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

const LessonItem = ({
   onClickElement,
   activeElement,
   lesson,
   moduleId,
   fetchData,
   questionsDataFromInputs,
   questions,
   lessonIndex,
   moduleIndex,
   isLinear,
   notAvailableModal,
   nextLesson,
   activeLessonRef,
   userStatusOnCoursePaused
}) => {

   const t = useFindTranslationsByString();

   const defineTestCompletedStatusIcon = () => {
      const latestTest = lesson?.userTests?.length 
         ? lesson.userTests.reduce((latest, current) => 
            new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest
         ) 
         : null;
  
 
     const userTestStatus = latestTest?.status;

      let statusImage;

      // Determine the image based on the status
      if (userTestStatus === TEST_STATUS_ON_CHECK) {
         return  <CustomStaticTooltip
                     id={uuidv4()}
                     icon={lessonOnCheckImg}
                     hint={t("On check")}
                  />;
      } else if (userTestStatus === TEST_STATUS_ON_REWORK) {
         return  <CustomStaticTooltip
                     id={uuidv4()}
                     icon={lessonOnReworkImg}
                     hint={t("To rework")}
                  />;
      } else if (userTestStatus === TEST_STATUS_REJECTED) {
         return  <CustomStaticTooltip
                     id={uuidv4()}
                     icon={lessonFailedImg}
                     hint={t("Failed")}
                  />;
      } else if (userTestStatus === TEST_STATUS_APPROVED) {
         return <CustomStaticTooltip
                  id={uuidv4()}
                  icon={lessonDoneImg}
                  hint={t("Done")}
               />;
      } else {
         return;
      }
   };

   const lessonImgCondition =
      lesson?.type === 0
         ? lessonImg
         : activeElement?.id === lesson?.id
         ? testsActive
         : tests;

   const isLinearLessonClick = () => {
      const latestTest = lesson?.userTests?.length 
            ? lesson.userTests.reduce((latest, current) => 
               new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest
            ) 
            : null;

      if (lesson?.unlocked && !userStatusOnCoursePaused) {
         onClickElement(lesson, lessonIndex);
      } else if(lesson?.completed && userStatusOnCoursePaused && !latestTest.status) {
         onClickElement(lesson, lessonIndex);
      } else if((latestTest?.status === TEST_STATUS_APPROVED) && (userStatusOnCoursePaused)) {
         onClickElement(lesson, lessonIndex);
      } else {
         notAvailableModal.open();
      }
   };

   const onClickElementOther = () => {
      const latestTest = lesson?.userTests?.length 
         ? lesson.userTests.reduce((latest, current) => 
            new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest
         ) 
         : null;
  

      if(userStatusOnCoursePaused && latestTest?.status && latestTest?.status !== TEST_STATUS_APPROVED) {
         notAvailableModal.open();
         return;
      }

      if(userStatusOnCoursePaused && !lesson?.completed && !latestTest?.status) {
         notAvailableModal.open();
      } else {
         onClickElement(lesson, lessonIndex);
      }
   };

   const isLessonHasUserTests = lesson?.userTests?.length > 0;

   return (
      <div
         ref={activeElement?.id === lesson?.id ? activeLessonRef : null}
         key={lesson.id}
         onClick={() => {
            isLinear
               ? isLinearLessonClick()
               : onClickElementOther(lesson, lessonIndex);
         }}
         className={cn(
            styles.element,
            activeElement?.id === lesson?.id ? styles.element_active : ""
         )}
      >
         <div className={styles.element_title}>
            <img
               src={lessonImgCondition}
               alt={"lesson type icon"}
               width={24}
               height={24}
               className={styles.lesson_img}
            />
            <span className={styles.number}>
               {moduleIndex + 1}.{lessonIndex + 1}{" "}
            </span>
            <CustomTooltip
               limit={15}
               delay={500}
               maxWidth={"250px"}
               isWidthMoreThanParent
               text={lesson?.name}
               id={`lesson_name_${lesson?.id}`}
            />
         </div>

         {isLinear && !lesson?.completed && !isLessonHasUserTests && (
            <img
               className={cn(styles.lock_icon, 'noselect')}
               src={lesson?.unlocked ? unlockWhite : lockIcon}
               alt={""}
            />
         )}

         {lesson?.completed && !isLessonHasUserTests && (
           <CustomStaticTooltip
               id={uuidv4()}
               icon={lessonDoneImg}
               hint={t("Done")}
            />
         )}


         {isLessonHasUserTests && defineTestCompletedStatusIcon()}
      </div>
   );
};

export default LessonItem;

import {
   MULTIPLE_CHOICE_ANSWER_CODE,
   ONE_CHOICE_ANSWER_CODE,
   TRUE_FALSE_ANSWER_CODE,
} from "../../../../utils/questionsHelper";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

import styles from "./styles.module.scss";
import cn from "classnames";
import Checkbox, { CheckboxVariants } from "../../../../components/Checkbox/Checkbox";

const SomeAnswerInputs = ({ type, item, answerIdx, setInputsData }) => {
   const t = useFindTranslationsByString();

   const handleSelectAnswer = (id, isMultiple) => {
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: prevInputsData.answers.map((answer) =>
             isMultiple
                 ? answer.id === id
                     ? { ...answer, isChosen: !answer.isChosen }
                     : answer
                 : { ...answer, isChosen: answer.id === id }
         ),
      }));
   };

   const renderAnswer = (checkboxVariant, additionalStyles) => (
       <div
           key={answerIdx}
           className={cn(styles.answer, styles.no_padding)}
           onClick={() => handleSelectAnswer(item?.id, type === MULTIPLE_CHOICE_ANSWER_CODE)}
       >
          <label
              htmlFor={`${item.content} ${item?.id}`}
              className={cn(
                  styles.answer_block,
                  styles.label,
                  item?.isChosen ? styles.answer_block_isSelected : "",
                  additionalStyles
              )}
          >
             <Checkbox isChecked={item?.isChosen} variant={checkboxVariant} />
             {t(item?.content)}
          </label>
       </div>
   );

   switch (type) {
      case MULTIPLE_CHOICE_ANSWER_CODE:
         return renderAnswer(null, styles.multiple_answer);

      case ONE_CHOICE_ANSWER_CODE:
         return renderAnswer(CheckboxVariants.roundedWithIcon, null);

      case TRUE_FALSE_ANSWER_CODE:
         return renderAnswer(CheckboxVariants.roundedWithIcon, styles.true_false);

      default:
         return <></>;
   }
};

export default SomeAnswerInputs;

import cn from "classnames";
import Input from "../../../components/Input/Input";
import Switch from "../../../components/Switch/Switch";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const LessonTypeLesson = ({ inputsData, setInputsData, loading}) => {
   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");

   const t = useFindTranslationsByString();

   const formatDate = (date) => {
      if (!date) {
         return "";
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
   };

   const onChange = (dates) => {
      const [start, end] = dates;
      const date = dates.map(formatDate);

      setInputsData({
         ...inputsData,
         new_available_date_range: `${date[0]} - ${date[1]}`,
      });

      setStartDate(start);
      setEndDate(end);
   };

   useEffect(() => {
      const dateRangeParts = inputsData.new_available_date_range?.split(" - ");

      if (dateRangeParts?.length === 2) {
         setStartDate(new Date(dateRangeParts[0]));
         setEndDate(new Date(dateRangeParts[1]));
      }
   }, [loading]);

   return (
      <div className={styles.lesson_block}>
         <Input
            placeholder={`${t("Enter a title")}...`}
            withHeader
            title={t("Title")}
            value={inputsData?.new_name}
            onChangeValue={(event) =>
               setInputsData({
                  ...inputsData,
                  new_name: event?.target?.value,
               })
            }
         />

         {/* <div className={cn(styles.block, styles.date_block)}>
            <p className={styles.date_title}>{t("Availability date")}</p>
            <DatePicker
               className={styles.date_picker}
               selected={startDate}
               startDate={startDate}
               endDate={endDate}
               selectsRange
               onChange={onChange}
            />

            <p className={styles.gray_text}>
               {t("The function allows you to make content available to students on a specific date")}.
            </p>
         </div> */}

         <div className={cn(styles.block, styles.protection_bloc)}>
            <div className={styles.protection_switch}>
               <Switch
                  title={t("Protection")}
                  onChange={() =>
                     setInputsData({
                        ...inputsData,
                        new_is_protected: +!inputsData?.new_is_protected,
                     })
                  }
                  checked={inputsData?.new_is_protected}
               />
               <p className={styles.gray_text}>
                  {t("Enable protection of texts and pictures of the lesson from copying")}.
               </p>
            </div>
         </div>
      </div>
   );
};

export default LessonTypeLesson;

import React, { useState } from "react";
import styles from "./styles.module.scss";
import TextArea from "../../../components/TextArea/TextArea";
import Input from "../../../components/Input/Input";
import Dropdown from "../../../components/Dropdown/Dropdown";
import plus from "../../../assets/images/plus.svg";
import { TIME_ZONES } from "../../../constants/agencies";
import { useParams } from "react-router-dom";
import { ROLE_ID_SUPER_ADMIN } from "../../../constants/user";
import { useSelector } from "react-redux";

const BasicBlock = ({
  blockRef,
  setAgencyData,
  agencyData,
  openAccessModal,
  t,
}) => {
  const params = useParams();
  const userRoles = useSelector((state) => state?.user?.info?.userBranchRoles);

  const handleAgencyDataChange = (fieldName, value) => {
    setAgencyData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  return (
    <div className={styles.settings_box} ref={blockRef}>
      <div className={styles.setting_box_name}>
        {t?.returnTranslation("Basic info")}
      </div>
      <div className={styles.setting_form}>
        <div className={styles.setting_form_row}>
          <label className={styles.setting_form_input_label} htmlFor="name">
            {t?.nameOfCompany}{" "}
          </label>

          <Input
            max={40}
            placeholder={`${t?.enterAName}...`}
            value={agencyData.agencyName}
            onChangeValue={(e) =>
              handleAgencyDataChange("agencyName", e.target.value)
            }
            withCounter
            maxHeightFrontEl={40}
          />
        </div>
        <div className={styles.setting_form_row}>
          <label className={styles.setting_form_input_label} htmlFor="email">
            {t?.email}
          </label>
          <Input
            max={40}
            maxHeightFrontEl={40}
            placeholder={`${t?.enterEmail}...`}
            value={agencyData.agencyEmail}
            onChangeValue={(e) =>
              handleAgencyDataChange("agencyEmail", e.target.value)
            }
            withCounter
          />
        </div>
        <div className={styles.setting_form_row}>
          <label
            className={styles.setting_form_input_label}
            htmlFor="description"
          >
            {t?.description}
          </label>

          <TextArea
            max={200}
            placeholder={t?.enterADescription}
            value={agencyData.agencyDesc}
            onChangeValue={(e) =>
              handleAgencyDataChange("agencyDesc", e.target.value)
            }
            withCounter
          />
        </div>
        <div className={styles.setting_form_row}>
          <Dropdown
            maxHeightFrontEl={40}
            placeholder={t?.choose}
            data={TIME_ZONES}
            title={t?.selectTimezone}
            value={agencyData.selectedTimeZone}
            onChange={(value) => {
              handleAgencyDataChange("selectedTimeZone", value);
            }}
          />
        </div>

        {params.id !== ":id" &&
          userRoles?.length > 0 &&
          userRoles?.some((role) => role?.role?.id === ROLE_ID_SUPER_ADMIN) && (
            <div className={styles.setting_form_row}>
              <span className={styles.add_access_title}>
                {t?.addAccessToCompany}
              </span>
              <button
                onClick={openAccessModal}
                className={styles.add_access_line}
              >
                <span>{t?.addAccess}</span>
                <img className={styles.plus_icon} src={plus} alt="" />
              </button>
            </div>
          )}
      </div>
      {/* <div className={styles.checkbox_row}>
              FOR FUTURE
            <input
               className={styles.checkbox_input}
               type="checkbox"
               checked={agencyData.privacyChecked ? true : false}
               onChange={() => handleAgencyDataChange("privacyChecked", agencyData.privacyChecked ? 0 : 1)}
               id="privacy"
            />
            <label className={styles.checkbox_input_label} htmlFor="privacy" />
            <div className={styles.checkbox_row_content}>
               <p className={styles.checkbox_row_title}>Privacy</p>
               <p className={styles.checkbox_row_description}>
                  Give students access to export lessons so they can learn
                  offline. An export button will appear in each lesson.
               </p>
            </div>
         </div>
         <div className={styles.checkbox_row}>
            <input
               className={styles.checkbox_input}
               type="checkbox"
               id="allow_registration"
               checked={agencyData.allowRegistrationChecked ? true : false}
               onChange={() => handleAgencyDataChange("allowRegistrationChecked", agencyData.allowRegistrationChecked ? 0 : 1)}
            />
            <label
               className={styles.checkbox_input_label}
               htmlFor="allow_registration"
            />
            <div className={styles.checkbox_row_content}>
               <p className={styles.checkbox_row_title}>Allow registration</p>
               <p className={styles.checkbox_row_description}>
                  Give students access to export lessons so they can learn
                  offline. An export button will appear in each lesson.
               </p>
            </div>
         </div> */}
    </div>
  );
};

export default BasicBlock;
